import React from 'react';

import SignupAPI from 'containers/Signup/SIgnupAPI';
import { errorAlert } from 'utils/alert';

class ReCaptcha extends React.Component {
  constructor(props) {
    super(props);
    window.successCallback = this.successCallback;
    window.errorCallback = this.errorCallback;
  }

  componentDidMount() {
    this.loadScript('https://www.google.com/recaptcha/api.js');
  }

  loadScript = source => {
    const script = document.createElement('script');
    script.src = source;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  successCallback = async token => {
    try {
      await SignupAPI.verifyCaptcha(token);
      this.props.postVerificationCallback && this.props.postVerificationCallback();
    } catch (error) {
      this.props.postVerificationCallback && this.props.postVerificationCallback(true);
      errorAlert('Please prove that you are a human. Try again!');
    }
  };
  errorCallback = () => {
    errorAlert('Please prove that you are a human. Try again!');
    this.props.postVerificationCallback && this.props.postVerificationCallback(true, false);
  };

  render() {
    return (
      <div
        className='g-recaptcha'
        data-sitekey={'6Lc1ELQZAAAAAGT-5xrmH0lUH3BNIJXVd3dBAtT7'}
        data-callback='successCallback'
        data-error-callback='errorCallback'
        data-size='invisible'
      />
    );
  }
}
export default ReCaptcha;
