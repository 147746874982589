import { currentSession } from './amplify/currentSession';

/**
 * @returns {Object} if the user is authenticated - an object with {idToken, accessToken}
 * @returns {undefined} if user is not authenticated
 * @description the function is an asynchronous function which calls currentSession which has its try catch block
 */
export async function getToken() {
  if (isUserAuthenticated()) {
    // const sessionInfo = await currentAuthenticatedUser();
    // if (sessionInfo) {
    // don't do this on the user information page (when user hasn't signed up/signed in)
    const { idToken, accessToken } = await currentSession();
    // updating the local storage as well to prevent discrepencies in case of tokens being refreshed
    saveCognitoTokens({ idToken, accessToken });
    return { idToken, accessToken };
    // }
  }

  return undefined;
}

/**
 * @returns {Object} if user is authenticated - an object with {idToken, accessToken} read from the local storage
 * @returns {undefined} if user is not authenticated
 * @description the function will act like a sync
 */
export function getTokenFromLocalStorage() {
  if (isUserAuthenticated()) {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    if (idToken && accessToken) {
      return { idToken, accessToken };
    }
  }
  return undefined;
}

export function getUserInfo() {
  return localStorage.getItem('userInfo');
}

export function saveToken(token) {
  if (token) {
    localStorage.setItem('token', token);
  }
}

export function saveCognitoTokens(tokens) {
  if (tokens) {
    localStorage.setItem('idToken', tokens.idToken);
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('isUserAuthenticated', true);
  }
}

export function isUserAuthenticated() {
  return localStorage.getItem('isUserAuthenticated') ? true : false;
}

export function saveUserInfo(userInfo) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

export function removeItem() {
  localStorage.removeItem('isUserAuthenticated');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('employeeInfo');
  localStorage.removeItem('notification-token');
  localStorage.removeItem('holidays');
  localStorage.removeItem('popup');
  sessionStorage.removeItem('cache');
  // console.log('removed items', localStorage.getItem('userInfo'));
}

export function getHolidays() {
  return localStorage.getItem('holidays');
}

export function saveHolidays(holidays) {
  localStorage.setItem('holidays', JSON.stringify(holidays));
}

export function getPopUp() {
  return localStorage.getItem('popup');
}

export function setPopUp() {
  localStorage.setItem('popup', JSON.stringify(true));
}

export function setCacheSettings(cache) {
  //don't cache for admin.
  const userInfo = JSON.parse(getUserInfo());
  if (!userInfo.isAdmin) {
    sessionStorage.setItem('cache', JSON.stringify(cache));
  }
}

export function getCacheSettings() {
  return sessionStorage.getItem('cache');
}
export function saveEmployeeInfo(employeeInfo) {
  localStorage.setItem('employeeInfo', JSON.stringify(employeeInfo));
}
export function getEmployeeInfo() {
  return localStorage.getItem('employeeInfo');
}

export function setSignUpFlowFromApp(signUpFlowStates) {
  return sessionStorage.setItem('signUpFlowFromApp', JSON.stringify(signUpFlowStates));
}

export function getSignUpFlowFromApp() {
  return JSON.parse(sessionStorage.getItem('signUpFlowFromApp'));
}
