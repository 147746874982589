import { bool } from 'prop-types';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
function MiniSpinner({ centerAligned }) {
  return (
    <div
      className={
        centerAligned
          ? 'mini-spinner d-flex justify-content-center align-items-center h-100'
          : 'mini-spinner'
      }
      data-testid='component-MiniSpinner'
    >
      <ClipLoader color='#1B8EB7' loading={true} />
    </div>
  );
}
MiniSpinner.defaultProps = {
  centerAligned: false
};
MiniSpinner.propTypes = {
  centerAligned: bool
};
export default React.memo(MiniSpinner);
