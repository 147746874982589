import APIUtil from 'api/apiUtil';

const api = new APIUtil();
const NotificationApi = {
  getNotifications(userId, mark_seen) {
    const config = {};
    config.params = {
      offset: 0,
      limit: 25,
      mark_seen
    };
    return api.get(`notification/${userId}`, config, 'v4');
  }
};
export default NotificationApi;
