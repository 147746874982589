import { authLogout } from 'actions';
import { fetchAuthSession } from 'aws-amplify/auth';
import { store } from 'index';
import { errorAlert } from 'utils/alert';

export const currentSession = async () => {
  try {
    const authTokens = (await fetchAuthSession()).tokens ?? {};
    // return authTokens;
    return {
      idToken: authTokens.idToken.toString(),
      accessToken: authTokens.accessToken.toString()
    };
  } catch (error) {
    // log user out when no tokens returned
    store.dispatch(authLogout());
    errorAlert('Not authorized. Please login again.');
    throw error;
  }
};
