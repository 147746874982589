import styled from 'styled-components';
import { theme, typeScale } from '../../utils';
export const Logo = styled.div`
  margin-bottom: 51px;
  padding: ${props => (props.padding ? `20px 25px` : 0)};
  @media (min-width: 1366px) {
    padding: ${props => (props.padding ? `${props.padding}` : 0)};
  }
`;
export const Wrapper = styled.div`
  height: 100%;
  @media (min-width: 1366px) {
    height: 100%;
  }
  @media (max-width: 850px) and (orientation: landscape) {
    height: 100%;
  }
  &.guest-login {
    @media (min-width: 768px) {
      height: 100vh;
    }
  }
  &.employee-flow {
    // mobile landscape
    @media (max-width: 850px) and (orientation: landscape) {
      height: 100vh;
    }
    @media (min-width: 768px) {
      height: 100vh;
    }
  }
  .left-window {
    flex: 0 0 20%;
    max-width: 20%;
    @media (min-width: 1440px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: 1920px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    @media (min-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
    &.signup-steps {
      flex: 0 0 20%;
      max-width: 20%;
      @media (min-width: 1440px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media (min-width: 1920px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
  .right-window {
    flex: 0 0 80%;
    max-width: 80%;
    height: 100%;
    @media (min-width: 1440px) {
      flex: 0 0 75%;
      max-width: 75%;
    }
    @media (min-width: 1920px) {
      flex: 0 0 80%;
      max-width: 80%;
    }
  }
  .login-row {
    .login-lhs {
      /* iPhone 11 Landscape */
      @media (max-width: 850px) and (orientation: landscape) {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100%;
        background: ${theme.pageBackgroundWhite};
      }
      ${Logo} {
        /* iPhone 11 landscape */
        @media (max-width: 850px) and (orientation: landscape) {
          padding: 20px 25px;
          background: ${theme.pageBackgroundWhite};
        }
      }
    }
    @media (max-width: 767px) {
      background: ${theme.pageBackgroundWhite};
      height: 100%;
    }
    &--guest {
      flex-direction: column;
      height: 100%;
      .auth-col {
        height: 50%;
        @media (max-width: 767px) {
          height: 100%;
        }
      }
      .login-bottom {
        height: 50%;
        background: ${theme.pageBackground};
        @media (max-width: 767px) {
          height: 0;
        }
      }
      ${Logo} {
        padding: 20px 35px;
      }
    }
    &--employee {
      height: 100%;
      @media (min-width: 750px) {
        background: transparent;
      }
      @media (max-width: 850px) and (orientation: landscape) {
        overflow-y: auto;
      }
      .employee-details {
        align-self: center;
        justify-self: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        @media (min-width: 750px) {
          width: 69%;
          display: block;
        }
        //mobile landscape
        @media (max-width: 850px) and (orientation: landscape) {
          display: none;
        }
        @media (min-width: 1024px) {
          width: 50%;
        }

        p {
          padding: 10px 20px;
          background: ${theme.pageBackgroundWhite};
        }
      }
      .login-lhs {
        @media (max-width: 749px) {
          height: 82px;
        }
        //mobile landscape
        @media (max-width: 850px) and (orientation: landscape) {
          height: 82px;
        }
        @media (min-width: 750px) {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }
      }
      .auth-col {
        @media (max-width: 749px) {
          height: calc(100% - 82px);
        }
        @media (max-width: 850px) and (orientation: landscape) {
          height: calc(100% - 82px);
        }
        @media (min-width: 750px) {
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }
      }
    }
  }
  .forgot-password {
    max-width: 320px;
    margin: 0 auto;
  }
  .demo-header {
    font-weight: bold;
    font-size: 30px !important;
    font-size: 1.875rem !important;
    margin-bottom: 10px !important;
    @media (min-width: 768px) {
      font-size: 38px !important;
      font-size: 2.375rem !important;
      margin-bottom: 15px !important;
    }
    sup {
      font-size: 30%;
      top: -23px;
    }
  }
  .demo-subheader {
    ${typeScale.modalHeader};
    font-weight: bold;
    margin-bottom: 15px !important;
    @media (min-width: 768px) {
      margin-bottom: 25px !important;
    }
  }
  .login-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
    &--login {
      @media (max-width: 850px) and (orientation: landscape) {
        display: none;
      }
    }
  }

  .auth-col {
    background: ${theme.pageBackgroundWhite};
    /* iphone 11 landscape */
    @media (max-width: 850px) and (orientation: landscape) {
      width: 100% !important;
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
    &__signup-button-container {
      order: 2;
      padding: 25px 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 768px) {
        order: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 40px 80px;
        top: 0;
        bottom: initial;
        justify-content: flex-end;
      }
      &--login {
        /* iPhone 11 landscape */
        @media (max-width: 850px) and (orientation: landscape) {
          order: 2;
          padding: 25px 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: static;
        }
      }
      span {
        ${typeScale.textMd}
      }
      a {
        @media (max-width: 767px) {
          background: transparent;
          box-shadow: none !important;
          color: ${theme.textPrimary} !important;
          text-decoration: underline;
          padding: 8px 0px;
        }
        /* iPhone 11 landscape */
        @media (max-width: 850px) and (orientation: landscape) {
          background: transparent;
          box-shadow: none !important;
          color: ${theme.textPrimary} !important;
          text-decoration: underline;
          padding: 8px 0px;
        }
      }
    }
  }
`;
export const LoginForm = styled.div`
  height: 100%;
  width: 100%;
  /* padding-top: 40px; */
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  .login-form-guestwrapper {
    width: 580px;
    box-shadow: var(--modal-box-shadow);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 40px;
    padding-bottom: 100px;
    background: ${theme.pageBackgroundWhite};
    //0 2px 6px 0 rgba(193, 193, 193, 0.51);
    @media (max-width: 767px) {
      width: 100%;
      box-shadow: none;
      padding: 10px;
      padding-bottom: 90px;
      position: static;
      align-self: center;
      transform: translate(0, 0);
    }
    .login-form-container {
      @media (max-width: 767px) {
        padding: 0 25px;
      }
    }
    .g-recaptcha {
      .grecaptcha-badge {
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 767px) {
          bottom: 0 !important;
        }
      }
    }
  }
  .login-form-container {
    background: #fff;
    justify-content: center;
    padding: 0 20px;
    margin: 0 auto;
    order: 1;
    width: 350px;
    @media (min-width: 768px) {
      width: 360px;
      padding: 0;
      order: 2;
      @media (max-width: 767px) {
        width: 350px;
      }
    }
    @media (max-width: 850px) and (orientation: landscape) {
      background: #fff;
      justify-content: center;
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;
      order: 1;
    }
    &--employee {
      @media (max-width: 767px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-bottom: 90px;
        padding-top: 45px;
      }
      @media (min-width: 768px) {
        width: 445px;
      }
      //mobile landscape
      @media (max-width: 850px) and (orientation: landscape) {
        width: 520px;
      }
      form {
        width: 360px;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      .submit {
        @media (max-width: 850px) and (orientation: landscape) {
          margin-top: 1.5rem !important;
        }
      }
    }

    .input-feedback {
      color: #cc0000;
      margin-top: 0.25rem;
      position: absolute;
    }
  }
  @media (min-width: 768px) {
    height: 100vh;
  }
  @media (max-width: 850px) and (orientation: landscape) {
    height: auto;
  }
  .form-group {
    text-align: left;
    .text-muted {
      font-size: 8px;
      margin: 2px 0 0;
    }
  }
  .floating-label-new {
    margin: 0 0 35px;
    &:last-child {
      margin: 0;
    }
    &.pass {
      margin: 0;
    }
  }
  .forget-password {
    padding: 0;
    margin-top: 2px;
    button {
      color: ${theme.textFaded};
      ${typeScale.textSm}
      line-height: 20px;
      background: none;
      padding: 0;
      text-decoration: underline;
      &:hover {
        background: none;
      }
    }
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
  .submit {
    button {
      @media (max-width: 767px) {
        margin-top: 20px;
        width: 88%;
        padding: 12px 100px;
        ${typeScale.textLg}
      }
    }
  }
  h1 {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
    @media (max-width: 850px) and (orientation: landscape) {
      margin-bottom: 20px;
    }
  }
  .show-password {
    position: relative;
    &__eye-wrapper {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    &__eye-label {
      opacity: 0.5;
    }
    &__eye-svg {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 9px;
        background: ${theme.textFaded};
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        right: 6px;
      }
    }
    &__eye-checkbox {
      opacity: 0;
      &:checked {
        ~ .show-password__eye-label {
          opacity: 1;
          .show-password__eye-svg {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
`;
export const ForgetPassword = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  text-align: left;
  form {
    width: 100%;
  }
  .submit {
    display: flex;
    button[type='submit'] {
      margin: 0 auto;
    }
  }
  button.btn.done {
    width: 80px;
    font-size: 12px;
    background: none;
    color: #333;
    box-shadow: none;
    padding: 0;
    text-decoration: underline;
  }
`;
export const ContractorLogin = styled.div`
  width: 100%;
  text-align: center;
  overflow-y: auto;
  height: calc(100vh - 120px);
  .d-inline-block {
    width: 50%;
    border-radius: 5px;
    margin: 40px 0;
    padding: 48px;
    text-align: left;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
    h2 {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin: 0 0 28px;
    }
    h3 {
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.5px;
      color: #666;
      margin: 0 0 40px;
    }
    form .modal-custom > .form-group:last-child {
      display: none;
    }
  }
`;
