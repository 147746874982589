// import { ClipLoader } from 'react-spinners';
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
// import PropTypes from 'prop-types';
// import './Spinner.css';

const Loader = ({ loading, className }) => (
  <div className={`lds-roller ${className ? className : ''}`} data-testid='component-Loader'>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
  // <div className="loader">
  //   <ClipLoader color="#1B8EB7" loading={loading} />
  // </div>
);
export default Loader;
// Loader.propTypes = {
//   // loading: PropTypes.bool.isRequired
// };

// this is created for storybook preview
export const LoaderWrapperForStory = styled.div`
  .lds-roller {
    position: relative;
    height: 300px;
  }
`;
