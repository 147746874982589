import appConfig from 'config';

// check if guest env
export const isProdEnv = () => {
  return appConfig.config().env === 'production';
};
export const isDevEnv = () => {
  return appConfig.config().env === 'develop';
};
export const isQAEnv = () => {
  return appConfig.config().env === 'qa';
};
export const isProdGuestEnv = () => {
  return appConfig.config().baseUrl === 'https://api-beta-h360.toro.com/api';
};
// check if guest env

export const isGuestEnv = () => {
  return (
    appConfig.config().env === 'guest' ||
    appConfig.config().env === 'guest_qa' ||
    appConfig.config().env === 'guest_dev' ||
    appConfig.config().env === 'guest_staging' ||
    appConfig.config().env === 'guest_production'
  );
};

export const isQaGuestEnv = () => {
  return appConfig.config().env === 'guest_qa';
};

export const isDevGuestEnv = () => {
  return appConfig.config().env === 'guest_dev';
};

export const isStagingEnv = () => {
  return appConfig.config().env === 'staging';
};

export const isStagingGuestEnv = () => {
  return appConfig.config().env === 'guest_staging';
};

export const isProductionEnv = () => {
  return appConfig.config().env === 'production';
};

export const isProductionGuestEnv = () => {
  return appConfig.config().env === 'guest_production';
};
