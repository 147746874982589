import { TIMESHEET_SECTION } from 'constants.js';

export const returnIsTimesheetsSubscribed = subscription => {
  // subscribed add ons could be either in
  // the IncludedAddons array or
  // the SubscribedAddons array
  if (subscription?.IncludedAddons?.length) {
    for (const addOn of subscription.IncludedAddons) {
      if (addOn.Name === TIMESHEET_SECTION) {
        return true;
      }
    }
  }
  if (subscription?.SubscribedAddons?.length) {
    for (const addOn of subscription?.SubscribedAddons) {
      if (addOn.Name === TIMESHEET_SECTION) {
        return true;
      }
    }
  }
};
