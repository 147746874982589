import * as actionTypes from 'actions/actionTypes';
const initialState = {
  unseen: 0,
  jobId: undefined
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MARK_NOTIFICATION_SEEN:
      return {
        ...state,
        unseen: 0
      };

    case actionTypes.GET_NOTIFICATION_COUNT:
      return {
        ...state,
        unseen: action.unseen
      };
    default:
      return state;
  }
};
