import InputField from 'components/FormElements/InputField';
import { ButtonAsLink, PrimaryButton } from 'css/components/Modules/Buttons';
import { AuthHeader } from 'css/components/Modules/Headings';
import { LoginForm } from '../../css/components/Modules/login';
import { Formik, Field } from 'formik';
import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { isUserAuthenticated, saveCognitoTokens, saveEmployeeInfo } from 'services/localStorage';
import { errorAlert } from 'utils/alert';
import * as Yup from 'yup';
import { LOGIN_SUCCESS } from './actionTypes';
import { useTimeMangementState } from './TimeMangementStateProvider';
import { getEventType } from './utils';
import Button from 'reactstrap/lib/Button';
import styled from 'styled-components';
import { theme, typeScale } from 'css/utils';
import { signIn } from 'aws-amplify/auth';
import APIUtil from 'api/apiUtil';
import { currentSession } from 'services/amplify/currentSession';
import { formatSessionDataOnLogin } from 'containers/Auth/helpers/formatSessionDataOnLogin';
import { validateEmail } from 'helpers/YUPValidations/validateEmail';
import { validatePassword } from 'helpers/YUPValidations/validatePassword';
import history from 'history.js';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import {
  ADMIN_LOGIN_INTO_TIMECLOCK_MSG,
  OPERATOR_WITHOUT_EMPLOYEE_OBJ_MSG,
  ROLES
} from 'constants.js';
import { returnIsTimesheetsSubscribed } from 'helpers/returnIsTimesheetsSubscribed';
import { COGNITO_ERRORS, LIMIT_EXCEEDED, USER_NOT_FOUND } from 'services/amplify/constants';

const EmployeeLoginSchema = Yup.object().shape({
  email: validateEmail(),
  password: validatePassword()
});

const api = new APIUtil();
function EmployeeLogin({ onLogout }) {
  const location = useLocation();

  const [, dispatch] = useTimeMangementState();
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      // const { data } = await TimeMangementAPI.employeeLogin(dataToSend);
      await signIn({
        username: values.email,
        password: values.password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH'
        }
      });
      const { idToken, accessToken } = await currentSession();
      let userInfo = { isAuthenticated: true, cognitoTokens: { idToken, accessToken } };
      saveCognitoTokens({ idToken, accessToken });

      const { data } = await api.get('user/session');
      // if role not employee -> signout🦵
      if (
        data?.User.RoleId === ROLES.admin.value ||
        (data?.User.RoleId === ROLES.operator.value && !data.Employee)
      ) {
        // if a super admin OR an operator without an employee object tries to login to web -> log them out, stright to jail 🦵
        // let others, (operators, employees) login to timeclock
        onLogout({ redirect: false });
        setSubmitting(false);
        errorAlert(
          data?.User.RoleId === ROLES.admin.value
            ? ADMIN_LOGIN_INTO_TIMECLOCK_MSG
            : OPERATOR_WITHOUT_EMPLOYEE_OBJ_MSG
        );
        return;
      }

      if (!returnIsTimesheetsSubscribed(data.Subscription)) {
        // employee doesn't have access to timesheet data -> right to jail ☠️
        onLogout({ redirect: false });
        setSubmitting(false);
        errorAlert('Please Upgrade plan/ subscribe to timesheet addon to access this.');
        return;
      }
      const employeeData = formatSessionDataOnLogin(data);

      const { eventType, eventGoingOn } = getEventType(
        employeeData.CurrentEmployeeStatus
          ? employeeData?.CurrentEmployeeStatus?.ClockStatus
          : undefined
      );
      // saveToken(data.SessionToken);
      saveEmployeeInfo({ employeeInfo: employeeData, eventType, eventGoingOn });
      dispatch({
        type: LOGIN_SUCCESS,
        data: { employeeLoginInfo: employeeData, eventType, eventGoingOn }
      });
    } catch (error) {
      setSubmitting(false);
      if (isUserAuthenticated()) {
        history.push('/timeclock'); // prevent flash of 403 page
        onLogout({ redirect: false }); // if session api throws error post cognito login, log user out and stay on the /timeclock page
        if (error?.response?.data?.error) {
          errorAlert(error?.response?.data?.error);
          return;
        }
      }
      if (error?.name === USER_NOT_FOUND || error?.name === LIMIT_EXCEEDED) {
        // override cognito's default error message
        errorAlert(COGNITO_ERRORS[error.name]);
      } else if (error?.message) {
        errorAlert(error?.message);
      } else {
        errorAlert('Something Went Wrong!');
      }
      throw error;
    }
  };

  const handleForgotPasswordClick = () => {
    history.push({
      pathname: `/forgot-password`,
      state: {
        from: location?.pathname
      }
    });
  };

  const PrivacyPolicy = styled.p`
    color: ${theme.textFaded};
    ${typeScale.textMd};
    text-align: left;
    margin-bottom: 0;
  `;

  return (
    <LoginForm className='text-center'>
      <div className='login-form-container login-form-container--employee'>
        <AuthHeader>Employee Clock In / Out</AuthHeader>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={EmployeeLoginSchema}
          onSubmit={handleFormSubmit}
          render={props => {
            const { handleSubmit, isSubmitting } = props;
            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className='form-group'>
                    <Field
                      type='text'
                      name='email'
                      label='Email Address*'
                      autoComplete='off'
                      component={InputField}
                      autoFocus={true}
                    />
                  </FormGroup>
                  <FormGroup className='form-group'>
                    <Label>Password*</Label>
                    <div className='show-password'>
                      <Field
                        type='password'
                        name='password'
                        autoComplete='off'
                        component={InputField}
                      />
                    </div>
                  </FormGroup>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='forget-password justify-content-end d-flex ms-auto'>
                      <Button
                        id='forgotPassword'
                        color='link'
                        type='button'
                        onClick={handleForgotPasswordClick}
                      >
                        Forgot Password?
                      </Button>
                    </div>
                  </div>
                  <PrivacyPolicy>
                    By clicking confirm, you agree to our{' '}
                    <ButtonAsLink
                      type='button'
                      modifiers={['faded', 'bold']}
                      onClick={() =>
                        window.open('https://www.toro.com/en/legal/privacy-policy', '_blank')
                      }
                    >
                      Privacy Policy
                    </ButtonAsLink>{' '}
                    and{' '}
                    <ButtonAsLink
                      type='button'
                      modifiers={['faded', 'bold']}
                      onClick={() =>
                        window.open(
                          'https://www.toro.com/en/legal/Horizon360-SaaS-Agreement',
                          '_blank'
                        )
                      }
                    >
                      Terms of Service
                    </ButtonAsLink>
                    .
                  </PrivacyPolicy>
                  <div className='submit mt-auto mt-md-4'>
                    <PrimaryButton
                      id='loginButton'
                      type='submit'
                      disabled={isSubmitting}
                      modifiers={'medium'}
                    >
                      Confirm
                    </PrimaryButton>
                  </div>
                </Form>
              </>
            );
          }}
        />
      </div>
    </LoginForm>
  );
}

const mapDispatchToProps = dispatch => ({
  onLogout: ({ redirect }) => dispatch(actions.authLogout({ redirect }))
});

export default connect(null, mapDispatchToProps)(EmployeeLogin);
