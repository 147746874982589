import React from 'react';

import { Row, Col, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Wrapper, Logo, LoginForm } from 'css/components/Modules/login';
import { LinkAsButtonWrapper } from 'css/components/Modules/Links';
import { AuthHeader } from 'css/components/Modules/Headings';

const LoginPageLayout = ({ children, header, isEmployeeLogin }) => {
  return (
    <>
      <Wrapper>
        <Row className='login-row'>
          <Col xs={12} md={4} className='login-lhs'>
            <Logo padding={'40px 50px'} className='mb-0'>
              <span>
                <img
                  className='img-fluid'
                  src={require('../../images/logo_dark.svg').default}
                  alt=''
                />
              </span>
            </Logo>
            {!isEmployeeLogin && (
              <img
                className='login-image login-image--login'
                alt='responsive 360 screens'
                src={require('../../images/scheduleScreen.png')}
              />
            )}
          </Col>
          <Col xs={12} md={8} className='d-flex flex-column auth-col'>
            <LoginForm className='text-center'>
              {!isEmployeeLogin && (
                <div className='auth-col__signup-button-container auth-col__signup-button-container--login'>
                  <span>Don't have an account?</span>
                  <LinkAsButtonWrapper className='ms-3'>
                    <NavLink
                      className='d-flex justify-content-center align-items-center flex-lg-column jobs-channel'
                      to='/signup'
                      tag={RRNavLink}
                    >
                      Sign Up
                    </NavLink>
                  </LinkAsButtonWrapper>
                </div>
              )}

              <div className='login-form-container'>
                {header && <AuthHeader>{header}</AuthHeader>}

                {children}
              </div>
            </LoginForm>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default LoginPageLayout;
