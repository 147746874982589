export const returnIsOnboardStatus = ({ roleId, companyOwner, operator, employee }) => {
  let isOnboard = false;
  if (roleId === 1) {
    // admin
    if (companyOwner?.Operator) {
      isOnboard = companyOwner?.Operator?.IsOnboard;
    } else {
      isOnboard = true;
    }
  } else if (roleId === 2) {
    // operator
    isOnboard = operator.IsOnboard;
  } else if (roleId === 3) {
    //employee
    isOnboard = employee.IsOnboarding;
  }
  return isOnboard;
};
