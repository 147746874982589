import { toast } from 'react-toastify';
export const successAlert = alertMessage => {
  toast.success(alertMessage, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    closeOnClick: true
  });
};

export const errorAlert = (alertMessage, duration = 3000) => {
  toast.error(alertMessage || 'Something went wrong!', {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: duration,
    closeOnClick: true
  });
};
