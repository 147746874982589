import {
  SELECT_EVENT_TYPE,
  CLOCK_OUT,
  CLOCK_IN,
  BREAK_START,
  BREAK_END,
  LUNCH_START
} from '../../constants';

export const getInitialState = () => {
  return {
    employeeLoginInfo: null,
    eventType: null,
    eventGoingOn: false,
    showSuccessScreen: false,
    successMessage: undefined,
    employeeLocationInfo: undefined
  };
};
/**
 *
 * @param {currentStatus} currentStatus It's the current status of the employee Timecard.
 * @description This function get the currentStatus and return information about the action to be performed when employee logs in.
 * @returns An object with eventType and eventGoingOn boolean
 */
export const getEventType = currentStatus => {
  switch (currentStatus) {
    case 0: // current status is clock in
      return {
        eventType: SELECT_EVENT_TYPE,
        eventGoingOn: false
      };

    case 1: // current status is clock out
      return {
        eventType: CLOCK_IN,
        eventGoingOn: false
      };
    case 2:
    case 3: // current status is lunch or break
      return {
        eventType: SELECT_EVENT_TYPE,
        eventGoingOn: true
      };

    default:
      return {
        eventType: CLOCK_IN,
        eventGoingOn: false
      };
  }
};

export const getSuccessMessage = eventType => {
  if (eventType === CLOCK_OUT) {
    return 'Clock Out Successful!';
  }
  if (eventType === BREAK_START || eventType === LUNCH_START) {
    return 'Break Started!';
  }
  if (eventType === BREAK_END) {
    return 'Break Ended!';
  }
};
