import { capitaliseFirstChar } from 'utils/capitaliseFirstChar';

export const formatSessionDataOnLogin = (data, idToken, accessToken) => {
  const {
    User,
    AccessibleSections,
    Subscription,
    GoogleApiKey,
    TutorialSectionSeen,
    Company,
    Employee,
    Evo,
    TimesheetDetails
  } = data || {};

  /**
   * the below keys are not present in the new session api response AND
   * these don't seem to be rquired either as they weren't saved into global state/localstorage in the previous auth flow
   * "quickbooks": false,
   * "qboCompanyName": null,
   * "qboConnectTime": null,
   * AvatarUrl
   */
  const sessionData = {
    UserId: User.UserId,
    IsAdmin: User.RoleId === 1 ? 1 : 0,
    CompanyId: 0,
    CompanyName: '',

    SubscriptionStatusId: 1,
    // TODO: for operator
    IsCompanyVerified: 0,
    // TODO: in the previous response, iscompanyverified and is isonboarding the same thing????
    // 'HasCompanyDetails':
    Timezone: null,
    Latitude: null,
    Longitude: null,
    FirstName: User.FirstName,
    LastName: User.LastName,
    RoleId: User.RoleId,
    Role: capitaliseFirstChar(User?.Role?.Role), // TODO: Use RoleId to generate Role.
    GoogleApiKey,
    EmailAddress: User.EmailAddress,
    PhoneNumber: User.PhoneNumber || null,
    Subscription,
    TutorialSectionSeen,
    AccessibleSections,
    cognitoTokens: {
      idToken,
      accessToken
    },
    Evo
  };
  if (User.RoleId === 2) {
    // Operator login
    // will have some extra keys
    sessionData.StartWorkHour = Company.StartWorkHour;
    sessionData.EndWorkHour = Company.EndWorkHour;
    sessionData.StartOfWeek = Company.StartOfWeek;
    sessionData.Weekends = Company.Weekends;
    sessionData.Holidays = Company.Holidays;
    sessionData.Timezone = Company.Timezone;
    sessionData.Latitude = Company.Latitude;
    sessionData.Longitude = Company.longitude;
    sessionData.CompanyId = Company.CompanyId;
    sessionData.CompanyName = Company.CompanyName;
    sessionData.IsCompanyVerified = Company.IsVerified; // TODO: confirm with BE
    sessionData.IsOnboarding = data.Operator.IsOnboard; // TODO: can be renamed to IsOnboard??
    sessionData.SubscriptionStatusId = Subscription?.SubscriptionStatusId;
    sessionData.AddressLine1 = Company.AddressLine1;
    sessionData.AddressLine2 = Company.AddressLine2;
    sessionData.City = Company.City;
    sessionData.County = Company.County;
    sessionData.State = Company.State;
    sessionData.Subscription = Subscription;
    sessionData.CompanyOwnerId = Company?.CompanyOwner?.UserId;
    sessionData.Company = JSON.parse(JSON.stringify(Company));
    if (Employee) {
      sessionData.CurrentEmployeeStatus = JSON.parse(JSON.stringify(TimesheetDetails));
      sessionData.FirstName = Employee?.FirstName;
      sessionData.LastName = Employee?.LastName;
      sessionData.CompanyId = Employee?.CompanyId;
      sessionData.CompanyName = Company?.CompanyName;
      sessionData.EmployeeId = Employee?.EmployeeId;
      sessionData.SubscriptionStatusId = Subscription?.SubscriptionStatusId;
    }
  }
  if (User.RoleId === 3) {
    // employee login to timeclock
    sessionData.CurrentEmployeeStatus = JSON.parse(JSON.stringify(TimesheetDetails));
    sessionData.FirstName = Employee.FirstName;
    sessionData.LastName = Employee.LastName;
    sessionData.CompanyId = Employee.CompanyId;
    sessionData.CompanyName = Company?.CompanyName;
    sessionData.EmployeeId = Employee.EmployeeId;
    sessionData.SubscriptionStatusId = Subscription?.SubscriptionStatusId;
  }
  return sessionData;
};
