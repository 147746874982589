import * as actionTypes from './actionTypes';
import NotificationApi from 'components/Notifications/NotificationApi';
export const markNotificationsSeen = () => {
  return {
    type: actionTypes.MARK_NOTIFICATION_SEEN
  };
};
export const getNotificationCount = userId => async dispatch => {
  const { data } = await NotificationApi.getNotifications(userId, false);
  return dispatch({
    type: actionTypes.GET_NOTIFICATION_COUNT,
    unseen: data ? data.unseen : 0
  });
};
