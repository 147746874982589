export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const MARK_NOTIFICATION_SEEN = 'MARK_NOTIFICATION_SEEN';
export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';
export const NAME_CHANGE = 'NAME_CHANGE';
export const ADMIN_AS_OPERATOR = 'ADMIN_AS_OPERATOR';
export const APP_TOUR_STATUS = 'APP_TOUR_STATUS';
export const MANAGE_WORK_HOURS = 'MANAGE_WORK_HOURS';
export const OPERATOR_AS_ADMIN = 'OPERATOR_AS_ADMIN';
export const SET_ACTIVE_JOB_FROM_NOTIFICATION = 'SET_ACTIVE_JOB_FROM_NOTIFICATION';
export const CHANGE_TIME_ZONE = 'CHANGE_TIME_ZONE';
export const UPDATE_TUTORIAL_STATE = 'UPDATE_TUTORIAL_STATE';
export const COMPANY_VERIFIED = 'COMPANY_VERIFIED';
export const COMPANY_DETAILS_ADDED = 'COMPANY_DETAILS_ADDED';
export const CHANGE_SUBSCRIPTION_STATUS = 'CHANGE_SUBSCRIPTION_STATUS';
export const SUBSCRIBED_SUCCESS = 'SUBSCRIBED_SUCCESS';
export const UPDATE_COMPANY_COORDINATES = 'UPDATE_COMPANY_COORDINATES';
export const REFRESH_DETAILS = 'REFRESH_DETAILS';
export const UPDATE_SCHEDULE_CALENDAR_DATE_RANGE = 'UPDATE_SCHEDULE_CALENDAR_DATE_RANGE';
