import { createGlobalStyle } from 'styled-components';
import images from '../images/error-bg.png';
import { RightsideBarWrap } from '../css/components/Dashboard-main';
import passedIcon from '../images/Green_Checkmark.svg';
import failedIcon from '../images/Red_X_Mark.svg';
import { JobsStatus } from './components/employee';
import { typeScale, theme, breakpoints } from './utils';
import ODJ_Icon from '../images/ODJ_Icon.svg';
import searchIcon from '../images/search-icon.png';

export default createGlobalStyle`
    :root {
        --toro-black: #333;
        --waring-red: #cc0000;
        --link-grey: #999999;
        --input-box-shadow: 0 0 6px 0 rgba(0,0,0,0.11);
        --background-bezier: cubic-bezier(.42,.97,.52,1.49);
        --box-shadow-bottom: 0 3px 4px -4px rgba(0,0,0,0.7);
        --modal-box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
        --disabled-opacity: 0.5;
        --page-layout-padding: 54px 45px;
        --leftsidebar-width: 140px;
        --rightsidewrap-width: 380px;
        --max-mobile: 'max-width: 767px';
        --max-tablet: max-width: 1024px;
        --max-tablet-landscape: max-width: 1365px;
        --min-desktop: 'min-width: 1366px';
        --min-tablet: 'min-width: 768px';
        --min-tablet-landscape: 'min-width: 1024px';
    }
    body, h1, h2, h3, h4, h5, a, li, p, .popover, pre{
        font-family: 'Verdana' !important
    }
    * {
        box-sizing: border-box;
    }
    html {
        font-size: 16px;
        height: 100%;
        overflow: hidden
    }
    body {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height:100%;
        overflow:hidden;
        background:#f9f9f9;
        @media(max-width:1024px){
            position:fixed;
            width:100%;
            left:0;
            right:0;
            top:0;
            bottom:0;
        }
        @media only screen
        and (min-device-width : 576px)
        and (max-device-width : 1024px)
        and (orientation : landscape) {
            position:static;
            /* overflow:auto; */
        }
        @media (max-width: 767px) { // ugh, what was this for??
            /* overflow-y: auto; */
        }
        /* iPhone 11 landscape */
        @media (max-width: 850px) and (orientation: landscape) {
            position: static;
            height: 100%;
            ${'' /* height: auto; */}
            /* overflow-y: auto; */
        }
        overflow: hidden !important;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace
    }
    // TODO: make this more flexible
    .collapsible-triangle {
        width: 0px;
        height: 0px;
        display: inline-block;
        &--leftsidebar {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 3px solid #fff;
            @media (max-width:992) {
                display: none;
            }
        }
        &.active {
            transition: all 0.3s ease;
            transform: rotate(180deg);
        }
    }
    ul, li {
        list-style: none;
    }
    .revert-default-list-style {
        list-style: disc !important;
        list-style-position: inside !important;
    }
    ol {
        list-style: auto;
        li {
            list-style: auto;
        }
    }
    .dotted-list-style li {
        list-style: disc;
    }
    // placement-top - add this class to the parent of your DatePickerInput component if you need the datepicker to open on top of the input field
    // for ControlledReactSelect components => use menuPlacement='auto' prop
    .placement-top {
        .DayPickerInput .DayPickerInput-Overlay {
            bottom: 35px;
            height: 312px;
        }
    }

    .filters-wrapper-popup  {
        @media(${breakpoints.maxTablet}) {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        & > div {
            width: 100%;
            @media (${breakpoints.maxTablet}) {
                margin-bottom: 15px;
            }
        }
    }
    .text-transform-capitalize {
        text-transform: capitalize;
    }
    .highlighted-background {
        background: ${theme.pageBackground};
        padding: 15px;
    }
    .container-shadow {
        box-shadow: var(--modal-box-shadow);
    }
    .color-white {
        color: #fff;
    }
    .w-60 {
        width: 60%;
    }
    .w-80{
        width: 80%;
    }
    .pe-2-5{
        padding-right:10px !important;
    }
    /* TODO: remove these after the Typgraphy component is merged */
    .text-1000 {
        font-size: 32px;
    }
    .text-300 {
        font-size: 12px;
    }
    .d-1024-block {
        @media (min-width: 1024px) {
            display: block !important;
        }
    }
    .standard-field-width {
        width: 250px;
    }
    .full-height {
        height: 100vh;
    }
    .full-width {
        width: 100vw;
    }
    .w-fit-content {
        width: fit-content;
    }
    .outline-none {
        outline: none !important;
    }
    .tile-shadow {
        box-shadow: var(--modal-box-shadow);
    }
    .border-bottom-e3e3e3 {
        border-bottom: solid 1px ${theme.iconDivider}
    }
    .opacity-6 {
        opacity: 0.6
    }
    .d-1024-none {
        @media (min-width: 1024px) {
            display: none !important;
        }
    }
    .col--tablet-l-full-width {
        @media (${breakpoints.maxTablet}) {
            width: 100% !important;
            flex: 1 1 100% !important;
            max-width: 100% !important;
        }
    }
    .cursor-default {
        cursor: default !important;
    }
    button, button:focus{
        outline: none;
        /* box-shadow: none !important; */
    }
    .spinner-border {
        overflow: hidden !important;
    }
    .form-check-input {
        position: absolute;
    }
    .overflow-x-hidden {
        overflow-x: hidden !important;
    }
    .form-group.mb-override-0 {
        margin-bottom: 0 !important
    }
    /* only and only for functional playground purposes === DO NOT REPLICATE === */
    .guest, .guest_qa, .guest_dev {
        pointer-events: none !important;
        &--hide {
            display: none !important;
        }
        &--faded {
            opacity: 0.5 !important; // use this to fade out icons, switch elements, your bad memories as well.
        }
        &--button {
            background: ${theme.disabled} !important;
            color: ${theme.textInverted} !important;
        }
    }
    .text-decoration-underline {
        text-decoration:underline !important;
    }
    .absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .confirmation-modal {
        &.header-text-transform-initial {
         .modal-title {
                 text-transform: initial !important;
             }
        }
    }
    .visually-hidden {
        position: absolute !important;
        top: -3000px;
        height: 1px !important;
        width: 1px !important;
        overflow: hidden;
    }
    .pointer-cursor {
        cursor: pointer
    }

    #root{
        min-height: 100%;
        /* height: calc(100vh - 120px); */
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* @media (max-width: 1365px) and (orientation: landscape) {
            overflow: hidden;
            height: auto;

        } */
    }

    .menu-open {
        button {
            background: #fff !important;
            color: #333 !important;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.15) !important;
        }
    }
    nav .thumb{
        color: ${theme.textPrimary};
        ${typeScale.header2}
    }
    .nav-link:focus, .nav-link:hover {
        color: #fff;
    }
    .selectColor{
        position:relative;
    }
    .single-line {
        white-space: nowrap;
    }
    .remaining-height {
        flex-grow: 1;
        overflow: hidden;
    }
    textarea, input  {
        &:disabled {
            background: ${theme.disabledField} !important;
            color: ${theme.disabled} !important;
        }
    }
    .list-dots {
        list-style: disc;
    }

    //for Schedule page edit form to bring the form upward
    .jobform-top {
        top: -30px;
    }

    .unscheduledroute-gradient {
        position: relative;
        border-left: 0 !important;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 7px;
            height: 100%;
            background: repeating-linear-gradient(45deg, #f6f6f6, #f6f6f6 6px, #ccc 3px, #ccc 12px);
        }
    }

// tippy tooltip ================ start
    .tippy-box[data-animation=fade][data-state=hidden] {
        opacity: 0
    }

    [data-tippy-root] {
        max-width: calc(100vw - 10px)
    }

    .tippy-box {
        position: relative;
        background-color: #fff;
        color: #333;
        border-radius: 4px;
        ${typeScale.textMd};
        line-height: 1.4;
        white-space: normal;
        outline: 0;
        transition-property: transform, visibility, opacity;
        box-shadow: var(--modal-box-shadow);
        max-width: 200px !important;
       
    }

    .tippy-box[data-placement^=top]>.tippy-arrow {
        bottom: 0
    }

    .tippy-box[data-placement^=top]>.tippy-arrow:before {
        bottom: -7px;
        left: 0;
        border-width: 8px 8px 0;
        border-top-color: initial;
        transform-origin: center top
    }

    .tippy-box[data-placement^=bottom]>.tippy-arrow {
        top: 0
    }

    .tippy-box[data-placement^=bottom]>.tippy-arrow:before {
        top: -7px;
        left: 0;
        border-width: 0 8px 8px;
        border-bottom-color: initial;
        transform-origin: center bottom
    }

    .tippy-box[data-placement^=left]>.tippy-arrow {
        right: 0
    }

    .tippy-box[data-placement^=left]>.tippy-arrow:before {
        border-width: 8px 0 8px 8px;
        border-left-color: initial;
        right: -7px;
        transform-origin: center left
    }

    .tippy-box[data-placement^=right]>.tippy-arrow {
        left: 0
    }

    .tippy-box[data-placement^=right]>.tippy-arrow:before {
        left: -7px;
        border-width: 8px 8px 8px 0;
        border-right-color: initial;
        transform-origin: center right
    }

    .tippy-box[data-inertia][data-state=visible] {
        transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
    }

    .tippy-arrow {
        width: 16px;
        height: 16px;
        color: #fff
    }

    .tippy-arrow:before {
        content: "";
        position: absolute;
        border-color: transparent;
        border-style: solid
    }

    .tippy-content {
        position: relative;
        padding: 10px;
        z-index: 1
    }

// tippy tooltip ================ end

    .input-feedback-top-100 {
        .input-feedback {
            top: 100%;
            position: absolute;
        }
    }
    .payment{
        .billing-heading {
            color: ${theme.textFaded};
            ${typeScale.textMd};
            font-weight: bold;
            margin-bottom: 6px;
        }
        iframe {
            height: 300px !important;
            border: 0;
            width: 100% !important;
            /* @media (min-width: 768px) {
                height: 310px !important;
            } */
            @media (min-width: 1366px) {
                height: 220px !important;
            }
            html {
                width: 100% !important;
            }
        }
    }
    .RangeExample button {
        box-shadow: var(--input-box-shadow);
        color: ${theme.textPrimary} !important;
        font-size: 10px;
        margin: 0;
        width: 100%;
        height: 35px;
        background: #fcfcfc !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button.btn{
        background: #cc0000;
        border-radius: 5px;
        border: none;
        transition: all 0.3s ease-in;
        margin-top: auto;
        &:hover{
            background: #ef4c4c;
            box-shadow: none;
        }
        &.done{
            background: #333333;
            border-radius: 6.3px;
            box-shadow: 0 0 8px rgba(0,0,0,0.2);
            font-weight: 700;
            letter-spacing: 0.2px;
            margin: 20px auto 0;
            display: flex;
            justify-content:center;
            min-width: 108px;
            padding: 0 10px;
            color: #fff;
            font-size: 10px;
            line-height:28px;
        }
        &.done-light{
            background:#fff;
            border-radius: 5px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15) !important;
            color:#333333;
            letter-spacing: 0;
            font-weight: 700;
            font-size: 10px;
            line-height: 40px;
            padding: 0;
            width:100%;
            &:hover{
                background:#333;
                color:#fff;
            }
            &.addServiceBtn{
                color:${theme.textInverted};
                background: ${theme.textPrimary};
                &:hover{
                    color: ${theme.textPrimary};
                    background: ${theme.textInverted};
                }
            }
        }
        &.rounded-cross{
            border-radius: 50%;
            background: #ccc;
            width: 18px;
            height: 17px;
            color: #fff;
            padding: 0;
            display:flex;
            align-items:center;
            justify-content:center;
            position: absolute;
            font-weight: 700;
            right: 10px;
            top: 0;
        }
        &.disabled {
            opacity: 0.6 !important;
            cursor: not-allowed;
        }
        &--small {
            min-width: 75px !important;
        }
        &--dark {
            background: var(--toro-black);
        }
        &--nobg {
            background: transparent;
            color: var(--toro-black);
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2) !important;
            padding: 8px 20px;
            &:hover {
                background: var(--toro-black);
                color: #fff;
            }
        }
        &--link {
            color: var(--link-grey);
            text-decoration: underline;
            background: transparent;
            &:hover {
                background: transparent;
                color: var(--link-grey);
            }
            &:active {
                color: var(--link-grey) !important;
                text-decoration: underline !important;
                background: transparent !important;
                }
        }

        &--warning {
            background: var(--waring-red) !important;
        }
        &--withbg-font {
            font-weight: 700;
            font-size: 10px;
        }
        &--w-120 {
            min-width: 120px;
            padding: 8px 0;
        }
        &--color-pallete-actions {
            border: 0;
            background: transparent;
            &:hover {
                background: transparent;
            }
        }
        &.disabled {
            cursor: not-allowed! important;
        }
        &--selected {
            background: #fff !important;
            color: #333 !important;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.15) !important;
        }
        &--confirm {
            border-radius: 4.5px;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.2) !important;
            font-size: 10px;
            line-height: 12px;
            padding: 8px 0 !important;
            min-width: 108px;
            background-color: var(--toro-black);
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.2px;
            margin: 20px auto 0;
            &:hover {
                background: #333;
            }
        }
        &--rounded-cross {
            border-radius: 50%;
            background: #333;
            width: 18px;
            min-width: 18px;
            height: 18px;
            padding: 0;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-left: auto;
            margin-top: 0;
            &:hover {
                background: #333;
            }
            &.disabled {
                opacity:0.7 !important;
                background: #333 !important;
                &:hover {
                    opacity: 0.7 !important;
                }
            }
        }
        &--input-rounded-cross {
            border-radius: 50%;
            width: 18px;
            min-width: 18px;
            height: 18px;
            padding: 0;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-left: auto;
            margin-top: 0;
            background: #ccc;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
                background: #333;
            }
            &.disabled {
                opacity:0.7 !important;
                background: #333 !important;
                &:hover {
                    opacity: 0.7 !important;
                }
            }
        }
        &--rise-on-hover {
            &:hover {
                box-shadow: 0 3px 5px 0 rgba(0,0,0,0.35) !important;
            }

        }
    }
    .RangeExample button.done-light{
        line-height: 15px;
    }
    .banner-button {
        padding: 0.260rem .75rem .490rem  .75rem;
    }
    .warning-header {
        background-color: #cc0000;
        width: 100%;
        color: #ffffff;
        text-align: center;
        position: relative;
        z-index: 99999;
        ${typeScale.textLg};
        &--border-top {
            border-top: solid 1px ${theme.lightBorder}
        }
        button, a {
            display: inline-block;
            padding: 0.260rem .75rem .490rem  .75rem !important;
            ${typeScale.textLg};
            color: ${theme.textInverted};
            font-weight: normal !important;
            /* background: #cc0000; */
            border-radius: 5px;
            border: none;
            -webkit-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
            margin-top: auto;
            box-shadow: none !important;
            &:hover {
                color: ${theme.textInverted};
                background: rgb(239, 76, 76);
                box-shadow: none;
                text-decoration: underline;
            }
        }
    }
    .address-list {
        list-style: none;
        padding: 0;
        li {
            text-align: left;
            ${typeScale.textSm}
        }
        input[type=radio]{
            min-width: 13px;
        }
    }
    .subscribed-devices {
        .card-header {
            background: #333;
            color: #fff;
            strong {
                font-size: 10px;
            }
        }
    }
    .card-frame {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
        border-radius: 5px;
    }
    & .dots{
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        & >div{
            width: 8px;
            height: 8px;
            background-color: #ccc;
            display:inline-block;
            border-radius:100%;
            &:not(:last-child){margin-right:6px;}
            &.active{
                background:#333333;
                width:10px;
                height:10px;
            }
        }
    }
    .ondemand-job {
        .btn-dark {
            width: 135px;
        }
    }
    .line-demarcation {
        position: relative;
        text-align: center;
        span {
            background: #fff;
            padding: 0 10px;
            position: relative;
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0%;
            right: 0;
            width: 100%;
            height: 1px;
            background: #666666;
            transform: translateY(-50%);
        }
    }
    .embeddedServiceHelpButton .uiButton {
        @media (${breakpoints.maxMobile}) {
            width: 0 !important;
            min-width: 0 !important;
        }
    }
    .embeddedServiceHelpButton .helpButton .uiButton {
        width: 46px !important;
        display: flex;
        align-items: center !important;
        justify-content: center !important ;
    }
    .embeddedServiceHelpButton .uiButton .helpButtonLabel {
        @media (${breakpoints.maxMobile}) {
            min-width: 0 !important;
            max-width: 0 !important;
        }
    }
    .embeddedServiceHelpButton .embeddedServiceIcon {
        @media (${breakpoints.maxMobile}) {
            margin-right: 0 !important;
        }
    }

    .serviceinfotext-max-width {
        max-width: 750px;
    }
    .sort {
        ${'' /* line-height: 24px; */}
        color: ${theme.textFaded};
        font-size: 10px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        &.sort-active {
            color: #333;
            &.asc {
                svg path:first-child {
                stroke: #333;
                }
            }
            &.desc {
                svg path:last-child {
                stroke: #333;
                }
            }
        }
    }
    div[class^='col-'], div[class*='col-'] {
        position:relative;
    }
    .absolute-right-0 {
        right: 0
    }
    
    .absolute-top-0 {
        top: 0;
    }
    .form-group{
        [tooltip]{
            margin:0 0 0 5px;
            position:relative;
            text-transform: none;
            &::before,::after{
                display:none;
            }
            &:hover::before,
            &:hover::after{
                display:block;
            }
        }
        [tooltip]::before {
            content: "";
            position: absolute;
            top:-6px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 4px 6px 0 6px;
            border-style: solid;
            border-color: rgba(0,0,0,0.7) transparent transparent     transparent;
            z-index: 100;
            font-weight:500;
        }
        [tooltip]::after {
            content: attr(tooltip);
            position: absolute;
            left:0%;
            top:-6px;
            transform: translateX(-50%) translateY(-100%);
            background: rgba(0,0,0,0.7);
            text-align: center;
            color: #fff;
            padding: 5px 4px;
            font-size: 9px;
            min-width: 180px;
            font-weight:700;
            border-radius: 5px;
            pointer-events: none;
            font-style:normal;
            z-index: 1;
            line-height:16px;
        }
        .tooltip-icon--drag-left {
            z-index: 9;
            &::after {
                background: rgba(0,0,0,1);
                transform: translateX(-90%) translateY(-100%);
            }
        }
        [tooltip].disabled {
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
            input, textarea{
            width: 100%;
            margin: 0;
            height: auto;
            padding: 10px 15px;
            background-color: #fcfcfc;
            border-radius: 5px;
            font-size: 10px;
            color: #333333;
            border: none;
            box-shadow:inset 0 0 3px rgba(0,0,0,0.1);
            &.is-invalid{
                box-shadow:inset 0 0 3px #dc3545;
                background-image:none !important;
            }
            &[type=number]{
                ::-webkit-inner-spin-button,
                ::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            &[type=number] {
                -moz-appearance:textfield;
            }
            &.custom-input-warning {
                box-shadow: inset 0 0 3px rgba(205,127,50, 1);
            }
        }
        .custom-input-warning-message {
            color: 	#cd7f32;
            font-size: 12px;
    }
    .input__warning {
        input {
            box-shadow: inset 0 0 3px rgba(205, 127, 50, 1);
            border-radius: 5px;
        }
    }
    .dropdown__warning {
        [class*='-control'] {
            border-color: rgba(205, 127, 50, 1);
            border-radius: 5px;
        }
        .custom-input-warning-message {
            color: 	#cd7f32;
            font-size: 12px;
    }
    .input__warning {
        input {
            box-shadow: inset 0 0 3px rgba(205, 127, 50, 1);
            border-radius: 5px;
        }
    }
    .dropdown__warning {
        [class*='-control'] {
            border-color: rgba(205, 127, 50, 1);
            border-radius: 5px;
        }
    }
    }
    }
    .input-feedback{
        text-align: left;
        font-size: 12px;
        color: ${theme.errorRed} !important
    }
    .form-group label, .form-label{
        font-size: 10px;
        color: #333;
        line-height: 20px;
        margin: 0;
        position: relative;
        &.crew{
            width:100%;
        }
    }
    .btn.close{
            color: #fff;
            background: none;
            opacity: 1;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            font-family: arial;
            font-weight: bold;
            line-height: 0px;
            font-size: 10px;
            min-width: auto !important;
            line-height: 0 !important;
            opacity: 1 !important;
        &:hover{
            background:none;
        }
    }
    p, h1, h2, h3, h4, h5, li{
        color: #333;
        font-size: 12px;
    }
    .warning-alert {
        transform: translateY(-2px);
        path {
            fill: #FFF;
        }
    }
    #slotDurationInfo {
        outline: none;
    }
    .Toastify__toast {
        background: #fff !important;
        border-radius: 10px !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        color: #333;
        text-align: center;
        .Toastify__close-button{
            color:#333;
            opacity:0.5;
            font-weight:400;
        }
        &.Toastify__toast--error {
            background: #cc0000 !important;
            color: #fff;
            top: 0 !important;
            bottom: auto !important;
            .Toastify__close-button{
                color:#fff;
            }
        }
    }
    .Toastify__toast-container{
        z-index:9999999 !important;
    }
    /* .modal-backdrop{
        -webkit-backdrop-filter: blur(3px);

        background-color: rgba(51, 51, 51, 0.75);
        opacity:1 !important;
    } */
    .multivalue-dropdown {
        @media (min-width: 1366px) and (max-width: 1440px) {
        max-width: 200px;
        }
        @media (min-width: 1441px) and (max-width: 1600px) {
        max-width: 260px;
        }
        @media (min-width: 1601px) and (max-width: 1920px) {
        max-width: 300px;
        }
        @media (min-width: 1921) {
        max-width: 350px;
        }
    }
    .subscription-date {
        ${typeScale.textMd};
    }
    .subscription-status {
        border: solid 1px ${theme.alternativeText};
        border-radius: 2px;
        display: inline-block;
        padding: 3px!important;
        color: ${theme.alternativeText} !important;
        width: auto !important;
        ${typeScale.textMd};
    }
    .modal-backdrop {
        bottom: 0;
        right: 0;
        left:auto;
        top:auto;
        z-index: -1;
        width: 100%;
        height: calc(100vh - 80px);
    }
    .job-note-download {
        position: absolute;
        top: 0;
        background: transparent;
        border: 0;
        z-index: 999;
        left: 91%;
        svg {
            width: 20px;
            height: 20px;
        }
    }
    .selected-frequency {
        background: ${theme.neutralPrimary};
        color: ${theme.textInverted};
        ${typeScale.textSm};
        border-radius: 12px;
        padding: 5px 12px;
    }
    .DayPicker-Month {
        min-width: 235px
    }
    .hide-popover-arrow {
        .popover .arrow{
            display: none;
        }
    }
    .tox {
        * {
            font-family: Verdana !important
        }
        .tox-dialog__title {
            font-size: ${typeScale.header2};
            font-weight: 700 !important;
        }
        .tox-checkbox__label {
            font-size: ${typeScale.textSm} !important;
            color: ${theme.textPrimary} !important;
        }
        .tox-form__group {
            margin-bottom: 20px !important;
        }
        .tox-label {
            font-size: ${typeScale.textSm} !important;
            color: ${theme.textPrimary} !important;
            margin-bottom: 5px !important;
        }
        .tox-button {
            outline: none !important;
            border: 0 !important;
            font-size: ${typeScale.textSm} !important;
            &[aria-label="Close"] {
                &:hover {
                    background: transparent !Important;
                }
            }
            
            &:not(.tox-lock, .tox-button--secondary) {
                background: white;
                color: ${theme.textPrimary} !important;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                &:hover {
                    color: ${theme.textInverted} !important;
                    background: ${theme.neutralPrimary} !important;
                }
                &:focus {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
                }
            }
            &[title="Save"] {
                background: ${theme.textPrimary} !important;
                color: #fff !important
            }
            &[title="Cancel"] {
                background: #fff !important;
                color: ${theme.textPrimary} !important;
                background: white;
                color: ${theme.textPrimary} !important;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                &:hover {
                    color: ${theme.textInverted} !important;
                    background: ${theme.neutralPrimary} !important;
                }
                &:focus {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
                }
            }
            &--icon {
                box-shadow: none !important;
            }
        }
        .tox-textfield, .tox-listbox--select {
            background: ${theme.inputBackground} !important;
            box-shadow: inset 0 0 3px rgb(0 0 0 / 10%) !important;
            border: 0 !important;
            font-size: ${typeScale.textSm} !important;
            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
            }
        }
        .tox-collection__item-label {
            font-size: ${typeScale.textSm} !important;
        }
        .tox-button--secondary {
            background-color: ${theme.neutralPrimary} !important;
            color: #fff !important
        }
    }
    .tox .tox-dialog--width-md {
        height: 450px !important;
    }
    .tox .tox-dialog--width-md .tox-dialog__body-content {
        height: 350px !important;

    }
    .tox-dialog {
        max-height: calc(100% - 175px) !important;
        top: 75px;
        .tox-dialog__content-js {
            flex-grow: 1;
            overflow: hidden;
        }
    } 
        
    .timesheet-time-input {
        max-height: 40px;
    }


    .modal-warning {
        .modal-content{
            border-radius: 7px !important;
        }
    }
    .config-materials-modal {
        @media (min-width: 580px) {
            max-width: 540px;
            width: 540px !important;
        } 
    }
    .modal{
        text-align: center;
        top:auto;
        bottom:0;
        width: calc(100vw - var(--leftsidebar-width));
        height: calc(100% - 115px);
        right:0;
        left:auto;

        @media(max-width: 1024px){
            width: 100%;
        }
        @media (max-width: 767px) {
            height: calc(100% - 140px);
        }

        .modal-dialog{
            height: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            margin:0;
            @media(max-width:767px){
                width: 100%;
            }
            &.color-picker-modal {
                @media (min-width:768px) {
                    max-width: 600px !important;
                    min-width: 500px !important;
                }
            }
            .modal-header{
                background: #333333;
                position:relative;
                h5{
                    width: 100%;
                    color: #fff;
                    font-size: 12px;
                    text-transform: capitalize;
                    font-weight: bold;
                }
                .close, .btn-close{
                    display:none;
                }
            }
            .modal-body{
                p{
                    line-height: 1.5;
                }
                .done{
                    border-radius: 4.5px;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
                    font-size:10px;
                    padding:8px 0;
                    min-width: 108px;
                }
                .disableEnd{
                    background: #9a9999;
                    pointer-events: none;
                }
                .elongate-ghost-button {
                    button {
                        width: 100%;
                    }
                }
            }
            .modal-footer{
                border: none;
                border-top: solid 1px #e3e3e3;
                justify-content: center;
                @media(max-width:1024px){
                    flex-wrap: wrap;
                }
                .noborder{
                    color:#999999;
                    font-size:12px;
                    background: none;
                    padding: 0;
                }
                // generalize
                .disabled {
                    &:hover {
                        background: transparent !important;
                        color: #333 !important;
                    }
                }
                .custom-tooltip-wrapper {
                    position: relative;
                    .custom-tooltip {
                        opacity: 0;
                        visibility: hidden;
                        color: #000;
                        white-space: nowrap;
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0);
                        padding: 8px 10px;
                        background: #fff;
                        border-radius: 5px;
                        transition: all 0.2s ease-out;
                        line-height: 1.5;
                        font-weight: 500;
                        &:before {
                            content: ' ';
                            height: 0;
                            position: absolute;
                            width: 0;
                            left: 50%;
                            border: 10px solid transparent;
                            border-bottom-color: #fff;
                            transform: translate(-50%, -50%);
                            top: -10px;
                        }
                        &--dark {
                            background: #333;
                            color: #fff;
                            &:before {
                                border-bottom-color: #333;
                            }
                        }
                    }
                    &:hover {
                        .custom-tooltip {
                            /* z-index: 9; */
                            opacity: 1;
                            visibility: visible;
                            transform: translate(-50%, 45px);
                        }
                    }
                }
            }
            .modal-content{
                border:none;
                border-radius: 7px;
            }
            &.budgeting-section-infomodal {
                @media (min-width: 768px) {
                   min-width: 700px;
                }
                h2 {
                    font-weight: 700;
                    ${typeScale.header2};
                    margin-bottom: 18px;
                }
                img {
                    margin-bottom: 16px;
                }
                p {
                    ${typeScale.textMd};
                    text-align: left;
                    line-height: 20px;
                    margin-bottom: 16px;
                }
                ol {
                    li {
                        text-align: left;
                        margin-bottom: 8px;
                    }
                }
                .modal-content {

                    overflow-y: auto;
                    .modal-body {
                        padding: 20px;
                    }
                }
                &--overhead {
                    .modal-content {
                        height: 100%;
                    }
                }
            }
            &.deviceModal{
                width: 97%;
                max-width: 100%;
                .modal-content {
                    height: calc(100vh - 140px);
                    height: 100%;
                }
                .modal-header{
                    background: #fff;
                    border: none;
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    z-index:999;
                    .close{
                        display:block;
                        padding:0;
                    }
                }
                .modal-body{
                    padding:0 65px;
                    height: 100%;
                    overflow: auto;
                    text-align: left;
                    @media (${breakpoints.maxMobile}) {
                        padding: 0 15px 15px;
                    }
                    .dimLine{
                        text-align: center;
                        width: 100%;
                        font-size: 12px;
                        padding: 26px 0;
                        color: #ccc;
                    }

                }
                .purchase {
                    .active {
                        cursor: pointer;
                    }
                }
            }
            &.contractModal{
                width: 100%;
                max-width: 98%;
                .headerContracts{
                    margin-bottom:32px;
                    .continueBtn{
                        font-size: 10px;
                        font-weight: 700;
                        letter-spacing: 0.17px;
                        min-width: 120px;
                        height: 30px;
                    }
                }
                .contractsField {
                    flex: 1;
                    display: grid;
                    grid-template-columns: repeat( 3, minmax(160px, 1fr) );
                    flex-wrap: wrap;
                    & > div {
                        padding: 0 10px !important;
                        &.total{
                            span.px-3{
                                padding-left: 0 !important;
                            }
                        }
                        .row{
                            margin:0;
                        }
                        .form-group{
                            width:100% !important;
                        }
                    }

                    @media (${breakpoints.maxMobile}) {
                        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr) );
                    }
                    .price.row * {
                        flex-shrink: 1 !important;
                    }
                    & > div .occurence {
                        width: 64px !important
                    }
                }
                .modal-content{
                    height: 96%;
                }
                .modal-header{
                    background:none;
                    border:none;
                    padding:0;
                    .close{
                        display: flex;
                        position: absolute;
                        padding: 0;
                        margin: 0;
                        top: 40px;
                        right: 50px;
                        z-index:99;
                        background: #fff;
                    }
                }
                button[type='submit']{
                    margin:0 0 0 auto !important;

                }
                .addJobBtn{
                    color: #fff;
                    display: flex;
                    background: #333;
                    margin-top: 20px;
                    width: auto;
                    padding: 9px 37px;
                    line-height: normal;
                    height: auto;
                }
                // TODO: too many of these strwen around. create a (better) component for this. check for checkboxtickswrapper inside modules/Inputs
                .customCheckbox{
                    display:flex;
                    input{
                        opacity:0;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        & ~ span{
                            width:15px;
                            height:15px;
                            border-radius: 1.1fpx;
                            box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.15);
                            background-color: #f9f9f9;
                            margin-right:10px;
                            border: solid 0.6px #e3e3e3;
                        }
                        &:checked{
                            & ~ span{
                                background:#333;
                                position:relative;
                                &:after {
                                    width: 5px;
                                    height: 8px;
                                    border: solid white;
                                    border-width: 0 2px 2px 0;
                                    -webkit-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                    content:'';
                                    position:absolute;
                                    top:2px;
                                    right:0;
                                    bottom:0;
                                    left:0;
                                    margin:0 auto;
                                    display:inline-flex;
                                    align-items:center;
                                    justify-content:center;
                                }
                            }
                        }
                    }
                    label{
                        font-size:10px;
                        color:#333;
                        margin: 0;
                        flex: 1;
                    }
                }
                .jobservices {
                    &--ondemand {
                        position: relative;
                        padding-bottom: 90px !important;
                        .addAnother {
                            position: absolute;
                            bottom: 30px;
                        }
                        .job-notes {
                            /* width: 300px; */
                            &--odj {
                                flex: 1 1 auto;
                                margin-bottom: 0 !important;
                                margin-right: 0;
                            }
                        }
                        .severity {
                            width: 125px;
                            .custom-design {
                                height: 35px;
                                div[class*='-control'] {
                                    height: 35px;
                                }
                            }
                        }
                        .proof-of-service {
                            width: 300px;
                            margin-bottom: 0 !important;
                            @media (${breakpoints.maxTabletLandscape}) {
                                margin-bottom: 20px;
                                width: auto;
                            }
                        }
                        .services-container {
                            width: 100%;
                            @media(${breakpoints.minTablet}) {
                                width: 250px;
                            }

                            .custom-design {
                                height: 35px;
                                div[class*='-control'] {
                                    min-height: 35px;
                                    div[class*='-indicatorContainer'] {
                                        padding: 6px;
                                    }
                                }
                            }
                        }
                        .total {
                            width: 121px;
                        }
                    }
                    &--empty {

                        h3 {
                            margin-bottom:20px;
                            font-size:12px;
                            font-weight:700;
                            text-align:left;
                            text-transform:uppercase;
                            margin: 0 0 0 4px;
                        }
                        .jobservices-empty-main {
                            font-size: 22px;
                            margin-bottom: 15px;
                            text-align: center;
                            color: #ccc !important;
                        }
                        .jobservices-empty-secondary {
                            font-size: 16px;
                            text-align: center;
                            color: #ccc !important;
                        }
                    }
                }
                .jobservices{
                    border-radius: 5px;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
                    padding: 20px 20px 0;
                    margin-bottom:15px;
                    position: relative;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    &--summary {
                        padding: 20px;
                        .table-header, .table-body {
                            ${typeScale.textMd};
                            & > div {
                                border-right: solid 1px ${theme.pageBackground}
                            }
                        }
                        .job-total {
                            ${typeScale.textMd}
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            background: ${theme.pageBackground};
                            display: flex;
                            justify-content: flex-end;
                            padding: 8px 20px;
                        }

                    }


                    h3{
                        margin-bottom:20px;
                        font-size:12px;
                        font-weight:700;
                        text-align:left;
                        text-transform:uppercase;
                        margin: 0 0 0 4px;
                    }
                    &--summary-jobpdf {
                            padding: 12px;
                            h3 {
                                text-transform: capitalize;
                            }
                            .table-body {
                                font-size: 10px !Important;
                            }
                        }
                    .total{
                        display:flex;
                        .form-group{
                            width:90px;
                        }
                    }
                    .CustomRadio{
                        display:flex;
                        input{
                            opacity:0;
                            & ~ label ~ span {
                                width: 87px;
                                line-height: 24px;
                                color: #333333;
                                font-size: ${typeScale.textSm};
                                display: inline-block;
                                text-align: center;
                                background: #fcfcfc;
                                box-shadow: inset 0 0 1px 0 rgba(0,0,0,0.1);
                                border-radius: 5px;
                            }
                            &:checked ~ label  ~ span{
                                display: inline-block;
                                border-radius: 5px;
                                background: #333;
                                font-weight:700;
                                transform: translateX(0px);
                                box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
                                transition: all 150ms;
                                vertical-align: top;
                                position: relative;
                                color:#fff;
                            }
                        }
                        .crew{
                            margin-right: 12px;
                        }
                    }
                    .RangeExample{
                        button{
                            box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.1) !important;
                            color: #333;
                            font-size: 10px;
                            margin: 0;
                            width: 100%;
                            height: 35px;
                            background: #fcfcfc;
                        }
                    }
                    .price{
                        .form-group{
                            width:70px;
                        }
                    }
                    .cross{
                        background: none;
                        padding: 0 24px 0 0;
                    }
                    .time-inline{
                        flex-wrap:nowrap;
                        .form-group{
                            width:95px;
                        }
                    }
                    .bigWidth .form-group{
                        width:180px;
                    }
                    .fre{
                        .form-group{
                            width:140px;
                        }
                    }

                    .addAnother{
                        padding:32px 42px 0 0;
                        flex-wrap: wrap;
                        @media (${breakpoints.maxMobile}) {
                            padding: 5px;
                            margin-top: 20px;
                        }
                        &.second{
                            flex:1;
                        }
                        .form-group:nth-child(2){
                            width: 200px;
                            ${'' /* margin-left:30px; */}
                        }
                        .addServiceBtn{
                            border-radius: 5px;
                            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2) !important;
                            font-size:10px;
                            color:#333;
                            padding:8px 18px;
                            line-height:normal;
                            font-weight:700;
                            border:none;
                        }

                        .right{
                            flex:1;
                            .filters{
                                width:200px;
                                margin-right:30px;
                            }
                            .form-group:last-child{
                                flex:1;
                            }
                        }
                    }
                    .finalPrice {
                        background: #f9f9f9;
                        margin:0 -20px;
                        display:flex;
                        &--black {
                            color: ${theme.textPrimary};
                        }
                        h4{
                            padding:8px 62px;
                            color:#333;
                            font-size:12px;
                            text-align:right;
                            width:100%;
                        }
                        &--odj {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                        }
                    }
                    &--summary {
                        .card-header {
                            background: #333;
                            color: #fff;
                            strong {
                                font-size: 10px;
                            }
                        }
                        .card {
                            height: 100%;
                        }
                    }
                }

                .modal-body{
                    padding: 40px 15px;
                    overflow: auto;
                    @media(${breakpoints.minTablet}) {
                        padding:40px 50px;
                    }
                    h2{
                        font-size: 18px;
                        text-align: left;
                        margin-bottom:0;
                    }
                    .jobsList {
                        .form-group{
                            margin-bottom:0;
                        }

                        .cross{
                            height: 100%;
                        }
                        [readonly]{
                            background:#fcfcfc;
                        }
                        .equal{
                            display: flex;
                            font-size: 30px;
                            color:#292929;
                            align-items: center;
                            margin-top:10px;
                        }
                    }
                }

                .contractTotal{
                    background: black;
                    color : white;
                    padding : 5px;
                    padding-right : 20px;
                    &--add-contract {
                        background: transparent;
                        padding: 0;
                        color: #333;
                    }
                }
            }
            .billingInfo{
                h3 {
                    margin-bottom:12px !important;
                    margin-left: 0 !important;
                    text-transform:uppercase;
                    display:inline-block;
                    font-size: 12px;
                    font-weight: bold;
                }
                .billing-options__card-heading {
                    font-size: 10px;
                    font-weight: bold;
                    text-align: center;
                }
                strong{
                    margin-bottom:12px;
                    text-transform:uppercase;
                    display:inline-block;
                }
                .list-group-item{
                    border-radius: 5px;
                    box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
                    padding: 15px;
                    margin-bottom: 10px;
                    text-align: left;
                    position:relative;
                    display: flex;
                    b{
                        font-size:10px;
                        display:inline-block;
                        width:100%;
                    }
                    span{
                        color: ${theme.textSecondary};
                        font-size:10px;
                        margin-top:2px;
                    }
                }
            }
        }
        .warning-modal-dialog-lg .modal-dialog {
            width: 420px;
        }
        .enable-invoices {
            width: 748px;

            @media(min-width:768px){
                max-width: 100%;
            }
        }
        .invoicequickbook{
            width: 748px;
            max-width: 100%;
            @media(max-width:1024px){
                align-items:flex-end;
            }
            .modal-header{
                background:none;
                border:none;
            }
            .modal-body{
                h2{
                    font-size: 26px;
                    margin:0 0 12px;
                }
                h3{
                    font-size: 16px;
                    font-weight:700;
                    margin:0 0 50px;
                }
                span{
                    display:inline-block;
                    width:100%;
                }
                .done{
                    width:239px;
                    font-size: 14px;
                    margin-bottom:50px;
                    margin-top:50px;
                    background:#333;
                    font-weight:400;
                }
            }
        }

        .rejected-records-modal {
            &.modal-dialog {
                max-width: 1170px;
                width: 100%;
                /* height: 500px; */
                .modal-content {
                    height:461px;
                    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.36);
                    @media(max-width:1024px){
                        overflow:visible;
                        height:auto;
                        min-height:461px;
                    }
                    @media (min-width: 1200px) {
                        height: 500px;
                    }
                    .rejected {
                        height: 100%;
                        padding: 30px 190px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        &__heading {
                            ${typeScale.modalHeader};
                                font-weight: bold;
                                text-align: center;
                                margin-bottom: 25px;
                            }
                            &__text {
                                ${typeScale.header2};
                            }
                            &__list {
                                list-style: none;
                                height: 155px;
                                display: flex;
                                align-items: center;
                                /* justify-content: center; */
                                flex-direction: column;
                                overflow-y: auto;
                                width: 100%;
                                padding-left: 0;
                            li {
                                ${typeScale.textSm};
                                font-weight: bold;
                                padding: 5px 40px;
                            }
                        }
                    }
                }
            }

        }

        .scheduleModal--route {
            .modal-content {
                    overflow-x: hidden !important;
                }
                .modal-header .close {
                    position:  static !important;
                }
        }
        .update-location {
            width: 580px;
            max-width: 580px;
            &__instructions {
                ${typeScale.textMd}
                text-align: center;
                padding: 20px;
                line-height: 1.83;
            }
            &__map {
                & > div:first-child {
                    height: 328px !important;
                    margin-bottom: 45px;
                }
            }
            &__ctawrapper {
                padding-top: 15px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 30px);
                    height: 1px;
                    left: -15px;
                    right: 0;
                    top: 0;
                    transform: translateY(-15px);
                    margin: 0;
                    background: ${theme.textFaded};
                }
            }
            .geosuggest__suggests-wrapper {
                width: 340px;
                margin-right: 0 !important;
            }
            .geosuggest__input {
                height: 25px;
                border-radius: 5px;
                box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
                background-color: #fcfcfc;
                border: none;
                font-size: 10px;
                width: 340px;
                margin-top: 10px;
                margin-left: 10px;
                padding: 0 30px 0 10px;
            }
            .geosuggest__suggests {
                box-shadow: none;
                margin-left: 10px;
                .geosuggest__item {
                    text-align: left;
                    padding-left: 10px;
                }
            }
        }
        .subscribed-details-modal {
            width: 1120px;
            max-width: 100%;
            @media(max-width:1024px){
                width:100%;
            }
            .modal-header{
                background: none;
                border: none;
                padding: 30px 30px 10px 30px;
                background: none;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                position: -webkit-sticky;
                position: sticky;
                top: 0px;
                z-index: 99;
                background: #fff;
                h5{
                    color:#333;
                    font-size: 18px;
                    font-weight: 400;
                    text-align: left;
                }
                .close{
                    display: block;
                    position: absolute;
                    right: 32px;
                    top: 27px;
                    font-weight: 300;
                    font-size: 30px;
                    opacity:1;
                    z-index: 9;
                }
            }
        }
        .custom-frequency-modal {
            .modal-body {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .orientation-notification {
            .modal-content{
                box-shadow: var(--modal-box-shadow);
            }
            .modal-header {
                display: none;
            }
        }
        .job-details-popover {
            height: 366px;
            overflow: hidden auto;
            background: #fff;
            .modal-content {
                height: 100%;
            }
        }
        .unscheduled-popover-body {
            max-height: 366px;
            overflow: auto;
            background: #fff;
        }
        .job-details-report {
            height: 366px;
            transform: translateY(50%) !important;
            overflow: hidden;
            .modal-content {
                overflow: auto;
            }
            .modal-body {
                padding-left: 0;
                padding-right: 0;
                * :not(.mini-spinner):not(button){
                    text-align: left;
                }
            }
        }

        .compose-email-modal {
            .modal-body {
                display: flex;
                flex-direction: column;
                .tox-tinymce {
                    flex-grow: 1;
                    overflow: hidden;
                    box-shadow: var(--input-box-shadow);
                    border-radius: 5px;
                    border: 0;
                }
            }
        }
        .tox .tox-tbtn svg {
            fill: ${theme.neutralPrimary} !important;
        }
        .tox .tox-tbtn {
            color: ${theme.neutralPrimary} !important;
        }
        .tox .tox-tbtn--disabled svg,
        .tox .tox-tbtn--disabled:hover svg,
        .tox .tox-tbtn:disabled svg,
        .tox .tox-tbtn:disabled:hover svg {
            fill: ${theme.disabled} !important;
        }

        .scheduleModal {
            width:1100px;
            max-width: 100%;
            @media(max-width:1024px){
                width:100%;
            }
            .sticky {
                position: sticky;
                top: 100px;
                &.edit-actions {
                    top: 155px;
                    background: #fff !important;
                    @media(${breakpoints.maxTablet}) {
                        top: 80px;

                    }
                }
            }
            .modal-heading {
                z-index: 99;
                background: #fff;
                margin: 0 -5px;
                padding: 10px 5px;
                flex-wrap: wrap;
            }
            .popup-action-buttons {
                z-index: 99;
                transform: translate(0px, -31px);
                flex-wrap: wrap;
                padding-left: 5px;
                &.edit-actions {
                    transform: translate(0px, -85px);
                    @media(${breakpoints.maxTablet}) {
                        background: #fff;
                        transform: translate(0px,-15px);
                        padding-top: 5px;
                    }
                }
                &.route-form {
                    background: #fff;
                }
            }
            .route-total {
                width: 100%;
                padding: 7px 30px;
                text-align: right;
                font-size: 12px;
                background: #f9f9f9
            }
            .modal-content{
                height: calc(100vh - 110px);
                @media(max-width:1024px){
                    height: 100%;
                }
                overflow: auto;
                .staticButton{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: -40px;
                    right: 0;
                    left: 0;
                    .form-group{
                        margin:0 10px;
                    }
                }
            }
            .modal-header{
                background: none;
                border: none;
                padding: 30px 30px 10px 30px;
                background: none;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                position: -webkit-sticky;
                position: sticky;
                top: 0px;
                z-index: 99;
                background: #fff;
                h5{
                    color:#333;
                    font-size: 18px;
                    font-weight: 400;
                    text-align: left;
                }
                .close{
                    display: block;
                    position: absolute;
                    right: 32px;
                    top: 27px;
                    font-weight: 300;
                    font-size: 30px;
                    opacity:1;
                    z-index: 9;
                }
            }
            .contracts-field {
                width: 95%
            }
            .job-range {
                button {
                    width: 100%;
                    background-color: #fcfcfc;
                    color: #333333;
                    border: none;
                    box-shadow: inset 0 0 3px rgba(0,0,0,0.1) !important;
                    padding: 10px 15px;
                    font-size: 10px;
                }

            }
            .addAnother {
                width: 280px;
            }
            .carousel-control-next-icon, .carousel-control-prev-icon{
                border-radius: 50%;
                padding: 10px;
                background-size: 70% 70%;
                background-repeat: no-repeat;
                background-position: center;
                width: 40px;
                background-color: #000;
                height: 40px;
            }
            .modal-content{
                border-radius: 10px;
                overflow-y: auto;
                background: #fff;
            }
            .modal-body{
                padding: 10px 30px;
                padding-top: 0;
                @media(max-width:767px){
                    ${
                      '' /* overflow: auto;
                    height: 410px;
                    padding: 15px; */
                    }
                }
                .react-select__menu .react-select__menu-list{
                    max-height: 240px;
                }
                .edit-job-container {
                    .job-edit-form__card {
                        &:nth-child(2) {
                            /* margin-top: -30px; */
                        }
                    }
                    ${
                      '' /* .job-status {
                        grid-column: 2 !important
                    } */
                    }
                }
                .job-details{
                    &__owner-details {
                        margin-bottom: 25px;
                        font-size: 12px;
                        text-align: left;
                    }
                    .done{
                        line-height:initial;
                        margin-bottom:10px;
                    }
                }
                .table {
                    margin:0 0 21px;
                    table-layout: fixed;
                    &.table-striped{
                            & th:first-child, tbody td:first-child{
                            padding-left: 40px !important;
                            @media(max-width:1024px){
                                padding-left:10px !important;
                            }
                        }
                    }
                    td, th{
                        border:none;
                        p{
                            font-weight: 400;
                            line-height: normal;
                            font-size: 10px;
                            text-align:left;
                        }
                    }
                    &.table-striped tbody td{
                        span{
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 110px;
                            display: inline-block;
                            overflow: hidden;
                            text-align:left;
                            @media(max-width:1024px){
                                text-align:left;
                                width: 70px;
                                white-space: normal;
                            }
                        }
                    }
                    tbody tr{
                        &:nth-of-type(odd){
                            background:#f9f9f9;
                            td{
                                padding:21px .75rem;
                                font-size:11px;
                                color: #333333;
                                text-align: left;
                            }
                        }
                    }
                    &.schedule-date{
                        th{
                            text-align:left;
                            padding: 0 0 14px;
                            p{
                                color:#666;
                            }
                        }
                        tbody tr{
                            &:nth-of-type(odd){
                                background:none;
                                td{
                                    font-size:11px;
                                    color: #333333;
                                }
                            }
                            & td{
                                padding: 0 40px 0 0;
                                @media(max-width:1024px){
                                    padding:0 10px 0 0;
                                    &.time-inline {
                                        padding-right: 3px !important;
                                    }
                                }
                                label{
                                    width: 100%;
                                    text-align: left;
                                    margin:0 0 14px;
                                }
                                &:last-child{
                                    padding-right:0;
                                }
                                .custom-design{
                                    & > div{
                                        border-radius: 5px;
                                        box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
                                        background-color: #fcfcfc;
                                        min-height: 35px;
                                        padding: 0 4px;
                                        border: none;
                                        margin-left: 0;
                                    & div{
                                        &:first-child{
                                            & div{
                                                input{
                                                    width:0 !important;
                                                }
                                            }
                                        }
                                    }
                                    }
                                    & div[aria-hidden="true"]{
                                        padding:0;
                                    }
                                }
                                &.time-inline{
                                    .row{
                                        display:flex;
                                        margin: 0;
                                        flex-wrap:wrap;
                                        .form-group{
                                            margin:0;
                                            align-items:center;
                                        }
                                        & > .form-group{
                                            display:flex;
                                            align-items: baseline;
                                            width:100%;
                                            @media(max-width:1024px){
                                                flex-wrap:wrap;
                                            }
                                            @-moz-document url-prefix() {
                                                align-items:flex-start;
                                            }
                                        }
                                        .col{
                                            padding:0 5px 0 0;
                                            @media(max-width:1024px){
                                                width: 100%;
                                                flex: auto;
                                                margin: 0 0 10px;
                                            }
                                        }
                                        .time-dots{
                                            line-height: 30px;
                                            padding-right: 5px;
                                            padding-left: 0;
                                            @media(max-width:1024px){
                                                display:none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &--notesmodal {
                .carousel-item {
                    height: calc(100vh - 300px);
                    max-width: 100%;
                    @media (max-width: 1025px) {
                        height: calc(100vh - 330px);
                    }
                    img {
                        height: auto;
                        object-fit: contain;
                        /* width: 100%; */
                        max-height: calc(100vh - 300px);
                        @media (max-width: 1025px) {
                            height: calc(100vh - 330px);
                        }
                    }
                }
            }
            &--odjedit {
                transition: all 0.6s ease-out;
                .form-group {
                    text-align: left;
                }
                .modal-header {
                    background: #fff !Important;
                }
                .card {
                    .card-header {
                        background: #000;
                        color: #fff;
                        ${typeScale.textSm};
                    }
                    .card-body {
                        p {
                            margin-bottom:8px;
                            text-align: left;
                        }
                    }
                }
            }
            .close {
                position: static !Important
            }
        }
        }
        .employeelist-location-modal {
            &__map-container {
                margin-top: 40px;
                height: calc(100% - 110px);
                display: flex;
                flex-direction: column;
                & > div {
                    &:first-child {
                        order: 2;
                    }
                    &:last-child { order: 1;}
                }
            }
        }
        .canbus-modal {
            width: 100%;
            .modal-content {
                overflow: hidden;
            }
            .modal-body {
                height: calc(100% - 40px)
            }
        }
        .plan-details {
            width: 100% !important;
            .card-header {
                background: ${theme.neutralPrimary};
                color: ${theme.textInverted};
                ${typeScale.textLg};
            }
            .settings-plan {
                text-align: left;
                .plan {
                    margin-top: 40px;
                    width: 100%;
                    padding-left: 0 !important;
                    flex-direction: column;
                    &__row {
                        @media (min-width: 768px) {
                            width: 535px;
                            margin: 0 auto;
                        }
                    }
                    @media (min-width: 1366px) {
                        flex-direction: row;
                    }
                    @media (max-width: 1366px) {
                        margin-top: 40px;
                    }
                    /* lots of important to be used here since previous dev added styles to general class names */
                    .card {
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
                        border-radius: 2px;
                        height: 100%;
                    }
                    .active {
                        .card {
                            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        }
                        .card-header {
                            background: ${theme.textPrimary} !important;
                        }
                    }
                    .card-header {
                        background: ${theme.neutralPrimary} !important;
                        padding: 0 !important;
                        strong {
                            ${typeScale.textSm};
                            color: ${theme.textInverted} !important;
                            font-weight: bold !important;
                            text-align: center !important;
                            width: 100% !important;
                            padding: 10px 7px !important;
                        }
                    }
                    .card-body {
                        padding-top: 15px !important;
                        button#PremiumPlan,
                        button#BasicPlan {
                            margin: 0 !important;
                            margin-bottom: 20px !important;
                        }
                    }
                    &__price {
                        display: none;
                    }
                    &__tarrif {
                        display: none;
                    }
                    &__duration {
                        display: none;
                    }
                    &__finePoints {
                        color: ${theme.textFaded};
                        list-style: none;
                        padding-left: 0px;
                        margin-bottom: 0;
                        li {
                            display: flex;
                            align-items: center;
                            /* ${typeScale.textSm}; */
                            margin-bottom: 7px;
                            span {
                                font-size: 0;
                                img {
                                    width: 10px;
                                    height: 10px;
                                    margin-right: 3px;
                                }
                            }
                        }
                    }

                }
            }

            .plan-change {
                width: 55%;
                margin: auto;
                margin-top: 30px;
                &__lost-features {
                    text-align: center;
                    width: 69%;
                    margin: auto;
                    font-weight: bold;
                    list-style: none;
                    margin-top: 20px;
                }
            }
            .plan-details-collapse {
                order: 3;
                @media (min-width: 1366px) {
                order: 1;
                display: block !important;
                }
            }
            .show-plandetails-mobile {
                text-align: center;
                text-decoration: underline;
                margin-top: 20px;
                margin-bottom: 20px;
                color: ${theme.textFaded};
                order: 2;
                @media (min-width: 1366px) {
                    display: none;
                    order: 3;
                }
                &.show {
                    button svg {
                        transform: rotate(180deg);
                        transition: all 0.6s ease 0s;
                    }
                }
                button {
                padding-right: 0;
                svg {
                    path {
                        stroke: ${theme.textFaded};
                        }
                    }
                }
                /* placement of the select button is different for mobile and desktop */
                & ~ button {
                    order: 1;
                    @media (min-width: 1366px) {
                        order: 2;
                    }
                }
            }
        }
        .input-spinner {
            position: relative;
        }
        /* Spin Buttons modified */
        input[type="number"].input-spinner::-webkit-outer-spin-button,
        input[type="number"].input-spinner::-webkit-inner-spin-button {
            -webkit-appearance: none;
            background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
            width: 1.5em;
            transform: scale(1.5);
            opacity: .5;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
        input[type="number"].input-spinner::-webkit-inner-spin-button:hover,
        input[type="number"].input-spinner::-webkit-inner-spin-button:active{
            opacity: 1;
        }
        .input-spinner {
            position: relative;
        }
        /* Spin Buttons modified */
        input[type="number"].input-spinner::-webkit-outer-spin-button,
        input[type="number"].input-spinner::-webkit-inner-spin-button {
            -webkit-appearance: none;
            background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
            width: 1.5em;
            transform: scale(1.5);
            opacity: .5;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
        input[type="number"].input-spinner::-webkit-inner-spin-button:hover,
        input[type="number"].input-spinner::-webkit-inner-spin-button:active{
            opacity: 1;
        }
        .input-spinner {
            position: relative;
        }
        /* Spin Buttons modified */
        input[type="number"].input-spinner::-webkit-outer-spin-button,
        input[type="number"].input-spinner::-webkit-inner-spin-button {
            -webkit-appearance: none;
            background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
            width: 1.5em;
            transform: scale(1.5);
            opacity: .5;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
        input[type="number"].input-spinner::-webkit-inner-spin-button:hover,
        input[type="number"].input-spinner::-webkit-inner-spin-button:active{
            opacity: 1;
        }
        .pendingjobs{
            & .modal-header{
                padding:30px 0;
            }
            .jobPrice{
                button{
                    margin: auto;
                }
            }
            width:1200px;
            & .tableHolder{
                display:flex;
                flex-wrap:nowrap;
                align-items: flex-start;
                .done-light{
                    background:none;
                    color:#333 !important;
                    width: auto;
                }
                & > table{
                    display: flex;
                    table-layout: fixed;
                    flex: 0 0 50%;
                    flex-direction: column;
                    margin:0px !important;
                }
                .done{
                    width: auto;
                    min-width: 108px;
                }
                .table.schedule-date {
                    & .form-group{
                        margin-bottom:0px;
                    }
                }
                & tbody, thead{
                    flex:1;
                    & tr{
                        display:flex;
                        & th, td{
                            height: 40px;
                            flex: 1;
                            padding: 0 !important;
                            display: flex;
                            align-items: center;
                            padding:.75rem !important;
                            & *{
                                width:100%;
                                & [class*="css"]{
                                    width:auto;
                                }
                            }
                        }
                        & td{
                            height: 58px;
                            background: #f9f9f9;
                        }
                    }
                    & p{
                        color:#000 !important;
                        text-align:left
                    }
                }
            }
            & button{
                margin-bottom:20px;
            }
        }
        // responsemodalwrapper2437

        .success-modal {
            text-align: center;
            width: 100%;
            @media (min-width: 768px) {
                width: 600px !important;
                max-width: 600px;
            }
            .modal-body {
                @media (min-width: 768px) {
                    padding: 50px 40px;
                }
            }
            h2.success-modal-heading {
                ${typeScale.modalHeader};
                font-weight: bold;
                text-align: center;
                margin-bottom: 25px;
            }
            h3.success-modal-subheading {
                text-transform: uppercase;
                color: ${theme.textFaded};
                ${typeScale.textMd};
                font-weight: bold;
                letter-spacing: 0.5px;
                margin-bottom: 35px;
                .device-type {
                    white-space: nowrap;
                }
            }
            .success-denoting-message {
                width: 80px;
                height: 80px;
                margin-bottom: 35px;
            }
            .success-modal-addons {
                li {
                    text-transform: uppercase;
                    color: ${theme.textFaded};
                    ${typeScale.textMd};
                    font-weight: bold;
                    letter-spacing: 0.5px;
                }
            }
            p.success-text {
                ${typeScale.textMd};
                line-height: 1.5;
                margin-bottom: 25px;
            }
        }

    }
    .warning-modal-dialog-lg .modal-dialog {
        width: 420px;
    }
    .trial-notifier {
        .modal-backdrop {
            height: 100vh;
        }
        .modal {
            height: 100vh;
            width: 100vw;
        }
        &__cancel {
            display: none;
        }
        // TODO: once v1.2 is merged the following CSS will be redundant. check for consistency and remove these
        &__confirm {
            font-weight: bold;
            ${typeScale.textSm};
            padding: 8px 30px !important;
            background: ${theme.neutralPrimary} !important;
            line-height: 1.79;
        }
        .modal-body {
            ${typeScale.textLg};
            padding: 15px 35px;
        }
    }
    .searchBar {
      input {
        height: 30px;
        padding: 0 20px;
        border-radius: 5px;
        width: 100%;
        border: none;
        font-size: 10px;
        box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
        background: #fcfcfc url(${searchIcon}) no-repeat scroll right 20px center;
      }
    }
    /* Safari 10.1+ (alternate method) */
    @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) {
            .modal {
                /* height: calc(100vh - 220px); */
            }
        }}
        .full-backdrop {
            .modal-backdrop {
                height: 100vh !important;
            }

        }
    //modal wrapper classes
    .modal-operator {
        .modal-backdrop {
            height: calc(100vh - 46px);
        }
    }
    .company-login {
        .modal {
            @media (min-width: 1377px) {
                height: calc(100vh - 85px)
            }
        }
        &.tab-ios {
            &.tab-safari {
                .modal {
                    @media (max-width: 1024px) {
                        height: calc(100vh - 77px); // ipad 7th gen
                    }
                }
                .tui-image-editor-container {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 110px) !important;
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - 78px) !important;
                    }

                }
                .estimate-map-container {
                    height: calc(100vh - 275px);
                }
            }
            &.tab-chrome {
                .modal {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 150px);
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - var(--leftsidebar-width));
                    }
                    .modal-body .rightsidewrap {
                        @media (max-width: 1366px) and (orientation: landscape) {
                            height: calc(100vh - 160px) !important;
                        }
                    }
                }
                .tui-image-editor-container {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 150px) !important;
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - var(--leftsidebar-width)) !important;
                    }

                }
                .estimate-map-container {
                    height: calc(100vh - 320px);
                }
            }
        }

        &.tab-android {
            .modal {
                @media (max-width: 1366px) {
                    height: calc(100vh - 170px);
                }
                @media (max-width: 1024px) {
                    height: calc(100vh - 140px);
                }
            }
            .estimate-map-container {
                @media (max-width: 1366px) {
                    height: calc(100vh - 310px);
                }
            }
            /* .tui-image-editor-container .tui-image-editor-controls {
                @media (max-width:1200px) and (min-width: 998px) {
                    bottom: 68px;
                }
                @media (max-width: 768px) {
                    bottom: 35px;
                }
            }
            .tui-image-editor-container .tui-image-editor-main-container {
                @media (max-width:1200px) and (min-width: 998px) {
                    height: 75%;
                }
                @media (max-width: 768px) {
                    height: 90%;
                }
            } */
            .submit-form {
                margin-bottom: 120px;
            }
        }


    }
    .pdf-modal-wrapper {
        .modal {
            @media (min-width: 1025px) and (max-width: 1200px) {
                height: calc(100vh - 120px);
            }
            .close {
                @media (min-width: 1201px) {
                    margin-top: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    .admin-login {

        &.tab-ios {
            &.tab-chrome {
                .modal {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 192px); // - for ipad pro and ipad, chrome (landscape)
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - 154px); // ipad, chrome (potrait)
                    }
                    .estimate-map-container {
                        height: calc(100vh - 350px)
                    }
                    .modal-body .rightsidewrap {
                        @media (max-width: 1366px) and (orientation: landscape) {
                            height: calc(100vh - 190px) !important;
                        }
                    }
                }
                .tui-image-editor-container {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 185px) !important;
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - 155px) !important;
                    }

                }
                .tui-image-editor-container .tui-image-editor-controls {
                    height: 85px;
                }
            }
            &.tab-safari {
                .tui-image-editor-container {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 150px) !important;
                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - 120px) !important;
                    }

                }
                .tui-image-editor-container .tui-image-editor-controls {
                    height: 85px;
                }
                .modal {
                    @media (max-width: 1366px) {
                        height: calc(100vh - 153px); // - for chrome (landscape)

                    }
                    @media (max-width: 1024px) {
                        height: calc(100vh - 118px);
                    }
                    .estimate-map-container {
                        height: calc(100vh - 320px)
                    }
                    .modal-body .rightsidewrap {
                        @media (max-width: 1366px) and (orientation: landscape) {
                            height: calc(100vh - 160px) !important;
                        }
                    }
                }
            }
        }
        &.tab-android {
            .modal {
                @media (max-width: 1366px) {
                    height: calc(100vh - 215px);
                }
                @media (max-width: 1024px) {
                    height: calc(100vh - 195px);
                }
                @media (max-width: 768px) {
                    height: calc(100vh - 190px)
                }
                .estimate-map-container {
                        height: calc(100vh - 360px)
                }

            }
            .submit-form {
                margin-bottom: 120px;
            }
        }
    }
    .cancel-subscription-warning {
        .modal-footer {
            display: none;
        }
        li {
            line-height: 1.5;
            margin-bottom: 10px;
            .fw-bold button {
                font-weight: bold !important;
            }
        }
    }
    .pendingJobs {
        .jobsName {
          &--odj {
            position: relative;
            &:before {
              content: '';
              background-image: url(${ODJ_Icon});
              background-size: 23px 20px;
              display: inline-block;
              width: 23px;
              height: 20px;
              position: absolute;
              background-repeat: no-repeat;
              left: -18px;
              top: 2px;
            }
          }
        }

    }

    .lds-roller {
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        top:0;
        left:0;
        bottom:0;
        right:0;
        &.schedule-loader {
            position: absolute;
        }
        div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 32px 32px;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #fff;
                margin: -3px 0 0 -3px;
                opacity:0.8px;
            }
            &:nth-child(1) {
                animation-delay: -0.036s;
                &:after {
                    top: 50px;
                    left: 50px;
                }
            }
            &:nth-child(2) {
                animation-delay: -0.072s;
                &:after {
                    top: 54px;
                    left: 45px;
                }
            }
            &:nth-child(3) {
                animation-delay: -0.108s;
                &:after {
                    top: 57px;
                    left: 39px;
                }
            }
            &:nth-child(4) {
                animation-delay: -0.144s;
                &:after {
                    top: 58px;
                    left: 32px;
                }
            }
            &:nth-child(5) {
                animation-delay: -0.18s;
                &:after {
                    top: 57px;
                    left: 25px;
                }
            }
            &:nth-child(6) {
                animation-delay: -0.216s;
                &:after {
                    top: 54px;
                    left: 19px;
                }
            }
            &:nth-child(7) {
                animation-delay: -0.252s;
                &:after {
                    top: 50px;
                    left: 14px;
                }
            }
            &:nth-child(8) {
                animation-delay: -0.288s;
                &:after {
                    top: 45px;
                    left: 10px;
                }
            }
        }
    }
    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .form-control.is-invalid, .was-validated .form-control:invalid, .form-control.is-valid, .was-validated .form-control:valid{
        background-position: center right 8px !important;
    }

    .geosuggest__item{
        font-size: 10px !important;
        span{
            b{
                font-weight:normal;
            }
        }
    }
    .DayPickerInput{
        width:100%;
        .DayPickerInput-Overlay {
            height: 312px;
        }
        @media(max-width:1024px){
            z-index:999;
        }    
    }
    .device-dropdown-wrapper, .job-dropdown-wrapper {
        display: none;
        @media (min-width: 1024px) and (max-width: 1365px) {
            display: block;
        }
    }
    .device-group-wrapper, .job-group-wrapper {
        display: flex;
        @media (min-width: 1024px) and (max-width: 1365px) {
            display: none;
        }
    }
    .sticky-btn{
        position: sticky;
        top: 231.5px;
        background: #fff;
        z-index:9;
        @media(max-width:1024px){
            left: 0;
            bottom:0;
            top:auto !important;
            right: 0;
            margin: auto;
            justify-content: space-around !important;
            position:fixed;
            padding:20px 0;
            margin-bottom:0 !important;
        }
        @media only screen
        and (min-device-width : 576px)
        and (max-device-width : 1024px)
        and (orientation : landscape) {
            padding-top: 10px;
            padding-bottom: 10px !important;
        }
        &.companyPage{
            top:184px;
        }
        &__notop {
            top: unset !important;
        }
    }
    /* .yellow{
        border-left:5px solid #e0d121 !important;
    }
    .green{
        border-left:5px solid #5db85c !important;
    }
    .grey{
        border-left:5px solid #e3e3e3 !important;
    }
    .red{
        border-left:5px solid #cc0000 !important;
    }
    .black{
        border-left:5px solid #333333 !important;
    } */
    .blue{
        background-color :#AEE6FF;
        font-weight: bold;
    }
    .notifDot {
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background-color: #cc0000;
        position: absolute;
        left: -4px;
      }
    .notif{
        margin-top: 12.5px;
    }
    .modal-open .size{
        z-index:99999;
    }
    .input-group-prepend .input-group-text{
        background: none;
        border: none;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
        background-color: #fcfcfc;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .schedule-map-clustermodal-wrapper {
        .modal {
            display: block;
            width: calc(100% - 32%);
            left: var(--leftsidebar-width);
            height: 440px;
            top: 110px;
        }

    }
    .schedule-map-clustermodal {
        .modal-content {
            transform: translate(-50%,120px) !important;
            left: 50%;
        }
        .modal-body {
            max-height: 122px;
            overflow: auto;
            * :not(.mini-spinner):not(button){
                text-align: left;
            }
        }
    }

    // To reset list item styles
    .reset-list-styles {
        p, h1, h2, h3, h4, h5, h6, li, pre, span {
            font-size: revert;
        }

        ol li {
            list-style: auto;
        }

        ul li {
            list-style: initial;
        }
    }

    .calendar-main{
        position:relative;
        display:flex;
        background: #f9f9f9;
        @media(max-width:1024px){
            flex-wrap:wrap;
        }
        .calendarLeft{
            width:100%;
            position:relative;
        }
        .mapToggle {
            .maptogglebtn{
                    color:#333;
                    background: none !important;
                    padding: 0 10px;
                }
            }
            .maptogglebtn {
                color: #999;
                min-width:auto;
                padding: 0 15px;
                background: none;
                display: flex;
                align-items: center;
                margin-left: auto;
                h6{
                    font-size:10px;
                }
            }
        }

        .calendar-left{
            width:100%;
            height: calc(100vh - 108px);

            @media(max-width:1024px){
                height:100%;
            }
            .allcustomerButton {
                position: absolute;
                z-index: 9;
                right: 60px;
                color: #333 ;
                top: 10px;
                background: #fff;
                .dropdown-menu.show{
                    transform: translate3d(0px, 35px, 0px) !important;
                }
                button{
                    background-color: #fff !important;
                    font-size: 10px !important;
                    font-weight: bolder !important;
                    letter-spacing: 0.5px;
                    color: #000 !important;
                    padding: 10px 20px 10px 10px;
                }
                img{
                    margin: auto 8px auto 8px;
                }
                &.hide{
                    color:#999999
                }
            }
        }
        .calendar-scroll{
            width: 100% !important;
            height: calc(100vh - 81px) !important;
            background: #f9f9f9;
            @media(max-width:1024px){
                height:60px !important;
            }
        }
        .calendarRight{
            width: 32% !important;
            height: 100% !important;

            background:#fff;
            @media (max-width: 1470px) {
                height: calc(100vh - 135px) !important;
            }
            @media(max-width:1024px){
                ${'' /* width: 100% !important; */}
                height: calc(100vh - 178px) !important;
            }
            & > div:first-child {
                height: 100%;
                overflow-x: hidden !important;
                overflow-y: auto !important;
            }
            & ${JobsStatus}{
                height:auto;
            }
        }
    }
    .calendarBase {
        display: flex;
        position: relative;
        z-index: 9;
        background: #fff;
        bottom: 0;
        width: calc(100% - 123px);
        border-radius: 5px;
        margin: 0 0 40px auto;
        box-shadow: 0 0 7px 0 rgba(0,0,0,0.09);
        right: 40px;
        @media(max-width:1024px){
            display:none;
        }
        span {
            width: 100%;
            border-right:solid 1px #e3e3e3;
            padding:10px;
            &:last-child{
                border-right:none;
            }
            button {
                color: #333;
                padding: 3px 0;
                min-width: 30px;
                margin-top: 5px;
                border: none;
                height: 30px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15) !important;
                border-radius: 50%;
            }
            p{
                font-size: 8px;
                color:#333;
                margin:0;
                line-height: 1.88;
                text-align: left;
            }
        }
    }
    body .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
        background: #333 ;
        color:#fff !important;
        font-weight:400;
    }
    body .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
        background:#333;
    }
    body .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){
        background:#f7f7f7 !important;
        color: #666 !important;
    }
    body .DayPicker .DayPicker-wrapper{
        height: 262px;
    }
    body .DayPicker-Day{
        font-size:12px;
        color:#999 !important;
    }
    .DayPicker-Weekday{
        font-size:12px;
        color:#333 !important;
    }
    .popover{
        border-radius:10px;
    }
    .calendar-popup{
        z-index:999;
        max-height: 366px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        max-width: 330px;
        background: #fff;
        .popover {
            z-index: 999;
            max-height: 366px;
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            max-width: 330px;
            background: #fff;
        }
        @media(max-width:1024px){
            ${'' /* display:none; */}
        }
    }
    .DayPicker-Day{
        color:#666 !important;
    }
    .job-notes {
        &--username {
            font-weight: bold !important;

            max-width: 155px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .thumb-img{
        .jobnotes-image-container {
            display: inline-block;
            .img-timestamp {
                font-size: 9px;
                text-align: right;
            }
        }
        .jobnotes-imagewrapper {
            height: 145px;
            display: inline-block;
            vertical-align: top;
            margin: 0 5px 0 5px;
            margin-bottom: 10px;
            overflow:hidden;
            text-align: right;
            img {
                height: 100%;
                object-fit: cover;
                /* width: 100%; */
                cursor: pointer;
                max-width: fit-content;
            }
        }
        p{
            display: inline-block;
            margin-top: 20px !important;
        }
    }
    .Serial_Number.device{
        label{
            float:left;
        }
        & [tooltip]{
            margin:0;

        }
    }
    .gm-style {
        font-family: Verdana !important;
        img{
            max-width: 100%;
            vertical-align: top;
        }
        button{
            min-width:  auto !important;
            padding: 0;
            margin-top: 0 !important;
        }
        .thumb-img {
            margin: 10px 0 0;
        }
        .gm-svpc img{
            max-width:none;
        }
    }
    .no-border {
        border: 0;
    }
    .job-details-customer {
        ${typeScale.textLg};
        color: ${theme.textPrimary};
        font-weight: bold;
    }
    .calendar-left{
        .mapToggle {
            text-align: right;
            flex: 1;
        }
        button.maptogglebtn{
            background: none !important;
            box-shadow: none !important;
            min-width: auto !important;
            padding: 0;
        }
        .national {
            padding-left: 30px;
            padding-top: 5px;
            @media(max-width:1024px){
                display:none;
            }
        }
    }
    .import{
        padding:20px;
        .CustomRadio {
            input {
                opacity: 0;
                & ~ label {
                    & ~ span {
                        width: 87px;
                        line-height: 24px;
                        color: #333333;
                        font-size: ${typeScale.textSm};
                        display: inline-block;
                        text-align: center;
                        background: #fcfcfc;
                        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        @media(max-width:767px){
                            width: 75px;
                        }
                    }
                }
                &:checked {
                    & ~ label {
                        & ~ span {
                            display: inline-block;
                            border-radius: 5px;
                            font-weight:700;
                            transform: ${props =>
                              props.checked ? 'translateX(88px)' : 'translateX(0px)'};
                            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                            transition: all 150ms;
                            vertical-align: top;
                            position: relative;
                            color: #fff;
                            background: #333333;
                        }
                    }
                }
            }
        }
    }
    .estimated-hours {
        .input-feedback {
            width: 100%; 
            background: #fff;
            white-space: normal;
        }
    }
    .estimated-minutes {
        .input-feedback {
            width: 100%;
            background: #fff;
            white-space: normal;
        }
    }
    .row.time-inline{
        display:flex;
        margin: 0;
        flex-wrap:wrap;
        .form-group{
            margin:0;
            align-items:center;
        }
        & > .form-group{
            display:flex;
            align-items: baseline;
            width:100%;
            @-moz-document url-prefix() {
                align-items:flex-start;
            }
        }
        .col{
            padding:0 5px 0 0;
        }
        .time-dots{
            line-height: 30px;
            padding-right: 5px;
            padding-left: 0;
            width: auto;
        }
    }
    .gm-style-iw-d {
        /* max-height: auto; */
        max-height: 100%;
        overflow: hidden;
        padding-left: 20px;
        & > div {
            margin-top: 10px;
        }
    }
    .gm-style .gm-style-iw-c {
        padding: 18px;
        width: 360px;
        overflow-y: auto;
    }
    .gm-style-iw-t {
        button[aria-label='Close'] {
            opacity: 1;
            top: 10px !important;
            right: 10px !important;
            width: 30px !important;
            height: 30px !important;
            img {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }
    .gm-style .gm-style-iw-c {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0 !important;
    }
    .gm-ui-hover-effect[aria-label='Close'] {
        span {
            width: 20px !important;
            height: 20px !important;
        }
    }
    
    .gm-style-iw-a {
        strong {
            ${typeScale.textSm};
        }
        .header {
            h1 {
                min-height: 100% !important;
            }
            .arrows {
                width: 10px;
                height: 10px;
                margin-bottom: 0;
                svg {
                    font-size: inherit;
                    transform: translateY(-5px);
                }
            }
        }
        button {
            box-shadow: none !important;
        }
        p{
            font-size: 10px;
            margin: 0 0 2px;
            color: #333;
        }
        .left {
            padding: 18px 0px;
        }
        strong{
            font-weight:700;
        }
        h3 {
            border-radius: 3.9px;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            font-size: 10px !important;
            color: #333333 !important;
            display: inline-block;
            padding: 10px;
            width: 100%;
        }
    }
    .errorPage{
        height:100vh;
        h4{
            font-weight:700;
            color:#333333;
            font-size: 18px;
            line-height: 2.06;
            margin:34px 0;
        }
        .btn{
            width: 120px;
            height: 30px;
            border-radius: 5px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
            background-color: #cc0000;
            padding:0;
            border:none;
            font-size:10px;
            font-weight:700;
            color:#fff;
            display:inline-block;
            align-items:center;
            justify-content:center;
            line-height:30px;
        }
    }
    .errorWrap{
        flex-flow: column wrap;
        background:#fff;
        height:100vh;
        .logoError{
            margin-bottom: auto;
            margin-top: 16vh;
            padding: 10px;
            border-radius: 20px;
        }
        h4{
            font-size: 22px;
            font-weight: bold;
            line-height: 2.06;
            color: #333;
            margin: 0 0 34px;
            padding: 15px;
            border-radius: 10px;
        }
        .btn{
            width: 120px;
            height: 30px;
            border-radius: 5px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
            background-color: #cc0000;
            padding:0;
            border:none;
            color:#fff;
            font-size:10px;
            font-weight:700;
            display:inline-flex;
            align-items:center;
            justify-content:center;
        }
    }
    .hamburger {
        padding: 15px 15px;
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        &:hover {
            opacity: 1;
        }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        background-color: #fff;
    }

    .hamburger-box {
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .hamburger-inner {
        display: block;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 35px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block;

    }
    .hamburger-inner::before {
        top: -10px;
    }
    .hamburger-inner::after {
        bottom: -10px;
    }

    /** Collapse **/
    .hamburger--collapse .hamburger-inner {
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .hamburger--collapse .hamburger-inner::after {
        top: -20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
    .hamburger--collapse .hamburger-inner::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .hamburger--collapse.is-active .hamburger-inner {
        transform: translate3d(0, -10px, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        width: 20px;
        transition:0.4s;
    }
    .hamburger--collapse.is-active .hamburger-inner::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
        width: 20px;
    }
    .hamburger--collapse.is-active .hamburger-inner::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        width: 20px;
    }
    .appScreen{
        background-image: url(${images});
        background-size:cover;
        height:100vh;
        overflow:auto;
        ul {
            list-style:none;
            @media(max-width:1024px){
                justify-content: center;
            }
            li{
                text-align:center;
                .btn {
                    width: auto;
                    background: none;
                    height: auto;
                    opacity: 1;
                }
            }
        }
        @media(max-width:1024px){
            height: calc(100vh - 130px);
            .row{
                flex-flow: column-reverse wrap;
                text-align:center;
            }
        }
    }
    .ColorPicker{
        height: 27px;
        width: 27px;
        margin: 10px;
        padding: 5px;
        border-radius: 50% !important;
    }
    .CustomColor{
        height: 27px;
        width: 27px;
        margin: 12px;
        padding: 5px;
        border-radius: 50% !important;
        color: var(--toro-black);
        background-color: #fff !important;
        border: 1px solid silver !important;
        &:hover {
            color: var(--toro-black) !important;
        }
    }
    .ColorPicker--btn {
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom-design input {
        box-shadow: none !important;
        width: 100% !important;
        margin: 0 !important;
    }
    .ColorPickerHead{
        h5 {
            color: #000 !important;
        };
        background-color: #fff !important;
    }
    .loader {
        display: inline-block;
        @media(max-width:1024px){
            & h5{
                padding:0 0 30px;
            }
        }
    }
    .rightsidewrap{
		width: var(--rightsidewrap-width) !important;
		height: calc(100vh - 120px) !important;
		@media(max-width: 1210px){
            display:none;
            ${'' /* height: calc(100vh - 128px) !important; */}
            height: 100% !important;
            width:100% !important;
            &.show{
                display:block;
            }
		}
		@media(max-width: 1366px) and (orientation: landscape) and (min-device-pixel-ratio: 2){ // max-device-width is deprecated
            display:none;
            ${'' /* height: calc(100vh - 128px) !important; */}
            height: 100% !important;
            width:100% !important;
            &.show{
                display:block;
            }
		}
        .rightsidebar-tut,.customer-information-tut  {
            position: absolute;
            height: calc(100% - 30px);
            width: var(--rightsidewrap-width) !important;
            z-index: -1;
        }
	}
    .middleSections{
        width: 104% !important;
        height: calc(100vh - 340px) !important;
        text-align: center;
        @media(max-width:3000px) and (min-width:2500px){
            height:calc(100vh - 540px) !important;
        }
        @media(max-width:1024px){
            width:100% !important;
            height:calc(100vh - 350px) !important;
        }
        @media only screen
        and (min-device-width : 576px)
        and (max-device-width : 1024px)
        and (orientation : landscape) {
            height: calc(100vh - 160px) !important;
        }
    }



    .searchfield{
        display: none !important;
        @media(max-width: 1600px){
            display: block !important;
            margin: 0 !important;
            width: 52px;
            line-height: 30px;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            &.open{
                button{
                    background:#333;
                    svg{
                        path{
                            fill:#fff;
                        }
                    }
                }
            }
            & button{
                min-width: auto !important;
                width: 100%;
                padding: 0 !important;
                svg{
                    transform: rotate(0) !important;
                }
            }
        }
        &__qb {
            @media (max-width: 1200px) {
                display: block !important;
            margin: 0 !important;
            width: 52px;
            line-height: 30px;
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            &.open{
                button{
                    background:#333;
                    svg{
                        path{
                            fill:#fff;
                        }
                    }
                }
            }
            & button{
                min-width: auto !important;
                width: 100%;
                padding: 0 !important;
                svg{
                    transform: rotate(0) !important;
                }
            }
            }
            @media (min-width: 1201px) {
                display: none !important;
            }
        }
    }
    .collapsewrap{
        padding: 7px 0;
        &.collapse:not(.show){
            display: inherit;
            @media(max-width: 1600px){
                display:none;
            }

        }
        &--qb {
            &.collapse:not(.show){
                @media(min-width: 1201px){
                    display: block;
                }
            }
        }
    }
    .TooltipButton {
        font-size: 10px !important;
        padding: 5px !important;
        font-weight: 700;
        margin-top: 10px !important;
    }
    .moveIcon{
        height: 20px ;
        width: 20px ;
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 2px;
    }
    .modal-open{
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
    }
    .openwrap ~ .ButtonsGroup ~ section .middleSections {
        height: calc(100vh - 510px) !important;
    }

    .form-control-file{
        width:auto;
    }
    .no-billing .infoIcon{
        position:relative;
        width: auto;
        display: inline-block;
        margin-left: 10px;
        #DisabledAutoHideExample{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }
    span.introjs-helperNumberLayer{
        display:none;
    }
    body .introjs-tooltip {
        border-radius: 10px;
        min-width: 375px;
        padding: 20px;
        &.initial-tut-budgeting {
            min-width: 700px;
        }
        .introjs-tooltiptext{
            color:#333333;
            text-align: left;
            h2{
                font-weight: 700;
                font-size: 15px;
            }
            p{
                font-size:12px;
                text-align: left;
                line-height:20px;
            }
        }

        .introjs-tooltipbuttons{
            display:flex;

            .introjs-disabled {
                display: none;
            }

            a.introjs-skipbutton{
                margin-right: auto;
            }
            .introjs-button{
                background: #333;
                text-shadow: none;
                color: #fff;
                border-radius:5px;
                font-size:10px;
                letter-spacing:0.17;
                border:none;
                margin-left:10px;
                line-height: 28px;
                padding: 0 20px;
                text-align: center;
            }
        }
    }
    .tracking-scroll{
        height: calc(100vh - 80px) !important;
        width:100% !important;
        @media(max-width:1024px){
            height: calc(100vh - 45px) !important;
        }
    }
    .tracking-event-legend{
        display: flex;
        justify-content: flex-end;
        margin: 12.5px;
        font-weight: bold;
        p{
            font-size: 7.5px;
            margin: 5px;
        }
    }
    .dd-col {
        .custom-design {
            & > div {
                ${typeScale.textSm}
            }
            .react-select__indicators {
                max-height: 35px;
            }
        }
    }
    .job-edit-form{
        width:100%;
        position:relative;
        .row {
            height: auto !important;
        }
        .grided {
            display: grid;
            grid-template-columns: repeat(2, 280px);
            grid-column-gap: 1.5rem;
            grid-row-gap: 0.5rem;
        }

        &__card {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: 20px 15px;
            margin-bottom: 20px;
            position: relative;
        }
        &__heading {
            font-size: 12px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 15px;
            color: #333;
            margin-bottom: 15px;
        }
        .text-decoration-underline {
            text-decoration: underline;
        }
        &__form-group {
            width: 280px;
            @media(${breakpoints.maxTablet}) {
                width: 100%;
            }

        }
        .multi-select-scroll {
            > div {
            &:first-child {
                padding-top: 20px;
                /* overflow-y: scroll; */
                > div:last-child {
                /* height: 35px;
                position: sticky;
                top: 0;
                right: 0; */
                }
            }
            }
        }
        .job-service-undercontract {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .CustomRadio{
            input{
                opacity:0;
                & ~ label ~ span {
                    width: 88px;
                    line-height: 24px;
                    color: #333333;
                    font-size: ${typeScale.textSm};
                    display: inline-block;
                    text-align: center;
                    background: #fcfcfc;
                    box-shadow: inset 0 0 1px 0 rgba(0,0,0,0.1);
                    border-radius: 5px;
                }
                &:checked ~ label  ~ span{
                    display: inline-block;
                    border-radius: 5px;
                    background: #333;
                    color: #fff;
                    -webkit-transform: translateX(0px);
                    -ms-transform: translateX(0px);
                    transform: translateX(0px);
                    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
                    -webkit-transition: all 150ms;
                    transition: all 150ms;
                    vertical-align: top;
                    position: relative;
                }
            }
        }
        .DayPickerInput{
            z-index: inherit;
        }
        .jobeditSubtitle{
            text-align: left;
            font-size: 14px;
        }
        .form-group {
            text-align: left;
        }
        & > .row > .col-md-4{
            &.heightOverflow{
                height: calc(100vh - 250px);
                p{
                    padding:0 13px;
                }
                @media(max-width:767px){
                    height: auto;
                    margin: 0 0 15px !important;
                    border-bottom: 1px solid #ccc !important;
                    padding-bottom: 15px !important;
                }
                &.less .form-group {
                    width: 92%;
                    padding-right: 5px;
                    display: inline-block;
                }
                h1.jobeditSubtitle {
                    padding-left: 10px;
                 }
                .mapSchedule{
                    & > .col {
                        padding: 0 15px !important;
                        & .row {
                            flex-direction: column;
                            .col{
                                padding:0 15px !important;
                                margin: 0 0 10px;
                            }
                        }
                    }
                }
            }
            &:not(:last-child){
                border-right: 1px solid #f1f1f1;
                @media(max-width:767px){
                    border:none;
                }
            }
            p{
                margin: 0;
            }
        }
        .jobrange button {
            width: 100%;
            background-color: #fcfcfc;
            color: #333333;
            border: none;
            box-shadow: inset 0 0 3px rgba(0,0,0,0.1) !important;
            padding: 10px 15px;
            font-size: 10px;
        }
        .subtotalWrap {
            background: #fcfcfc;
            text-align: center ;
            padding: 10px 15px;
            margin: 0;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            padding: 5px;
            label{
                color: #333;
                font-size: 12px;
            }
        }
        &.route-edit-form {
            text-align: left !important;
            .route-edit-form__info {
                font-size: 12px;
                line-height: 2.08;
                .card-header {
                    background: #000;
                    color: #fff;
                }
                @media(${breakpoints.maxTablet}) {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 20px;
                }
            }
            .route-edit-form__fields {
                @media(${breakpoints.maxTablet}) {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

        }
    }
    .route-edit-table {
        text-align: left !important;
        margin-bottom: 0;
        .table-header {
            font-size: 12px;
            color: #999;
        }
        .table-content {
            font-size: 12px;
        }
    }

    .rangeslider{
        box-shadow: 0 1px 3px inset rgba(0,0,0,0.1) !important;
        background: #fcfcfc !important;
        .rangeslider-horizontal .rangeslider__handle:after{
            background-color: #666 !important;
        }
        .rangeslider__handle{
            box-shadow: none;
            outline: none;
        }
    }
    body .tooltip {
        &.show{
            opacity:1;
        }
        .tooltip-inner{
            box-shadow:0 0 6px rgba(0,0,0,0.11);
            background:#fff;
            border-radius:5px;
            color:#333;
            padding: 10px;
        }
        &[data-popper-placement='right'] {
            .tooltip-arrow {
                &::before {
                    border-right-color: #fff !important;
                    
                }
            }
        }
        &[data-popper-placement='top'] {
            .tooltip-arrow {
                &::before {
                    border-top-color: #fff !important;
                    
                }
            }
        }
        &[data-popper-placement='left'] {
            .tooltip-arrow {
                &::before {
                    border-left-color: #fff !important;
                    
                }
            }
        }
        &[data-popper-placement='bottom'] {
            .tooltip-arrow {
                &::before {
                    border-bottom-color: #fff !important;
                    
                }
            }
        }
        &--lg{
            .tooltip-inner{max-width: 500px;}
        }
    }
    .bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before{
        border-left-color:#ccc;
    }
    .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{
        border-top-color:#ccc;
    }
    .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before{
        border-bottom-color:#ccc;
    }
    .bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before{
        border-right-color:#ccc;
    }
    .DayPickerInput-Overlay{
        padding: 20px;
        border-radius: 5px;
    }
    body .introjs-bullets ul {
        display:flex;
        justify-content:center;
        align-items:center;
            li a{
                width:8px;
                height:8px;
                background:#ccc;
            &.active{
                width:10px;
                height:10px;
                background:#333;
            }
        }
    }
    #trackingpopupWrapper{
        margin: auto;
        background: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        z-index:99;
        padding:20px;
        position:absolute;
        &:after{
            display:none;
        }
    }

    #indicator-container{
        background-color: #333;
        color: #fff;
        padding: 0 5px;
        border-radius: 50%;
        margin: 0;
        display: flex;
        line-height: 26px;
        font-size:10px;
        font-weight:500;
    }
    .invoiceNotes{
        text-align: right;
        margin-bottom:30px;
        p{
            font-size:10px;
            color:#cccccc;
        }
        div{
            background: #666666;
            border-radius: 5px;
            color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            padding: 9px 15px;
        }
    }
    .UsedColor{
        height: 11px;
        width: 11px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
    }

    .optimseRoute{
        width:95% !important;
        max-width:1500px !important;
        &.modal-dialog .modal-header{
            background: none;
            border:none;
            .close{
                display:block;
            }
        }
        p{
            font-size: 14px;
            font-weight: 700;
            text-align: center;
        }
        .optimisiBtn{
            padding-top: 50px;
            position: absolute;
            bottom: 20px;
            button{
                margin:0;
                min-width: 120px;
                font-weight: 700;
                font-size: 10px;
            }
            .right{
                border-left: 1px solid #ccc;
                padding: 20px 39px;
                margin-left: 39px;
                display:flex;
                p{
                    line-height: initial;
                    margin-left: 5px;
                    font-size: 14px;
                }
            }
        }
    }
    .event-map-modal {
        width: 600px !important;
        height: 545px !important;
        .modal-header {
            background: transparent !important;
            .close {
                display: block !important;
            }
        }
        .modal-body {
            padding: 0px;
        }
    }
    .modal-dialog.estimateModal {
        max-width: 100%;
        width: 100%;
        @media (max-device-width: 1366px) and (min-device-width: 1211px) and (orientation: landscape) {
            overflow: hidden;
        }
        /* overflow: hidden; */
        .card-body {
            @media (max-width: 1366px) {
                ${'' /* overflow: auto; */}
            }

        }
        .modal-content{
            height: 100%;
            border-radius:0px;
            @media (max-width: 1366px) {
                overflow: auto;
            }
            ${
              '' /* @media (max-device-width: 1366px) and (min-device-width: 1211px) and (orientation: landscape) {
                overflow: hidden;
            } */
            }
            .modal-header{
                display: none;
            }
            .Table__pagination{
                background:none;
            }
            .geosuggest{
                .geosuggest__item{
                    text-align:left;
                }
            }
            .modal-body{
                padding:0;
                .leftsideEstimate{
                    overflow: hidden;
                    padding:0;
                    border-right: 1px solid #f1f1f1;
                    .p-0{
                        height:100%;
                    }
                    .mb-3 {
                        margin: 0 auto 0 0 !important;
                        padding: 35px;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-align: left;
                        width: 100%;
                        .backoverview{
                            margin-top:18px;
                            border-radius: 5px;
                            box-shadow: 0 0 5px 0 rgba(0,0,0,0.15) !important;
                            background-color: #fcfcfc;
                            height: 30px;
                            font-size: 10px;
                            font-weight: 700;
                            border: none;
                            display: flex;
                            align-items: center;
                            width: 149px;
                            justify-content: space-evenly;
                        }
                    }
                    h5{
                        height: 100%;
                    }
                }
                .done{
                    padding:0;
                }
                .estimateMap{
                    height:calc(100vh - 201px);
                }
                .boundary-with-cross {
                    position: absolute;
                    right: 80px;
                }
                .polygon-no-cross {
                    position: absolute;
                    right: 23px;
                }
                .polygon-with-cross {
                    position: absolute;
                    right: 60px;
                }
                .rightsidewrap{
                    height:calc(100vh - 111px) !important;
                    @media (max-width: 1365px) {
                        margin-top: 30px;
                    }
                    @media (max-width: 1210px) {
                        height: 100% !important;
                        background: ${theme.pageBackgroundWhite}
                    }
                    @media(max-device-width: 1365px) and (orientation: landscape) {
                        height: 100% !important;
                        background: ${theme.pageBackgroundWhite}
                    }
                    @media (min-device-width: 1025px) and (max-device-width: 1365px) {
                        margin-top: 30px;
                    }
                }
                ${RightsideBarWrap}{
                    height:100%;
                    @media (max-width: 1024px) {
                        padding-left: 40px;
                        padding-right: 40px;
                        padding-bottom: 60px;
                        background: #fff !important;
                        height: auto !important;
                    }
                    .card{
                        text-align: left;
                        margin:0;
                        .boundary-summary {
                            box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
                            ${typeScale.textMd};
                            padding: 10px;
                            color: #333;
                            margin-bottom: 15px;
                            border-radius: 5px;
                            p {
                                color: #333;
                                padding-bottom: 3px;
                                &:last-child {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .location-image-info {
                    position: absolute;
                    left: 105px;
                    padding: 0 25px;
                }
            }
        }
    }
    .full-size-modal {
        padding: 10px;
        max-width: 100% !important;
        width: 100% !important;
        .mini-spinner {
            display: flex;
            justify-content: center;
        }
        .modal-content {
            min-height: 100%;
        }
        .modal-header {
            background: #FFF !important;
            border-bottom: 0;

            .modal-title {
                color: ${theme.textPrimary} !important;
                font-size: ${typeScale.header1};
                font-weight: normal !important;
            }
            .close {
                display: block !important;
            }
        }
    }
    .view-pdf-modal {
        .modal-body {
            padding: 45px;
            @media (max-width: 1366px) {
                padding-top: 25px;
                padding: 25px 0;
            }
        }
    }
    .add-routes-modal {
        .modal-content {
            height: 100%;
            overflow: hidden;
        }
        .modal-body {
            height: calc(100% - 56px);
        }
    }
    .estimateMap{
        height: calc(100vh - 200px);
        width:100%;
    }

    .enlargeImage{
        width:100% !important;
        max-width:650px !important;
        .modal-header{
            display:none;

        }
    }
    .optimseRoute{
        .modal-body{
            padding:0;
        }
        .leftSide{
            padding:30px 45px;
            h4{
                font-size:18px;
                color:#333333;
                margin-right:45px;
                margin-bottom:0;
            }
            .selectroute{
                flex:0.8;
            }
            h1{
                font-size:10px;
            }
            .geosuggest{
                input{
                    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
                    background-color: #fcfcfc;
                    padding:8px 10px;
                    font-size: 10px;
                    color:#333333;
                    border-radius: 5px;
                    width:100%;
                    border:none;
                }
            }
            .table{
                th{
                    border:none;
                    font-size: 10px;
                    color: #999999;
                    font-weight:400;
                }
                td{
                    color:#333333;
                    font-weight:400;
                    border: none;
                    font-size: 10px;
                }
                tbody{
                    tr{
                        border-radius: 5px;
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
                    }
                }
            }
        }
        .map{
            display: inline-flex;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }
    .ReactTable .rt-resizer{
        display:none !important;
    }
    .ReactTable .rt-resizer{
        display:none !important;
    }
    .ReactTable .rt-noData {
        text-align: center !important;
    }
    .gm-style-mtc > div {
        width: 100px;
    }
    .instance-label{
        text-decoration: underline;
        font-weight: 600;
    }
    .optimise-route{
        height: calc(100vh - 140px) !important;
        margin: 10px !important;
    }
    .btn.disabled, .btn:disabled{
        opacity: 1 !important;
        cursor: not-allowed;
    }
    .list-item-heading {
        width: 180px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        line-height: 1.2em;
        text-overflow: ellipsis;
        display: inline-block;
        margin: 0 0 2px;
        padding: 0;
        overflow: hidden;
    }
    .tab-pane .list-group {
        padding-top: 5px;

    }

}
    .pending-schedule {
        .CustomRadio {
            padding: 30px 15px 0 15px;
        }

    }
    .gm-style{
        button{
            margin-top:10px;
        }
        .allcustomerButton{
            button{
                background: #fff !important;
                color: #333 !important;
                display: flex;
                align-items: center;
                padding: 10px;
                margin-right: 10px;
                img{
                    margin:0 5px;
                }
            }
        }
    }
    .analyticsScroll{
        @media(max-width:1024px){
            height: calc(100vh - 150px) !important;
        }
        .alignLoader{
            top:70% !important;
        }
    }
    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        .analyticsScroll .buttonWrap {
            padding-bottom: 150px !important;
        }
    }
    .calendar-main .fc-unselectable{
        li {
            padding: 0 !important;
            .pendingJobs{
                height: 100%;
            }
            svg{
                margin: 0 9px 0 12px;
                width: 1.3vw;
            }
            .jobsName{
                p{
                    margin-bottom:0;
                }
            }
        }
    }
    .hidden {
        position: absolute !important;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
    }
    .mapCalendarScroll {
        height: 100% !important;
        & > div:first-child {
            overflow: auto !important;
        }
        @media (max-width: 1024px) {
            height: auto !important;
            & > div:first-child{
                position:static !important;
            }
            .calendarheader{
                padding:0 !important;
                h2{
                    margin:0;
                    padding-top:15px;
                }
            }
        }
    }
    .search-container {
        &--jobs {
            .form-group.sort-filters {
                display: flex;
            }
        }
    }
    .company-container, .admin-container {
        display: flex;
        flex-direction: column;
        .search-container {
            order: 2;
        }
        .company-heading-container {
            order: 1
        }
        .company-list-container {
            order: 3;
        }
        .pagination-wrapper {
            order: 4;
        }
        @media(max-width:1024px){
            padding-top: 10px;
        }
    }

    .label-row-s-between {
        display: flex;
        justify-content: space-between;
        button.btn.rounded-cross {
            position: static !important;
            margin: 0 !important;
        }
        .text-italics {
            font-size: 8px;
        }
    }
    .text-italics {
        font-style: italic;
    }
    .legend-wrapper {
        .legend-value {
            margin-left: auto;
        }
        .legend-label {
            width: 70%;
            text-overflow: ellipsis;
            text-align: left;
            white-space: nowrap;
            padding-right: 5px;
            &--wrapper {
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;

            }
            @media (max-width: 1399px) {
                width: 62%;
            }
            @media (max-width: 1300px) {
                width: 54%;
            }
        }
    }
    .overflow-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block !important;
        &--block{
            display:block !important
        }
    }


    .quickbooksWrapperList {
        .page-item {
            .page-link {
                color:#333;
            }
            &.active {
                .page-link {
                    background: #cc0000;
                    border-color: #cc0000;
                    color: #fff;
                }
            }
        }
        &__wrapper {
            width: 100%;
            @media (min-width: 1200px) {
                width: auto;
            }
        }
    }
    .buy-device-btn {
        @media (min-width: 1024px) {
            margin-left: auto;
            margin-right: 20px;
        }
    }
    .page-link {
        &:focus {
            box-shadow: none !important;
        }
    }
    .width-auto {
        width: auto !important;
        max-width: 100% !important;
    }
    .invoice-table-approve-btn {
        width: 5% !important;
        max-width: 5% !important;
        flex: 30 0 auto !important;
        .customCheckbox {
            margin-right: 0 !important;
        }
    }
    .invoice-table-contract {
        width: 12% !important;
        max-width: 12% !important;
        white-space: nowrap;
        flex: auto !important;
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            /* width: 100%; */
        }
    }
    .invoice-table-d-none {
        width: 0 !important;
        display: none;
        flex: auto !important;
    }
    .invoice-table-address {
        padding-right: 60%;
        white-space: nowrap;
        width: 10% !important;
        max-width: 10% !important;
        flex: auto !important;
    }
    .invoice-table-frequency {
        width: 7% !important;
        max-width: 7% !important;
        flex: auto !important;
    }
    .invoice-table-contract-price {
        width: 9% !important;
        max-width: 9%;
        flex: auto !important;
    }
    .invoice-table-addon-price {
        width: 9% !important;
        max-width: 9%;
        flex: auto !important;
    }
    .invoice-table-total {
        width: 7% !important;
        max-width: 7%;
        flex: auto !important;
    }
    .invoice-table-date {
        width: 12% !important;
        max-width: 12%;
        flex: auto !important;
    }
    .invoice-table-action-btns {
        max-width: 25% !important;
        width: 25 !important;
        flex: auto !important;
    }
    .invoice-approve-popup {
        .modal-body {
            @media (min-width: 1024px) {
              padding: 40px 90px;
            }
        }
        @media (min-width: 1024px) {
          width: 748px !important;
        }
      }
      .no-modal-header .modal-header {
        display: none;
      }
      .modal-invoices-qb {
        &__header {
          font-size: 26px;
          line-height: 1.38;
          text-align: center;
          color: #333;
          @media (min-width: 1024px){
              margin-bottom: 10px;
          }
        }
        &__subheader {
          font-size: 16px;
          line-height: 1.38;
          color: #333;
          font-weight: 700;
          @media (min-width: 1024px){
            margin-bottom: 20px;
        }
        }
        &__passed {
          color: #5db85c;
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 4px;
            content: url(${passedIcon});
          }
        }
        &__failed-without-cross{
            color: #cc0000;
          position: relative;
        }
        &__failed {
          color: #cc0000;
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 4px;
            content: url(${failedIcon});
          }
        }
        &__job-issues {
          list-style: none;
          display: inline-block;
          padding: 0;
          @media (min-width: 1024px) {
              margin-bottom: 30px;
          }
          li {
                padding-left: 40px;
                @media (min-width: 1024px) {
                    margin-bottom: 25px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
          }
        }
        a {
            display: inline-block;
        }
        &__button {
            button {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
                background: #333 !important;
                color: #fff;
                font-weight: 700;
                padding: 8px 6px;
                border-radius: 5px;
                text-decoration: none;
                &:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }

        }
        &__anchor {
            text-decoration: underline;
            color: #666;
            margin-top: 15px;
            &:hover {
                color: #000;
            }
        }
        &__success-icon {
            @media (min-width: 1024px) {
                margin: 60px 0;
            }
            img {
                height: 100%;
                width: 80px;
            }
        }
        &__fontSize10 {
            font-size : 10px;
        }
        &__closeButton {
            font-size : 12px;
            cursor : pointer;
            color : #666666
        }
    }
    .switch {
        transform: translate3d(0,0,0);
        &--styled {
            & > div {
                transform: scale(0.7);
            }
        }
    }
    .model-instances {
        font-size: 10px;
        padding-left: 15px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            min-width: 5px;
            left: 0;
            top: 50%;
            background: #333;
            transform: translateY(-50%);
        }
        &__nickname {
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .tutorial-icon-container {
        position: fixed;
        z-index: 99;
        bottom: 10px;
        left: 10px;
        background: #fff;
        border-radius: 50%;
        width:45px;
        height: 45px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media (min-width: 1024px) {
            left: calc(20px + var(--leftsidebar-width));
        }
        svg path{
            stroke:#333;
        }
    }
    .tutorial-icon-container.introjs-fixParent {
        position: fixed !important;
    }
    .tracking-tut-wrapper .tutorial-icon-container {
        position: absolute;
        left: 12px;
        bottom: 32px;
    }
    .sort-icon {
        min-width: 6px;
    }
    .schedule-popover {
        max-height: 100% !important;
    }
    .sidebar-quickbooks {
        transition: all 0.3s ease-in;
        margin-bottom: 10px !important;
        svg {
            margin-bottom: 0 !important;
        }
        a {
            width: 70px;
            height: 70px;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border-left-color: transparent !important;
            margin: 0 auto;
            border-left: 0 !important;
        }
        a:hover {
            border-left-color: transparent;
            background: transparent;
            box-shadow: none;
            background: #333;
        }
	}

    .page-header {
        margin-right: 25px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    .left-sidebar-container {
        height: 100%;
        position: relative;
		background: #3f3f3f;
        &--fixed {
            @media (min-width: 1024px) {
                position: fixed;
                top: 84px;
                bottom: 0;
                height: auto; // this fixes an issue with android where where this element is displaced upwards wafter the soft keyboard is opened and then closed. has to work with android + landscape only
            }
        }
        @media (min-width: 1024px) {
            max-width: var(--leftsidebar-width);
        }
    }
    .left-sidebar-wrapper {
        /* &--operator {
            height: calc(100vh - 78px)
        }
        &--admin {
            height: calc(100vh - 120px)
        } */
        height: 100%;
        @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
        }
    }
    .page-tut {
        position: absolute;
        width: 100vw;
        z-index: -1;
        height: calc(100vh - 125px);
        @media (max-width: 1366px) {
            height: 500px;
            height: calc(100vh - 225px);
        }
        @media (min-width: 1024px) {
            width: calc(100% - var(--leftsidebar-width));
        }
    }
    .trackingMap .introjs-fixParent {
        position: absolute !important;
}
    .calendar-right-tut {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        height: calc(100vh - 120px);
        width: 100%;
        z-index: -1;
    }

    .analytics-tracking-tut, .analytics-financial-tut, .analytics-productivity-tut, .budgeting-expense-tut, .budgeting-overhead-tut, .budgeting-workHours-tut {
        position: absolute;
        width: 100%;
        height: 160px;
        z-index: -1;
    .black-btn {
        background: #333 !important;
    }
    .schedule-today-btn {
        margin-right: 25px;
    }
    .notes-timestamp {
        font-size: 12px
    }
`;
