import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  token: '',
  isAdmin: null,
  companyId: null,
  userId: null,
  firstName: '',
  avatarUrl: '',
  companyName: '',
  StartWorkHour: '09:00:AM',
  EndWorkHour: '05:00:PM',
  Holidays: true,
  Weekends: true,
  StartOfWeek: 'Sunday',
  EmailAddress: '',
  Timezone: null,
  showAppTour: false,
  IsCompanyVerified: null,
  SubscriptionStatusId: null,
  subscriptionDetails: null,
  tutorialSection: null,
  companyAddressCoordinates: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        ...action,
        token: action.token,
        isAuthenticated: action.isAuthenticated,
        isAdmin: action.isAdmin,
        loggedAsAdmin: action.loggedAsAdmin,
        companyId: action.companyId,
        userId: action.userId,
        firstName: action.firstName,
        avatarUrl: action.avatarUrl,
        companyName: action.companyName,
        StartWorkHour: action.StartWorkHour,
        EndWorkHour: action.EndWorkHour,
        Holidays: action.Holidays,
        Weekends: action.Weekends,
        StartOfWeek: action.StartOfWeek,
        EmailAddress: action.EmailAddress,
        Timezone: action.Timezone,
        showAppTour: action.showAppTour,
        tutorialSection: action.tutorialSection,
        IsCompanyVerified: action.IsCompanyVerified || 0,
        SubscriptionStatusId: action.SubscriptionStatusId || 1,
        HasCompanyDetails: action.HasCompanyDetail,
        subscriptionDetails: action.subscriptionDetails,
        companyAddressCoordinates: action.companyAddressCoordinates,
        accessibleSections: action.accessibleSections,
        numberOfCreditCards: action.numberOfCreditCards,
        DisplayName: action.DisplayName,
        Sender: action.Sender
      };
    case actionTypes.NAME_CHANGE:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        avatarUrl: action.avatarUrl
      };

    case actionTypes.ADMIN_AS_OPERATOR:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.CHANGE_TIME_ZONE:
      return {
        ...state,
        Timezone: action.Timezone
      };
    case actionTypes.OPERATOR_AS_ADMIN:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.APP_TOUR_STATUS:
      return {
        ...state,
        showAppTour: action.showAppTour
      };
    case actionTypes.MANAGE_WORK_HOURS:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.UPDATE_TUTORIAL_STATE:
      return {
        ...state,
        ...action.tutorialSection
      };
    case actionTypes.COMPANY_VERIFIED:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.COMPANY_DETAILS_ADDED:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.CHANGE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        SubscriptionStatusId: action.SubscriptionStatusId
      };
    case actionTypes.SUBSCRIBED_SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.subscriptionDetails
      };
    case actionTypes.UPDATE_COMPANY_COORDINATES:
      return {
        ...state,
        companyAddressCoordinates: { ...action.companyAddressCoordinates }
      };
    case actionTypes.REFRESH_DETAILS:
      return {
        ...state,
        ...action.data
      };
    case actionTypes.UPDATE_SCHEDULE_CALENDAR_DATE_RANGE:
      return {
        ...state,
        calendarStartDate: action.calendarStartDate,
        calendarEndDate: action.calendarEndDate
      };
    default:
      return state;
  }
};
