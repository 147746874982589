/**
 *
 * @param {String} timestring  timestring '12:20 PM'
 * @description Function takes a time string and returns and object with hours and minutes
 * @returns {Object}
 */
export default function getTimeObject(timestring) {
  const [hourMinuteString, period] = timestring.split(' ');
  let [hours, minutes] = hourMinuteString.split(':');
  if (Number(hours) === 12) {
    hours = 0;
  }
  if (period === 'PM' || period === 'pm') {
    hours = Number(hours) + 12;
  }
  return { hours: Number(hours), minutes: Number(minutes) };
}
