import { ButtonAsLink } from 'css/components/Modules/Buttons';
import { StyledCheckbox } from 'css/components/Modules/Inputs';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, typeScale } from 'css/utils';

const PrivacyPolicyCheckboxWrapper = styled.div`
  color: ${theme.textFaded};
  ${typeScale.textMd};
`;

export default function PrivacyPolicyCheckbox({ onStatusChangeHandler, checkboxDescription }) {
  const onChange = e => {
    onStatusChangeHandler && onStatusChangeHandler(e.target.checked);
  };
  return (
    <PrivacyPolicyCheckboxWrapper className='mt-3 mb-4 d-flex align-items-center'>
      <StyledCheckbox>
        <input
          className='styled-checkbox'
          id='styled-checkbox-1'
          type='checkbox'
          onChange={onChange}
        />
        <label htmlFor='styled-checkbox-1'></label>
      </StyledCheckbox>
      <span className='text-start'>
        By checking this box, you agree to our&nbsp;
        <ButtonAsLink
          type='button'
          modifiers={['faded', 'bold']}
          onClick={() => window.open('https://www.toro.com/en/legal/privacy-policy', '_blank')}
        >
          Privacy Policy
        </ButtonAsLink>{' '}
        and{' '}
        <ButtonAsLink
          type='button'
          modifiers={['faded', 'bold']}
          onClick={() =>
            window.open('https://www.toro.com/en/legal/Horizon360-SaaS-Agreement', '_blank')
          }
        >
          Terms of Service
        </ButtonAsLink>
        <>{`${checkboxDescription ? checkboxDescription : '.'}`}</>
      </span>
    </PrivacyPolicyCheckboxWrapper>
  );
}

PrivacyPolicyCheckbox.propTypes = {
  checkboxDescription: PropTypes.string,
  onStatusChangeHandler: PropTypes.func.isRequired
};
