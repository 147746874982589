import { createGlobalStyle } from 'styled-components';
import { theme, typeScale } from '../../utils';

import VerdanaBoldItalic from '../../../Fonts/Verdana-BoldItalic.woff';
import VerdanaBold from '../../../Fonts/Verdana-Bold.woff';
import Verdana from '../../../Fonts/Verdana.woff';

const Typography = createGlobalStyle`
@font-face {
    font-family: 'Verdana';
    src: url(${VerdanaBoldItalic});
    font-weight: bold;
    font-style: italic;
    font-display: fallback
  }
@font-face {
    font-family: 'Verdana';
    src: url(${VerdanaBold});
    font-weight: bold;
    font-style: normal;
    font-display: fallback
  }
  @font-face {
    font-family: 'Verdana';
    src: url(${Verdana});
    font-weight: normal;
    font-style: normal;
    font-display: fallback
  }

  html {
    font-family: Verdana, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* color: ${theme.text};
    letter-spacing: var(--letter-spacing); */
  }

  html, body, h1, h2, h3, h4, h5, a, li, p, .popover, pre{
        font-family: 'Verdana' !important
    }

  /* a {
    color: var(--black);
    text-decoration-color: var(--red);
    // Chrome renders this weird with this font, so we turn it off
    text-decoration-skip-ink: none;
  } */

  .center {
    text-align: center;
  }
  .text-faded {
        color: ${theme.textFaded}
    }
    .text-decoration-underline {
        text-decoration:underline !important;
    }
  .text-header1 {
      ${typeScale.header1}
  }
  .text-header2 {
      ${typeScale.header2}
  }
  .text-lg {
      ${typeScale.textLg}
  }
  .text-md {
      ${typeScale.textMd}
  }
  .text-sm {
      ${typeScale.textSm}
  }
  .text-xs {
      ${typeScale.textXs}
  }
  .text-grey {
    color: ${theme.grey666666}
  }
`;

export default Typography;
