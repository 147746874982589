import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { useTimeMangementState } from '../TimeMangementStateProvider';
import { SET_EVENT_TYPE } from '../actionTypes';
import { BREAK_START, LUNCH_START } from '../../../constants';
import { AuthHeader, GreySubHeading } from 'css/components/Modules/Headings';
import { PrimaryButton } from 'css/components/Modules/Buttons';
import { CheckboxTicksWrapper } from 'css/components/Modules/Inputs';

export default function SelectBreakType() {
  const [breakType, setBreakType] = useState(LUNCH_START);
  const [, dispatch] = useTimeMangementState();
  return (
    <div className='event-type-selection'>
      <div className='mb-4'>
        <AuthHeader>Choose Break Type</AuthHeader>
      </div>

      <div className='ps-3 pe-3 employee-block-mb'>
        <div className='mb-2'>
          <GreySubHeading>BREAK TYPE</GreySubHeading>
        </div>
        <CheckboxTicksWrapper>
          <div className='d-flex align-items-center ending-options__radio customCheckbox mb-2'>
            <input
              type='radio'
              checked={breakType === LUNCH_START}
              name={LUNCH_START}
              onChange={() => setBreakType(LUNCH_START)}
              className='me-2'
              id={LUNCH_START}
            />
            <span />
            <Label htmlFor={LUNCH_START} className='mb-0'>
              Lunch
            </Label>
          </div>
          <div className='d-flex align-items-center ending-options__radio customCheckbox'>
            <input
              type='radio'
              checked={breakType === BREAK_START}
              name={BREAK_START}
              onChange={() => setBreakType(BREAK_START)}
              className='me-2'
              id={BREAK_START}
            />
            <span />
            <Label htmlFor={BREAK_START} className='mb-0'>
              Break
            </Label>
          </div>
        </CheckboxTicksWrapper>
      </div>
      <div className='text-center event-type-selection__button'>
        <PrimaryButton
          onClick={() => {
            dispatch({ type: SET_EVENT_TYPE, data: { eventType: breakType } });
          }}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  );
}
