import React from 'react';
import { FormText, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { getIn, ErrorMessage } from 'formik';
import './input.css';

const InputField = ({
  field,
  form: { touched, errors },
  label,
  type,
  placeholder,
  min,
  max,
  disable,
  valid,
  step,
  id,
  autoFocus,
  suffix,
  prefix,
  subText,
  ...props
}) => {
  const InputWrapper = suffix || prefix ? InputGroup : React.Fragment;

  return (
    <>
      {label ? (
        <Label htmlFor={id || field.name} className='pe-2'>
          {label}
        </Label>
      ) : null}

      <InputWrapper>
        {prefix ? <InputGroupText>{prefix}</InputGroupText> : null}

        <Input
          id={id || field.name}
          step={step}
          disabled={disable}
          bsSize='sm'
          type={type}
          placeholder={placeholder}
          min={min}
          max={max}
          {...field}
          {...props}
          valid={touched[field.name] && !errors[field.name] ? valid : null}
          invalid={
            (touched[field.name] && !!errors[field.name]) ||
            (getIn(errors, field.name) && getIn(touched, field.name))
          }
          onWheelCapture={e => {
            e.target.blur();
          }}
          onBlur={() => {
            props.onBlur && props.onBlur(field.value, errors[field.name]); // Callback functions that accept only the value of the input
          }}
          autoComplete='off'
          className={props.className}
          autoFocus={autoFocus}
        />

        {suffix ? <InputGroupText>{suffix}</InputGroupText> : null}
      </InputWrapper>

      {(touched[field.name] && errors[field.name] && (
        <ErrorMessage component='div' name={field.name} className='input-feedback' />
      )) ||
        (getIn(errors, field.name) && getIn(touched, field.name) && (
          <ErrorMessage component='div' name={field.name} className='input-feedback' />
        ))}

      {Boolean(subText) && <FormText className='text-sm'>{subText}</FormText>}
    </>
  );
};

export default InputField;
