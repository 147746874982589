import APIUtil from 'api/apiUtil';
const api = new APIUtil();
export const TimeMangementAPI = {
  employeeLogin(data) {
    return api.post('company/employeeLogin', data);
  },
  logEvent(companyId, employeeId, data) {
    return api.post(`company/${companyId}/employee/${employeeId}/employeeTimesheet`, data);
  },
  submitTimesheet(companyId, employeeId, timesheetId, data) {
    return api.post(`company/${companyId}/employee/${employeeId}/timesheet/${timesheetId}`, data);
  }
};
