import * as Yup from 'yup';
const phoneRegexWithParenthesis = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; //(234) 132-4123
const phoneRegex = /^[0-9]{10}$/;
export const phoneValidator = (excludeEmptyString = false, msg = null) => {
  return Yup.string().matches(phoneRegexWithParenthesis, {
    message: msg ? msg : 'Please provide a valid Phone/Mobile Number.',
    excludeEmptyString
  });
};

export const zipValidator = () => {
  return Yup.string()
    .matches(/^([0-9]{5})(-[0-9]{4})?$/i, {
      message: 'Please provide a valid Zip Code.',
      excludeEmptyString: false
    })
    .min(5, 'Please Provide a valid zipcode')
    .max(5, 'Please Provide a valid zipcode');
};

export const nameValidator = () => {
  return Yup.string()
    .trim()
    .matches(/^[^\s].*[^\s]$/, 'The field name must be between 2 and 30 characters');
};
/**
 * @description THIS IS NOT TO BE USED FOR YUP VALIDATIONS
 * @param {email} value
 * @returns {Boolean}
 */
export const emailValidator = value => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

export const mobileValidator = value => {
  return phoneRegex.test(value);
};

export const eitherPhoneOrEmailValidator = value => {
  let isValidEmail = emailValidator(value);
  let isValidPhone = mobileValidator(value);
  if (!isValidEmail && !isValidPhone) {
    return false;
  }
  return true;
};
