import styled from 'styled-components';
import { theme, typeScale } from '../../utils';
import { applyStyleModifiers } from 'styled-components-modifiers';

const BUTTON_MODIFIERS = {
  // i am loath to create this particular modifier, but such buttons have been used enough times to warrant a modifier imo
  minWidth120: () => `
    min-width: 120px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  `,
  minWidth160: () => `
    min-width: 160px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  `,
  large: () => `
  ${typeScale.textLg};
  padding: 12px 50px !important;
  `,
  medium: () => `
    ${typeScale.textMd};
    padding: 10px 45px !important;
  `,
  small: () => `
    ${typeScale.textSm};
    padding: 8px 20px !important;
  `,
  extraSmall: () => `
    ${typeScale.textSm};
    padding: 8px 15px !important;
    min-width: 60px;
  `,
  faded: () => `
    color: ${theme.textFaded};
    &:hover {
      color: ${theme.textFaded};
    }
    &:focus {
      color: ${theme.textPrimary}
    }
  `,
  bold: () => `
    font-weight: bold;
  `,
  fullWidth: () => `
    width: 100%;
  `,
  success: () => `
    background-color: ${theme.success} !important;
    color: ${theme.textInverted} !important;
    &:hover {
      color: ${theme.textInverted};
    }
  `,
  backButton: () => `
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15) !important;
    color: ${theme.textPrimary};
    ${typeScale.textSm};
    position: relative;
    z-index: 999;
    min-width: 80px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px !important;
    @media (max-width: 1024px) {
      font-size: 8px;
      z-index: 0;
    }
    svg {
      margin-right: 6px;
    }
    &:hover {
      path {
        stroke: ${theme.textInverted};
        fill: ${theme.textInverted};
      }
    }
  `,
  fadedText: () => `
    color: ${theme.textFaded};
    &:hover {
      color: ${theme.textFaded};
    }

  `,
  removeButton: () => `
    background: ${theme.neutralPrimary} !important;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${theme.primary};
    }
  `,
  // use guest modifier to disable tradition buttons
  guest: () => `
    pointer-events: none !important;
    background: ${theme.disabled} !important;
    color: ${theme.textInverted} !important;
    `,
  // use guestIcon to disable buttons that serve as icons
  guestIcon: () => `
    pointer-events: none !important;
    opacity: 0.5 !important
    `,
  guestHide: () => `
    display: none;
  `,
  guest_qa: () => `
    pointer-events: none !important;
    background: ${theme.disabled} !important;
    color: ${theme.textInverted} !important;
    `,
  // use guestIcon to disable buttons that serve as icons
  guest_qaIcon: () => `
    pointer-events: none !important;
    opacity: 0.5 !important
    `,
  guest_qaHide: () => `
    display: none;
  `,
  guest_dev: () => `
    pointer-events: none !important;
    background: ${theme.disabled} !important;
    color: ${theme.textInverted} !important;
    `,
  // use guestIcon to disable buttons that serve as icons
  guest_devIcon: () => `
    pointer-events: none !important;
    opacity: 0.5 !important
    `,
  guest_devHide: () => `
    display: none;
  `,
  primaryFocused: () => `
    background: #fff !important;
    color: ${theme.textPrimary} !important;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.15) !important;
  `,
  trimmedGhost: () => `
    font-size: 10px !important;
    line-height: 40px !important;
    padding: 0 !important;
    width:100% !important;
  `,
  blackCross: () => `
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.textPrimary} !important;
    border-radius: 50%;
    width: 20px;
    min-width: 20px !important;
    height: 20px;
  `,
  noUnderline: () => `
    text-decoration: none;
  `,
  highlightOnHover: () => `
    position: relative;
    border-left: 1px solid #f9f9f9;
    height: 45px;
    width: 45px;
    text-align: center;
    padding: 0 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${theme.hoveredBackground} !important;
    }
  `,
  errorThrown: () => `
    opacity: var(--disabled-opacity);
  `,
  contentWidth: () => `
    padding: 10px 15px !important;
    width: max-content !important;
  `,
  noHoverGhost: () => `
    &:hover {
      background: ${theme.buttonText} !important;
      color: ${theme.textPrimary} !important;
    }`
};
export const Button = styled.button`
  padding: 10px 40px !important;
  border: 0;
  border-radius: 5px;
  letter-spacing: 0.2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
  ${typeScale.textMd};
  font-weight: bold;
  color: ${theme.buttonText};
  transition: all 0.2s var(--background-bezier);
  line-height: 1.5 !important;
  &:hover {
    color: ${theme.textInverted};
    box-shadow: 3px 8px 5px 0 rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    background: ${theme.disabled} !important;
    cursor: not-allowed;
  }
`;
export const PrimaryButton = styled(Button)`
  background: ${theme.primary};
  min-width: 110px;
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
  &:focus {
    background: #fff !important;
    color: #333 !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${theme.neutralPrimary};
  min-width: 110px;
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const ButtonAsLink = styled(Button)`
  background: transparent;
  padding: 0 !important;
  text-decoration: underline;
  font-weight: normal;
  color: ${theme.textPrimary};
  box-shadow: none !important;
  &:disabled {
    color: ${theme.disabled} !important;
    cursor: not-allowed;
    background: transparent !important;
  }
  &:hover {
    color: ${theme.textPrimary};
  }
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const GhostButton = styled(Button)`
  background: white;
  color: ${theme.textPrimary} !important;
  &:hover {
    color: ${theme.textInverted} !important;
    background: ${theme.neutralPrimary};
  }
  &:focus {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
  }
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const ButtonAsIcon = styled.button`
  border: 0;
  background: transparent !important;
  color: ${theme.textPrimary};
  min-width: auto !important;
  padding: 0;
  &:disabled {
    opacity: var(--disabled-opacity);
    cursor: not-allowed;
  }
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const BackButton = styled(GhostButton)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    margin-right: 6px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11"><path d="M5.5 10L1 5.5L5.5 1" stroke="%23333333" strokeWidth="1.25" fill="%23333333" strokeLinecap="round"/><path d="M11.5 5.5L1.75 5.5" stroke="%23333333" strokeWidth="1.25" strokeLinecap="round"/></svg>');
    width: 13px;
    height: 11px;
  }
  &:hover::before {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11"><path d="M5.5 10L1 5.5L5.5 1" stroke="%23FFFFFF" strokeWidth="1.25" fill="%23FFFFFF" strokeLinecap="round"/><path d="M11.5 5.5L1.75 5.5" stroke="%23FFFFFF" strokeWidth="1.25" strokeLinecap="round"/></svg>');
  }
  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;
