import React, { useEffect, useState } from 'react';
import CardFrame from 'components/CardFrame';
import { Col, Row } from 'reactstrap';
import { useTimeMangementState } from '../TimeMangementStateProvider';
import { SET_EVENT_TYPE } from '../actionTypes';
import { SELECT_BREAK_TYPE, BREAK_END, CLOCK_OUT, timeCardStatuses } from '../../../constants';
import { GhostButton, PrimaryButton } from 'css/components/Modules/Buttons';
import { AuthHeader } from 'css/components/Modules/Headings';
export default function EventSelection() {
  const [state, dispatch] = useTimeMangementState();
  const [selectedEvent, setSelectedEvent] = useState(undefined);

  const onEventSelect = () => {
    dispatch({ type: SET_EVENT_TYPE, data: { eventType: selectedEvent } });
  };
  useEffect(() => {
    if (
      state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus === timeCardStatuses.break ||
      state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus === timeCardStatuses.lunch
    ) {
      setSelectedEvent(BREAK_END); // auto select `End Break`if employee already on break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='event-selection'>
      <div className='text-center mb-4'>
        <AuthHeader>{`${state.eventGoingOn ? `End` : 'Take'} Break / Clock Out`}</AuthHeader>
      </div>
      <Row className='card-wrapper mb-5'>
        <Col sm={12} lg={6} className='mb-3 mb-md-5 mb-lg-0'>
          <CardFrame heading={`${state.eventGoingOn ? 'End Break' : 'Take Break'}`}>
            <div className='event-selection__button'>
              <GhostButton
                id='break'
                modifiers={
                  selectedEvent === SELECT_BREAK_TYPE || selectedEvent === BREAK_END
                    ? 'success'
                    : ''
                }
                onClick={() => {
                  if (
                    state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus ===
                      timeCardStatuses.break ||
                    state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus ===
                      timeCardStatuses.lunch
                  ) {
                    setSelectedEvent(BREAK_END);
                  } else {
                    setSelectedEvent(SELECT_BREAK_TYPE);
                  }
                }}
              >
                {selectedEvent === SELECT_BREAK_TYPE || selectedEvent === BREAK_END
                  ? 'Selected'
                  : 'Select'}
              </GhostButton>
            </div>
          </CardFrame>
        </Col>
        <Col sm={12} lg={6}>
          <CardFrame heading={`Clock Out`}>
            <div className='event-selection__button'>
              <GhostButton
                disabled={
                  state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus ===
                    timeCardStatuses.break ||
                  state.employeeLoginInfo.CurrentEmployeeStatus.ClockStatus ===
                    timeCardStatuses.lunch
                }
                id='clockIn'
                modifiers={selectedEvent === CLOCK_OUT ? 'success' : ''}
                onClick={() => setSelectedEvent(CLOCK_OUT)}
              >
                {selectedEvent === CLOCK_OUT ? 'Selected' : 'Select'}
              </GhostButton>
            </div>
          </CardFrame>
        </Col>
      </Row>
      <div className='event-selection__button text-center mt-auto mt-md-0'>
        <PrimaryButton disabled={!selectedEvent} onClick={onEventSelect}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
}
