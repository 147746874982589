import styled from 'styled-components';
import { theme } from '../../utils';

export const StyledCheckbox = styled.span`
  position: relative;
  .styled-checkbox {
    position: absolute;
    opacity: 0;
    &.focus-visible {
      + label {
        &::before {
          background: ${theme.textPrimary};
        }
      }
    }
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      height: 20px;
    }
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border-radius: 2px;
      box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 15%);
      background: #f9f9f9;
      border: solid 0.6px #e3e3e3;
    }
    &:hover + label:before {
      @media (min-width: 1366px) {
        background: ${theme.textPrimary};
      }
    }
    &:checked + label:before {
      background: ${theme.textPrimary};
    }
    &:disabled + label {
      color: ${props => (props.disabledStyles === false ? theme.textPrimary : theme.disabled)};
      cursor: not-allowed;
    }
    &:disabled + label:before {
      box-shadow: none;
      background: ${props => (props.disabledStyles === false ? theme.textPrimary : theme.disabled)};
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9.5px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
`;

export const CheckboxTicksWrapper = styled.div`
  .customCheckbox {
    display: flex;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      cursor: pointer;
      & ~ span {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.15);
        background-color: #f9f9f9;
        margin-right: 10px;
        border: solid 0.6px #e3e3e3;
      }
      &:checked {
        & ~ span {
          background: ${theme.alternativeText};
          position: relative;
          /* &:after {
            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            content: '';
            position: absolute;
            top: 2px;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0 auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          } */
        }
      }
    }
  }
`;
