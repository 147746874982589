import { useQuery } from 'react-query';

import onQueryError from 'helpers/queryError';
import APIUtil from 'api/apiUtil';

const api = new APIUtil();

export const getSessionDetails = ({ companyId }) => {
  return api.get(`user/session?companyId=${companyId}`);
};

export const useSessionDetails = ({ companyId, enabled = true, onSuccess }) => {
  return useQuery({
    queryKey: [
      'SESSION_DETAILS',
      {
        companyId
      }
    ],
    queryFn: () => getSessionDetails({ companyId }),
    enabled,
    onError: error => onQueryError(error),
    select: data => data.data,
    onSuccess: onSuccess ? onSuccess : null
  });
};
