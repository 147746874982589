export const breakpointValues = {
  maxMobile: 767,
  maxTablet: 1024,
  maxTabletLandscape: 1119,
  minDesktop: 1200,
  minTablet: 768,
  minTabletLandscape: 1025
};
export const breakpoints = {
  maxMobile: `max-width: ${breakpointValues.maxMobile}px`,
  maxTablet: `max-width: ${breakpointValues.maxTablet}px`,
  maxTabletLandscape: `max-width: ${breakpointValues.maxTabletLandscape}px`,
  minDesktop: `min-width: ${breakpointValues.minDesktop}px`,
  minTablet: `min-width: ${breakpointValues.minTablet}px`,
  minTabletLandscape: `min-width: ${breakpointValues.minTabletLandscape}px`
};
