//Helper function for Query errors

import { errorAlert } from 'utils/alert';

export default function onQueryError(error) {
  if (error.response) {
    errorAlert(error.response?.data?.error);
    return;
  }
  errorAlert('Something Went Wrong!');
  throw error;
}
