import React, { Component, Suspense, lazy } from 'react';
import Auth from './containers/Auth';
import GlobalStyle from './css/global';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Route, Switch, Redirect } from 'react-router-dom';
//HOC
import requiredAuth from './hoc/requiredAuth';
import ErrorBoundary from 'containers/ErrorBoundatry';
// Components
import Spinner from 'components/Spinner';
import GuestLogin from 'containers/GuestLogin';
import appConfig from './config';
import TimeMangement from 'containers/TimeMangement';
import Typography from 'css/components/Modules/Typography';
import { ResponsivePhase1 } from 'css/components/Modules/ResponsivePhase1';
import ConnectLanding from 'components/Pages/ConnectLanding';
import { isGuestEnv } from 'utils/envUtils';
import ForgotPasswordInitialStep from 'containers/Auth/ForgotPassword/ForgotPasswordInitialStep';
// import Maintenance from 'containers/Maintenance';
const Dashboard = lazy(() => import('./containers/Dashboard'));
const CompanyCreateForm = lazy(() => import('./containers/Company/CompanyCreateForm'));
const Header = lazy(() => import('./containers/Header'));
const SignupOnboarding = lazy(() => import('./containers/Auth/SignupOnboarding'));
const Page500 = lazy(() => import('./components/Pages/Page500'));
const Page404 = lazy(() => import('./components/Pages/Page404'));
const Page403 = lazy(() => import('./components/Pages/Page403'));
const landingPage = lazy(() => import('./components/Pages/LandingPage'));
const ForgotPassword = lazy(() => import('./containers/Auth/ForgotPassword'));
const Signup = lazy(() => import('./containers/Signup'));
const LoggedInError = lazy(() => import('./components/Pages/LoggedInError'));
const PlanAndPayment = lazy(() => import('./containers/Payment'));
const Maintenance = lazy(() => import('./containers/Maintenance'));
const EmailSubscription = lazy(() => import('./containers/CustomerManagement/Subscription'));
class App extends Component {
  componentDidMount() {
    //adding dynatrace
    var newScript = document.createElement('script');
    switch (appConfig.config().env) {
      case 'develop':
        newScript.src = './dynatrace/dynatraceDev.js';
        break;
      case 'qa':
        newScript.src = './dynatrace/dynatraceQA.js';
        break;
      case 'staging':
        newScript.src = './dynatrace/dynatraceStage.js';
        break;
      case 'production':
        newScript.src = './dynatrace/dynatraceProd.js';
        break;
      default:
        break;
    }
    document.body.appendChild(newScript);
  }
  render() {
    const routes = (
      <Switch>
        <Route path='/signup/user/onboarding/:userId' exact component={SignupOnboarding} />

        <Route path='/forgot-password' exact component={ForgotPasswordInitialStep} />
        <Route path='/user/reset-password' exact component={ForgotPassword} />
        <Route path={`/emails-subscription`} component={EmailSubscription} />

        <Route path='/error-500' component={Page500} />
        <Route path='/error-404' component={Page404} />
        <Route path='/error-403' component={Page403} />
        <Route path='/download/app' component={landingPage} />
        <Route path='/landing-page' component={landingPage} />
        {isGuestEnv() ? '' : <Route path='/signup' component={Signup} />}
        {isGuestEnv() ? (
          <Route path='/login/guest' exact component={GuestLogin} />
        ) : (
          <Route path='/login' exact component={Auth} />
        )}
        <Route path='/dashboard' component={requiredAuth(Dashboard)} />
        <Route path='/company/create' exact component={requiredAuth(CompanyCreateForm)} />
        <Route path='/user-already-logged-in' component={LoggedInError} />
        <Route path='/plan-and-payment' component={PlanAndPayment} />
        <Route path='/maintenance' component={Maintenance} />
        <Route path='/timeclock' component={TimeMangement} />
        <Route path='/connect-landing' component={ConnectLanding} />
        <Redirect to='/dashboard' />
      </Switch>
    );

    return (
      <>
        <Typography />
        <GlobalStyle />
        <ResponsivePhase1 />
        <ToastContainer autoClose={false} />
        <ErrorBoundary>
          <Suspense fallback={<Spinner loading />}>
            <Header />
            {routes}
          </Suspense>
        </ErrorBoundary>
        {/* <div id='trackingpopupWrapper' /> */}
      </>
    );
  }
}

export default App;
