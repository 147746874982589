import styled from 'styled-components';
import { breakpoints, theme } from '../../utils';

export const TimeClockWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 749px) {
    height: 100%;
  }
  .clock-wrapper {
    /* @media (min-width: 768px) {
            position: relative;
        } */
    .react-timekeeper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      @media (${breakpoints.maxTablet}) {
        position: fixed;
      }
    }
  }
  .event-selection {
    @media (max-width: 749px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding: 45px 25px 90px 25px;
    }
    @media (max-width: 850px) and (orientation: landscape) {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
    &__button {
      button {
        @media (max-width: 749px) {
          width: 88% !important;
        }
      }
    }
    .card-wrapper {
      @media (max-width: 850px) and (orientation: landscape) {
        margin-bottom: 1.5rem !important;
      }
      @media (min-width: 1024px) {
        width: 620px;
        margin-bottom: 90px !important;
      }
      @media (min-width: 1200px) {
        width: 700px;
      }
      & > div {
        @media (max-width: 850px) and (orientation: landscape) {
          width: 50%;
          flex: 0 0 50%;
          margin-bottom: 0 !important;
        }
      }
      .card-header {
        background: ${theme.neutralPrimary};
        color: ${theme.pageBackgroundWhite};
      }
      .card-body {
        text-align: center;
        button {
          width: 100% !important;
          @media (min-width: 750px) {
            width: 75% !important;
          }
        }
      }
    }
  }
  .event-type-selection {
    @media (max-width: 749px) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding: 45px 25px 90px 25px;
    }
    &__button {
      @media (max-width: 749px) {
        margin-top: auto;
      }
      button {
        @media (max-width: 749px) {
          width: 88%;
        }
      }
    }
  }
  .employee-block-mb {
    @media (min-width: 750px) {
      margin-bottom: 90px !important;
    }
    @media (max-width: 850px) and (orientation: landscape) {
      margin-bottom: 1.5rem !important;
    }
  }
  .event-end {
    @media (max-width: 749px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-bottom: 90px;
      padding-top: 45px;
    }
    @media (max-width: 850px) and (orientation: landscape) {
      width: 360px;
    }

    &__button {
      button {
        @media (max-width: 749px) {
          width: 100%;
        }
      }
    }
    &__datepicker {
      button {
        width: 100%;
        margin: 0;
        height: auto;
        padding: 10px 15px;
        background-color: #fcfcfc;
        border-radius: 5px;
        font-size: 10px;
        color: #333333;
        border: none;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
        text-align: left;
      }
    }
  }
  .event-start {
    @media (max-width: 749px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-bottom: 90px;
      padding-top: 45px;
    }
    h1 {
      @media (max-width: 850px) and (orientation: landscape) {
        margin-bottom: 0;
      }
    }
    .form-group {
      &::last-of-type {
        @media (max-width: 850px) and (orientation: landscape) {
          margin-bottom: 0;
        }
      }
    }
    &__button {
      @media (max-width: 850px) and (orientation: landscape) {
        margin-top: 0 !important;
      }
      button {
        @media (max-width: 749px) {
          width: 88%;
        }
      }
    }
    &__datepicker {
      button {
        width: 100%;
        margin: 0;
        height: auto;
        padding: 10px 15px;
        background-color: #fcfcfc;
        border-radius: 5px;
        font-size: 10px;
        color: #333333;
        border: none;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
        text-align: left;
      }
    }
  }
`;
