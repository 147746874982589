export default {
  DEV: {
    AWS_COGNITO_REGION: 'us-east-1',
    AWS_USER_POOLS_ID: 'us-east-1_GPn6FqdcE',
    AWS_USER_POOLS_WEB_CLIENT_ID: '41bp8jfdh4ema9vmtg7ssbd6v3',
    AWS_IDENTITY_POOL_ID: 'us-east-1:80b5019e-ef7e-4413-a9aa-c05bb06f64a2',
    GUEST: {
      AWS_USER_POOLS_ID: 'us-east-1_sVcTWR4Zi',
      AWS_USER_POOLS_WEB_CLIENT_ID: '6516uv1oljge77alfoh5bju09n',
      AWS_IDENTITY_POOL_ID: 'us-east-1:ee72c0c9-194c-41e8-a712-8571d32f2574'
    }
  },
  QA: {
    AWS_COGNITO_REGION: 'us-east-1',
    AWS_USER_POOLS_ID: 'us-east-1_EQnLy5loP',
    AWS_USER_POOLS_WEB_CLIENT_ID: 'l3c64dssjuj1i0oubmfpnhecs',
    AWS_IDENTITY_POOL_ID: 'us-east-1:050e3bb6-1c68-48d8-8f85-f61924ed9588',
    GUEST: {
      AWS_USER_POOLS_ID: 'us-east-1_is9z40OZd',
      AWS_USER_POOLS_WEB_CLIENT_ID: '1atosd61ea47pe38vkhrrtp19g',
      AWS_IDENTITY_POOL_ID: 'us-east-1:c4a8a284-f339-4752-8d61-4cb8071c407a'
    }
  },
  STAGE: {
    AWS_COGNITO_REGION: 'us-east-1',
    AWS_USER_POOLS_ID: 'us-east-1_Id0iNe7dQ',
    AWS_USER_POOLS_WEB_CLIENT_ID: '3olekifqs2immgpbksltvnu5ou',
    AWS_IDENTITY_POOL_ID: 'us-east-1:3969f292-721b-44df-82c9-c13ee760bdb1',
    GUEST: {
      AWS_USER_POOLS_ID: 'us-east-1_Liz6laEc3',
      AWS_USER_POOLS_WEB_CLIENT_ID: '7lua9v1slk2eqjdj43btopceo4',
      AWS_IDENTITY_POOL_ID: 'us-east-1:936b77bd-2f97-4543-8fd7-fad6a474eb9a'
    }
  },
  PRODUCTION: {
    AWS_COGNITO_REGION: 'us-east-1',
    AWS_USER_POOLS_ID: 'us-east-1_f0OgfgC8I',
    AWS_USER_POOLS_WEB_CLIENT_ID: '49kpdqb2qul0v9e03or9smhkfg',
    AWS_IDENTITY_POOL_ID: 'us-east-1:e95da901-f18c-4928-b4ff-90af8cbd8314',
    GUEST: {
      AWS_USER_POOLS_ID: 'us-east-1_NpLpGZzB8',
      AWS_USER_POOLS_WEB_CLIENT_ID: '7s1qces8an6bet4j48bh18ffcd',
      AWS_IDENTITY_POOL_ID: 'us-east-1:cb23b0b5-717a-4956-975b-cdeb0c5028f1'
    }
  }
};
