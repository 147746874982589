import styled from 'styled-components';
import { theme, typeScale } from 'css/utils';

export const WrapperNewCalendar = styled.div`
  .rbc-addons-dnd .rbc-addons-dnd-resizable {
    min-height: 30px;
    background: #fff;
  }
  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody {
    border: none !important;
  }
  /* .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td {
    border: none !important;
  } */
  @media (max-width: 1024px) {
    padding: 10px 15px;
  }
  .fc-unthemed {
    td.fc-today {
      background-color: #eaf6ff;
    }
    .fc-dayGridMonth-view .fc-widget-content {
      border-bottom: 1px solid #ccc !important;
    }
  }
  .fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px !important;
    h2 {
      font-size: 16px;
    }
  }
  .fc-day-header {
    font-size: 10px;
    overflow: visible;
    display: table-cell;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    color: #333;
    border-right: 1px solid #fff !important;
    font-size: 14px;
    font-weight: 400;
    padding: 7px;
    &.fc-today {
      span {
        font-weight: 700;
      }
    }
  }
  .fc-widget-content {
    font-size: 10px;
    color: #ccc;
    &.fc-past,
    &.fc-future,
    &.fc-today {
      border-right: 1px solid #cccccc !important;
    }
    & [style='background-color:lightblue'] {
      .fc-content {
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333 !important;
      }
    }
  }
  hr.fc-divider {
    /* border-bottom: 2px solid #ddd; */
    background: none;
    box-shadow: none;
    padding: 0;
  }
  .blue {
    .rbc-time-slot {
      border: none;
    }
    .rbc-timeslot-group {
      border-bottom: none;
    }
  }
  .fc-dayGridMonth-view .fc-scroller {
    height: calc(100vh - 240px) !important;
    overflow-y: auto !important;
  }
  .fc-widget-content.fc-today {
    background: #f9f9f9;
    border-right: 1px solid #ddd;
  }
  .calendarheader {
    align-items: center;
    margin: 0 0 10px;
    display: flex;
    padding: 20px 30px 0;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
      align-items: center;
    }
    .collapse {
      width: 105%;
      order: -1;
      /* margin: -20px -30px 20px; */
    }
    .datepickerCombo {
      position: relative;
      margin: 0 25px 0 0;
      ​ .prevNex {
        button {
          position: absolute;
          top: 0;
          min-width: auto;
          border: none;
          width: 24px;
          background: none;
          &.prev {
            left: 0;
          }
          &.next {
            right: 0%;
          }
        }
      }
    }
    .RangeExample {
      button {
        background: #fff;
        width: 173px;
        color: #333;
        box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1) !important;
        @media (max-width: 1500px) and (min-width: 768px) {
          width: 100%;
        }
      }
    }
    .form-group {
      margin: 0 25px 0 0;
      /* flex: 0 0 24%; */
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .custom-design {
      width: 173px;
      z-index: 999;
    }
    .slot-duration-dd {
      width: 80px;
    }
    .calendar-view-selector {
      width: 125px;
      .custom-design {
        width: 100%;
      }
    }
    .lastsync {
      margin-left: 10px;
      p {
        font-size: 8px;
        color: ${theme.grey666666};
      }
    }
    .refresh-sync {
      color: #333;
      font-size: 10px;
      cursor: pointer;
      img {
        width: 12px;
        margin-left: 5px;
      }
      .active {
        animation-name: rotate-refresh;
        animation-iteration-count: infinite;
        animation-duration: 2s;
      }
    }
    @keyframes rotate-refresh {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .slot-duration-dd {
    width: 80px;
  }
  .fc-scroller.fc-time-grid-container {
    height: calc(100vh - 361px) !important;
    margin-bottom: 20px;
    tbody tr {
      &:nth-of-type(4n + 4) {
        .fc-widget-content {
          &:last-child {
            border-bottom: 1px solid #ccc !important;
          }
        }
      }
      ​ .fc-widget-content {
        &:last-child {
          border-bottom: 0.5px solid #e3e3e3 !important;
        }
      }
    }
    .fc-widget-content {
      &:last-child {
        border-bottom: 0.5px solid #e3e3e3 !important;
      }
    }
  }
  .fc-ltr {
    padding: 0 30px 0;
    @media (max-width: 1024px) {
      display: none;
    }
    .fc-day-top {
      text-align: center;
      span {
        font-size: 10px;
        color: #666;
        text-align: center;
        padding: 10px 0;
        float: none !important;
        display: inline-block;
      }
      &.fc-today {
        padding: 10px 0;
        span {
          background: #333333;
          color: #fff;
          border-radius: 50%;
          width: 30px;
          line-height: 26px;
          position: relative;
          padding: 0 0 5px;
          display: inline-block;
          &:after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            right: 0;
            background: #fff;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin: auto;
          }
        }
      }
    }
    .fc-now-indicator-line {
      background: #cc0000;
      &:after {
        content: '';
        position: absolute;
        left: -4px;
        width: 7px;
        height: 7px;
        margin: auto;
        background: #cc0000;
        top: 0;
        bottom: 0;
        border-radius: 50%;
      }
    }
    .fc-left h2 {
      display: none;
    }
    .fc-left,
    .fc-right {
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
      button {
        font-size: 8px;
        color: #999999;
        background: #f5f5f5;
        min-width: auto;
        border: none;
        padding: 5px 10px;
        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
        margin: 0;
        &:hover {
          background: #fff;
        }
      }
    }
    .fc-center h2 {
      font-size: 10px;
      color: #333;
      font-weight: 400;
    }
    .fc-event {
      & > div {
        min-height: 40px;
        text-align: left;
        overflow: hidden;
        border-radius: 5px;
      }
      .drive-time {
        padding-left: 10px;
        padding-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        &__hoverable {
          box-shadow: var(--modal-box-shadow);
          padding: 5px 10px;
        }
        svg {
          min-width: 11px;
        }
      }
      & .notifDot {
        min-height: auto;
      }
      p {
        font-size: 10px;
        padding: 0 5px 0 10px;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        &.warning {
          padding-left: 0;
          width: auto;
        }
        &:nth-child(2) {
          padding-left: 25px;
          padding-top: 0px;
          flex: 1;
        }
        &:first-child {
          font-weight: 700;
          display: flex;
          align-items: center;
        }
      }
      svg {
        margin-left: 4px;
        margin-top: 0px;
        margin-right: 5px;
      }
    }
    .fc-more-cell .fc-more {
      font-size: 10px;
      color: #333;
      font-weight: 600;
      padding: 5px;
    }
    .fc-event {
      background: #fff;
      color: #333;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
      border: none;
      font-size: 10px;
      padding: 0;
      overflow: visible;
      overflow: hidden;
      min-height: ${props => (props.calendarView ? '30px' : '15px')};

      &.rbc-addons-dnd-dragged-event,
      &.rbc-addons-dnd-drag-preview {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
      }
      &.rbc-selected {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        outline: none;
        min-height: 30px;
      }
      .rbc-event-label {
        font-weight: 700;
        position: absolute;
        padding-left: 32px;
        padding-top: 2px;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .schedule-controls-tut {
    .form-group {
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .black-btn {
    background: #333 !important;
  }
  .schedule-today-btn {
    margin-right: 15px;
  }
`;

export const FullCalendarCheckboxesWrapper = styled.div`
  .fullcalendar-checker {
    padding-left: 84.3px;
    padding-right: 40px;
    &__wrapper {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      padding: 10px 0;
    }
  }
  .col-workWeek {
    width: 25%;
  }
  .col-week {
    width: 14.28%;
  }
  .checker-input {
    margin-right: 4px;
  }
  .checker-label {
    ${typeScale.textXs};
    margin-bottom: 0;
    margin-top: 2px;
  }
  .checker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 10px; */
  }
`;
