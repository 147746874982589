import { signOut } from 'aws-amplify/auth';
import { errorAlert } from 'utils/alert';

export const handleSignOut = async () => {
  try {
    await signOut();
  } catch (error) {
    if (error.response) {
      errorAlert(error.response?.data?.error);
      return;
    }
    errorAlert('Something Went Wrong!');
    throw error;
  }
};
