import axios from 'axios';
import appConfig from '../config';
class APIUtil {
  constructor(root = appConfig.config().baseUrl) {
    this.root = root;
  }

  /**
   * Get request
   *
   * @param path
   * @returns {axios.promise}
   */
  get(path, config = {}, version = 'v1') {
    return axios.get(`${this.root}/${version}/${path}`, config);
  }

  /**
   * Post request
   *
   * @param path
   * @param data
   * @param config
   * @returns {axios.Promise}
   */
  post(path, data, config = {}, version = 'v1') {
    return axios.post(`${this.root}/${version}/${path}`, data, config);
  }

  /**
   * Put request
   *
   * @param path
   * @param data
   * @param config
   * @returns {axios.Promise}
   */
  put(path, data, config = {}, version = 'v1') {
    return axios.put(`${this.root}/${version}/${path}`, data, config);
  }

  /**
   * Patch request
   *
   * @param path
   * @param data
   * @param config
   * @returns {axios.Promise}
   */
  patch(path, data, config = {}, version = 'v1') {
    return axios.patch(`${this.root}/${version}/${path}`, data, config);
  }

  /**
   * Delete request
   *
   * @param path
   * @param data
   * @param config
   * @returns {axios.Promise}
   */
  delete(path, data, config = {}, version = 'v1') {
    return axios.delete(`${this.root}/${version}/${path}`, config);
  }
}

export default APIUtil;
