import { SectionHeading } from 'css/components/Modules/Headings';
import { LinkAsButtonWrapper } from 'css/components/Modules/Links';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import app from 'images/app.png';

import styled from 'styled-components';
import { theme } from 'css/utils';
import SVGIcon from 'containers/SVGicon';

const ConnectLandingStyles = styled.div`
  overflow-y: auto;
  padding-top: 50px;
  .boxed {
    border: solid 1px ${theme.neutralPrimary};
    padding: 20px 30px;
    width: 92%;
    max-width: 505px;
    margin: auto;
  }
  .app-preview {
    width: 300px;
  }
  .apple svg {
    max-height: 37px; // because both icons have different viewboxes, handling the max-height for each icon separately
  }
  .google svg {
    max-height: 36px;
  }
`;

const ConnectLanding = () => {
  return (
    <ConnectLandingStyles className='remaining-height'>
      <Row>
        <Col xs={12} md={6} className='mb-4 d-flex align-items-center'>
          <div className='boxed mx-auto'>
            <div className='mb-5 d-flex flex-column align-items-center'>
              <SectionHeading className='text-center mb-3'>
                The full web version of Horizon360 Connect is not yet available, click below to
                manage your Connect account from a web browser
              </SectionHeading>
              <LinkAsButtonWrapper>
                <Link to='/dashboard/settings/billing-payments/subscription'>
                  Change Subscription Details
                </Link>
              </LinkAsButtonWrapper>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <h3 className='mb-3 text-header1'>Download our Mobile App:</h3>
              <div className='d-flex'>
                <a
                  href='https://apps.apple.com/us/app/horizon360/id1489969453'
                  className='w-50 px-1 apple'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <SVGIcon name='appStore' />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.toro.horizon360&hl=en_US&gl=US'
                  className='w-50 px-1 google'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <SVGIcon name='googlePlay' />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className='d-flex justify-content-center px-3 mb-4'>
          <div className='mx-3'>
            <img className='app-preview' src={app} alt='Horizon360 app preview' />
          </div>
        </Col>
      </Row>
    </ConnectLandingStyles>
  );
};

export default ConnectLanding;
