import styled from 'styled-components';
import refresh from '../../images/refresh.png';
import search from '../../images/search-icon.png';
import ODJ_Icon from '../../images/ODJ_Icon.svg';
import { breakpoints, theme, typeScale } from '../utils';

export const MobileWrap = styled.section``;
export const LeftsideBarWrap = styled.div`
  background: #3f3f3f;
  width: var(--leftsidebar-width);
  padding: 24px 0 0;
  position: sticky;
  top: 0;
  height: calc(100vh - 110px);
  display: block;
  overflow-y: auto;
  z-index: 999999;
  li {
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  .nav {
    &.nav-collapsed {
      li {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0;
      }
      @media (min-width: 1024px) {
        max-height: 0;
        overflow: hidden;
        &.active {
          max-height: 300px;
        }
      }
    }
    .nav-item {
      position: relative;
      &.active {
        @media (min-width: 1024px) {
          background: #292929;
          position: relative;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.23);
          &:before {
            position: absolute;
            content: '';
            left: 0;
            width: 5px;
            height: 100%;
            background: #cc0000;
          }
        }
      }
    }
    .nav-collapsed {
      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
      li {
        @media (max-width: 1024px) {
          height: 54px;
          margin-top: 0;
        }
      }
    }
  }

  a {
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 8px;
    text-align: center;
    padding: 15px 0px;
    @media (max-width: 1024px) {
      font-size: 16px;
      display: inline-flex !important;
      flex-direction: row !important;
      align-items: flex-start !important;
      text-align: left;
      justify-content: flex-start !important;
      border: none;
    }
    svg {
      margin: 0 0 10px;
      @media (max-width: 1024px) {
        margin: 0 20px 0 0;
        width: 26px;
      }
      path {
        fill: #fff;
      }
    }
    &.active {
      background: #292929;

      @media (max-width: 1024px) {
        background: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  @media (max-width: 1024px) {
    background: none;
    width: 100%;
    top: 0;
    height: auto;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media only screen and (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: calc(100vh - 96px);
  }
`;
export const MainSection = styled.section`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  &.main-admin {
    min-height: calc(100% - 120px);
    height: calc(100% - 120px);
    @media (min-width: 520px) {
      min-height: calc(100% - 100px);
      height: calc(100% - 100px);
    }
    @media (min-width: 1200px) {
      min-height: calc(100% - 120px);
      height: calc(100% - 120px);
    }
  }
  &.main-guest {
    min-height: calc(100% - 120px);
    height: calc(100% - 120px);
    @media (min-width: 520px) {
      min-height: calc(100% - 100px);
      height: calc(100% - 100px);
    }
    @media (min-width: 1200px) {
      min-height: calc(100% - 120px);
      height: calc(100% - 120px);
    }
  }
  &.admin-landing {
    min-height: calc(100% - 46px);
    height: calc(100% - 46px);
    @media (min-width: 1024px) {
      min-height: calc(100% - 84px);
      height: calc(100% - 84px);
    }
  }
  &.main-operator {
    min-height: calc(100% - 46px);
    height: calc(100% - 46px);
    @media (min-width: 1024px) {
      min-height: calc(100% - 85px);
      height: calc(100% - 85px);
    }
    &.main-tablet {
      min-height: calc(100% - var(--leftsidebar-width));
      height: calc(100% - var(--leftsidebar-width));
    }
  }
  height: auto;
  width: 100%;
  flex-direction: row;
  // leftsidebar toggle
  @media (max-width: 1024px) {
    ${LeftsideBarWrap} {
      display: none;
    }
  }
  .card {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border: none;
    width: 100%;
    margin: 0 0 20px;
    .card-header {
      background: #333333;
      padding: 11px 20px;
      border-radius: 5px 5px 0 0;
      position: sticky;
      top: 0;
      z-index: 9;
      min-height: 48px;
      strong,
      h3 {
        width: 75%;
        color: #fff;
        margin: 3px;
        font-size: 10px;
        text-transform: uppercase;
        position: relative;
      }
      .mini-spinner {
        margin: 0 5px;
        position: absolute;
        left: 100%;
        div {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
`;
export const MiddleSection = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 0;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.12);
  max-width: 100%;
  width: calc(100vw - (var(--leftsidebar-width) + var(--rightsidewrap-width))); // width of leftsidebar: 115px + width of right side form container: 380px
  &.android {
    @media (min-width: 1025px) and (orientation: landscape) {
      padding-top: 10px !important;
      margin-left: var(--leftsidebar-width);
    }
    .settings-tabs {
        @media (min-width: 1025px) and (orientation: landscape) {
          box-shadow: 0 3px 8px 0 rgba(0,0,0,0.12) !important;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1210px) {
    width: calc(100vw - var(--leftsidebar-width));
  }
  @media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) and (min-device-pixel-ratio: 2) {
    width: calc(100vw - var(--leftsidebar-width));
  }
  @media (max-width: 1210px) {
    &.hide {
      display: none;
    }
  }
  @media(max-width: 1366px) and (orientation: landscape) and (min-device-pixel-ratio: 2) {
    &.hide {
      display: none;
    }
  }
  .jobFilter {
    max-width: 120px;
    position: sticky;
    z-index: 9;
    width: 120px;
    margin-bottom: 20px;
    /* border: solid; */
    &--companies {
      max-width: 160px;
      width: 160px;
      [class*='menu'] {
        [class*='option'] {
          ${typeScale.textMd}
        }
      }
    }
    .btn-group {
      width: 100%;
    }
    button {
      border-radius: 5px;
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1) !important;
      background-color: #fcfcfc !important;
      font-size: 10px;
      font-weight: 400;
      color: #333 !important;
      text-align: left;
      padding: 0 15px;
      display: flex;
      min-height: 26px;
      align-items: center;
      justify-content: space-between;
      &[disabled] {
        background: #e9ecef !important;
      }
    }
    .show {
      button {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1) !important;
      }
    }
    .dropdown-menu {
      border: none;
      padding: 0;
      min-width: 100%;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
      button {
        border-radius: 0;
        font-size: 10px;
        color: #333;
        box-shadow: none !important;
        padding: 5px 15px;
        &:hover {
          font-weight: 700;
        }
        &:nth-child(odd) {
          background: #f9f9f9 !important;
        }
        &:nth-child(even) {
          background: #fff;
        }
      }
    }
  }
  h3 {
    font-size: 18px;
    color: #333333;
    margin: 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  button {
    font-size: 10px;
    letter-spacing: 0.2px;
    padding: 8px 0;
    /* min-width: 120px; */
    font-weight: bold;
    &.btn-dark {
      min-width: 120px;
    }
  }
  .companyPage {
    padding: 0 40px;
    @media (max-width: 1024px) {
      padding: 0 15px;
    }
    @media (max-width: 1550px) {
      .list-group li {
        flex-wrap: wrap;
      }
    }
    .btn.btn-success {
      margin: 0 20px 0 0;
    }
    .list-group .company-info .right span {
      width: 100%;
    }
    .list-group-item-heading {
      /* width: 70% !important; */
    }
  }
  .list-group {
    .crew {
      border-left: 5px solid #000;
    }
    .contractbtn {
      margin: 7px 0 14px;
    }
    li {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.11);
      background: #fff;
      border-radius: 4px;
      padding: 0;
      border: none;
      margin: 0 0 10px;
      border-left: 5px solid transparent;
      color: #333;
      &:hover,
      &.active {
        border-left: 5px solid #333;
        cursor: pointer;
      }
  
      .list-group-item-heading {
        margin: 0;
        width: 85%;
        padding: 16px;
        .contracts-icons {
          span {
            padding: 0 15px 0 0;
          }
        }
      }

      .company-info {
        align-items: center;
        flex-grow: 1;
        &.recurring {
          .thumb {
            &:after {
              background-image: url(${refresh});
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: left 0 top;
              display: inline-block;
              content: '';
              width: 25px;
              height: 25px;
              position: absolute;
              border-radius: 50%;
              top: -6px;
              left: -12px;
            }
          }
        }
        &.onDemandJob{
          .thumb {
            &:after {
              background-image: url(${ODJ_Icon});
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: left 0 top;
              display: inline-block;
              content: '';
              width: 25px;
              height: 25px;
              position: absolute;
              top: 9px;
              border-radius: 50%;
              left: 6px;
            }
          }
        }
        .thumb {
          width: 34px;
          height: 34px;
          min-width: 34px;
          margin-right: 8px;
          border: 1.25px solid #333;
          border-radius: 50%;
          img {
            border: 1px solid transparent;
          }
        }
        .right {
          flex: 1;
          text-align: left;
          width: calc(100% - 42px);
          span {
            font-size: 14px;
            color: #333333;
            background: none;
            box-shadow: none;
            padding: 0;
            cursor: pointer;
            font-weight: 400;
            text-align: left;
            line-height: 1.2em;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: 100%;
          }
          pre {
            font-size: 10px;
            color: #999;
            margin: 4px 0 0;
            font-family: 'Verdana' !important;
            text-align: left;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .contract-info{
        padding-left: 42px;
      }
      .company-btn-container {
        height: 100%;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background: #fff;
        &[clickedOutsideComponent='false'] {
          .company-btns {
            width: auto;
            z-index: 9;
            max-width: 400px;
            overflow: visible !important;
          }
        }
      }
      .company-btns {
        justify-content: flex-end;
        padding: 8px 0 8px 0px;
        @media (max-width: 1550px) {
          flex: 0 0 99%;
          background: #fcfcfc;
          justify-content: start !important;
          margin-top: 10px;
          border-radius: 10px;
          & > div {
            flex: 1;
          }
        }
        &__more-info {
          /* display: none; */
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 100%;
          right: 0;
          width: 190px;
          z-index: 999;
          overflow: hidden;
          cursor: default;
          background: #fff;
          padding: 15px 12px;
          border-radius: 5px;
          box-shadow: 0 0 6px rgba(0,0,0,0.11);
          transition: all 0.3s ease-in;
          ul {
            padding-left: 12px;
            li {
              text-align: left;
              border-left: 0;
              cursor: auto;
              box-shadow: none;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          p {
            text-align: left;
            font-size: 12px;
            line-height: 14px;
          }
        }
        .show-location-list {
          &:hover {
            .company-btns__more-info {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .contractIcon {
          flex-direction: row;
          margin-left: 8px;
          & > div {
            height: 45px;
            width: 45px;
            min-width: 45px;
            padding: 5px;
            line-height: 37px;
            &:hover {
              background: #f2f2f2;
              border-radius: 50%;
            }
          }
          &.open {
            & > div {
              background: #f2f2f2;
              border-radius: 50%;
              line-height: 37px;
              height: 45px;
              width: 45px;
              padding: 5px;
            }
          }
          svg {
            min-width: 19px;
          }
        }
        .dropdown {
          border: none;
          padding: 0;
          width: auto;
          display: flex;
          justify-content: center;
          padding: 5px;
          width: 40px;
          min-width: 40px;
          height: 40px;
          line-height: 37px;
          &:hover {
            background: #f2f2f2;
            border-radius: 50%;
          }
          & button {
            height: 100%;
          }
          .dropdown-menu {
            width: 175px;
            padding: 15px;
            line-height: normal;
            border-radius: 5px;
            border: none;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
            top: 24px !important;
            right: -10px;
            word-wrap: break-word;
            button {
              color: #333333;
              font-size: 12px;
              font-weight: 400;
              margin: 8px 0;
              letter-spacing: 0;
              width: 100%;
              white-space: pre-line;
              word-break: break-word;
              line-height: 0;
              flex-direction: column;
              align-items: flex-start;
              line-height: initial;
              text-align: left;
              margin: 0px;
              &:not(:last-child) {
                padding: 0 0 10px;
              }
              p {
                word-break: break-word;
              }
            }
          }
        }
        &__hoverable { // TODO: to be done away with after all actions buttons are replaced with custom buttons
          &:first-child {
            margin-left: 8px;
          }
          &:hover {
            background: #f2f2f2;
          }
        }
        > div {
          position: relative;
          border-left: 1px solid #f9f9f9;
          height: 45px;
          width: 45px;
          text-align: center;
          padding: 0 10px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .active {
            width: 38px;
            height: 38px;
            padding: 11px 0;
            background: #f2f2f2;
            border-radius: 50%;
            path {
              fill: #333;
            }
          }
          &.contractIcon:hover svg {
            /* width: auto;
            height: auto; */
            padding: 0;
            background: none;
            border-radius: 0%;
          }
          &:hover {
            cursor: pointer;
            .badge {
              color: #333;
            }
            & .dropdown svg {
              width: auto;
              height: auto;
              padding: 0;
              background: none;
              border-radius: 0%;
            }
            svg {
              /* width: 38px;
              height: 38px;
              padding: 11px 0;
              background: #f2f2f2;
              border-radius: 50%; */
              path {
                fill: #333;
                &:not(:first-child) {
                  stroke: #333;
                }
              }
            }
          }
          &.location {
            width: auto;
            button {
              display: flex;
              min-width: auto;
              align-items: center;
            }
          }
          button {
            background: none;
            padding: 0;
            box-shadow: none;
            display: inline-flex;
            align-items: center;
            min-width: auto;
          }
          .badge {
            background: none;
            color: #666;
            font-size: 10px;
            font-weight: 700;
            padding: 0 0 0 3px;
          }
        }
        &--collapsed {
            background: #fcfcfc;
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            /* width: 0; */
            max-width: 0;
            transition: max-width 0.4s ease-out;
            overflow: hidden;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
            height: 55px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
          }
          &--open {
            width: auto;
            z-index: 9;
            max-width: 400px;
            overflow: visible !important;
          }
        }
        .customer-geofence-tooltip {
          [tooltip] {
            &::before {
              transform: translate(30%, 10px) rotate(90deg);
            }
            &::after {
              transform: translate(10%, -20%);
            }
          }
        }
      }
    }
  }
  .estimate-info-boundary {
    background: red;
  }
  .ButtonsGroup {
    padding: 0px 45px;
    padding-bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 164px;
    background: #fff;
    padding-bottom: 20px;
    width: 99.9%;
    margin: 0;
    @media (max-width: 1024px) {
      position: static;
      padding: 0 0;
    }
    & button {
      width: 87px;
      line-height: 24px;
      color: #333333;
      font-size: 8px;
      display: inline-block;
      text-align: center;
      background: #fcfcfc;
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 0px;
      opacity: 1;
      &.disabled {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
        background: #fff;
        position: relative;
        z-index: 9;
      }
      @media (max-width: 1024px) {
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        padding: 14px 0;
        border: none;
        border-radius: 0;
        background: none;
        @media only screen and (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape) {
          padding: 4px 0;
        }
        &.disabled {
          color: #333 !important;
          font-weight: 700;
          border-bottom: 2px solid #333333;
          background: none !important;
          box-shadow: none !important;
        }
      }
    }
    & label {
      width: 100%;
      margin-bottom: 5px;
    }
    &.mobile-tabs {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
`;

export const RightsideBarWrap = styled.div`
  padding: 25px;
  @media (${breakpoints.minTablet}) and (${breakpoints.maxTabletLandscape}) {
    width: 85%;
    margin: 0 auto;
  }
  & > div:first-child {
    @media (${breakpoints.maxTabletLandscape}) {
      width: 100%;
    }
  }
  .event-logs {
    height: calc(100vh - 90px);
    &__list-heading {
      font-size: 10px;
      color: #999;
    }
    &__list-content {
      font-size: 10px;
    }
    .card {
      height: 100%;
    }
  }
  .recurring-wrapper {
    margin-top: 5px;
    /* padding-top: 15px; */
    h4 {
      padding-bottom: 15px;
      margin-bottom: 15px;
      position: relative;
      border-bottom: solid 1px ${theme.textFaded};
      text-align: center;
    }
    .invoice {
      color: ${theme.textPrimary};
      text-decoration: underline;
    }
    .text-decoration-underline {
      text-decoration: underline;
    }
  }
  @media (min-width: 1366px) {
    flex: 0 0 380px;
    background: #f9f9f9;
    max-width: 380px;
    > div {
      width: 100%;
    }
    &.company-right {
      justify-content: space-between;
      button {
        font-size: 10px;
        padding: 0;
        min-width: 140px;
        line-height: 28px;
      }
      .company-edit-label {
        font-size: 10px;
        color: #333;
        line-height: 20px;
        margin: 0;
        position: relative;
      }
      .DayPickerInput {
        input {
          width: 100%;
          margin: 0;
          padding: 10px 15px;
          background-color: #fcfcfc;
          border-radius: 5px;
          font-size: 10px;
          color: #333333;
          border: none;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
          height: 35px;
        }
      }
      .company-details-edit {
        button {
          width: 210px;
        }
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) and (min-device-pixel-ratio: 2) {
    max-width: 70%;
    flex: 1;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    flex: 1;
  }

  flex-direction: column;
  .card {
    .card-header {
      background: #333333;
      padding: 11px 20px;
      border-radius: 5px 5px 0 0;
      position: sticky;
      top: 0;
      z-index: 9;
      min-height: 48px;
      strong,
      h3 {
        width: 75%;
        color: #fff;
        margin: 3px;
        font-size: 10px;
        text-transform: uppercase;
        position: relative;
      }
      .mini-spinner {
        margin: 0 5px;
        position: absolute;
        left: 100%;
        div {
          height: 15px;
          width: 15px;
        }
      }
      button.backBtn {
        background: none;
        padding: 0;
      }
      &--light {
        background: #ffffff;
        border-bottom: 1px solid ${theme.lightBorder};
        color: ${theme.textPrimary};
        strong,
        p {
          color: ${theme.textPrimary};
        }
        h3 {
          margin-top: 2%;
        }
      }
      &.non-sticky {
        position: static;
        z-index: 1;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      .assign-crew {
        [class*='menu'] {
          & > div {
            max-height: 130px;
            overflow-y: scroll;
          }
        }
      }
      .devicoInfo {
        p {
          line-height: 25px;
        }
        .deviceInfoList {
          padding-left: 20px;
          li {
            position: relative;
            list-style: none;
            &:after {
              width: 9px;
              height: 9px;
              border: solid 1px #292929;
              content: '';
              position: absolute;
              left: -20px;
              top: 5px;
              border-radius: 50%;
            }
          }
        }
      }
      .jobrange {
        button {
          width: 100%;
          margin: 0;
          height: auto;
          padding: 10px 15px;
          background-color: #fcfcfc;
          border-radius: 5px;
          font-size: 10px;
          color: #333333;
          border: none;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
        }
      }
      .disableEnd {
        position: relative;
        &.btn {
          pointer-events: none;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
          top: 0;
          left: 0;
        }
      }
      .jobPrice button.btn.rounded-cross {
        top: 10px;
        bottom: auto;
      }
      .subtotalWrap {
        background: #f9f9f9;
        text-align: center;
        margin: 0 -18px 1rem;
        label {
          color: #333;
          font-size: 12px;
          padding: 8px 0;
        }
      }
      .remove-service-button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0px, -50%);
      }
      form {
        flex: 1;
        h3 {
          padding: 10px 18px;
          border-top: 1px solid #eee;
          font-weight: 700;
          margin: 0 -18px 5px;
          border-bottom: 1px solid #eee;
        }
        .border-area {
          border-bottom: solid 1px #e3e3e3;
          padding: 19px 20px;
          margin: 0 -18px;
        }
        .collapse.show ~ .border-area {
          border-top: 1px solid #e3e3e3;
        }
      }
      .CustomRadio {
        input {
          opacity: 0;
          & ~ label {
            & ~ span {
              width: 87px;
              line-height: 24px;
              color: #333333;
              font-size: 8px;
              display: inline-block;
              text-align: center;
              background: #fcfcfc;
              box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
              border-radius: 5px;
            }
          }
          &:checked {
            & ~ label {
              & ~ span {
                display: inline-block;
                border-radius: 5px;
                background: #333;
                transform: ${props => (props.checked ? 'translateX(88px)' : 'translateX(0px)')};
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                transition: all 150ms;
                vertical-align: top;
                color: #fff;
                font-weight: 700;
                position: relative;
              }
            }
          }
        }
      }
      h5 {
        text-align: center;
        font-size: 10px;
        color: #333333;
        margin: 0 0 20px;
      }
      .switch {
        border-bottom: 1px solid #e3e3e3;
        transform: translate3d(0, 0, 0);
        label {
          color: #333333;
          font-size: 12px;
          padding: 0 15px;
          margin: 0;
        }
        &-location {
          padding-right: 10px;
        }
      }
      p {
        padding: 0 0 10px;
        margin: 0;
        &:last-child {
          padding: 0;
        }
      }
      .DayPicker-Month {
        width: 100%;
        margin: -22px 0 0;
        .DayPicker-Day {
          font-size: 12.5px;
          color: #999 !important;
          width: 1em;
          height: 3em;
          &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background: #333;
            padding: 0;
            width: 1em;
            height: 1em;
            color: #fff !important;
          }
        }
        .DayPicker-Caption {
          text-align: center;
          & > div {
            font-weight: 400;
            font-size: 14px;
            color: #333;
          }
        }
        .DayPicker-Weekday abbr[title] {
          font-size: 10px;
          color: #333;
        }
      }
      .DayPicker-NavBar {
        display: inline-block;
        .DayPicker-NavButton {
          top: 0;
          color: #999999;
          &.DayPicker-NavButton--prev {
            left: 0;
          }
        }
      }
      .subscribed-addons {
        border-bottom: 1px solid #e3e3e3;
        font-size: 12px;
      }
    }
    .custom-design {
      &.multi-select-scroll {
        > div {
          &:first-child {
            overflow-y: scroll;
            > div:last-child {
              height: 35px;
              position: sticky;
              top: 0;
              right: 0;
            }
          }
        }
      }
      > div {
        &:first-child {
          border-radius: 5px;
          box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
          /* background-color: #fcfcfc; */
          min-height: 35px;
          border: none;
          margin-left: 0;
          /* max-height: 84px; */
          overflow: hidden;
        }
        div span {
          display: none;
        }
        & div > div:nth-child(2) input {
          box-shadow: none;
        }
        & ~ div {
          min-height: auto;
        }
      }
      > div ~ div {
        min-height: auto;
      }
    }
    .list-group {
      i {
        font-style: normal;
        text-align: center;
        font-size: 12px;
      }

      li {
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #333;
        padding: 18px 15px;
        margin: 0 0 10px;
        border-radius: 5px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        word-break: break-all;
        p {
          padding: 0;
          flex: 1;
        }
        button {
          // TODO: to be done away with after all actions buttons are replaced with custom buttons
          border-radius: 50%;
          background: #333;
          width: 20px;
          height: 20px;
          color: #fff;
          padding: 0;
          font-size: 11px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
    .another {
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background: #fff;
      font-size: 10px;
      font-weight: bold;
      color: #333333;
      line-height: 25px;
      width: 100%;
      padding: 9px 0 6px;
    }
  }
  .accountSettings__timezone {
    #indicator-container {
      display: none;
    }
  }
  .subscribed-device-details {
    ${typeScale.textSm};
    &__label {
      font-weight: bold;
      ${typeScale.textSm};
      margin-bottom: 5px !important;
      text-align: left !important;
    }
    @media (min-width: 768px) {
      height: 100%;
      .device-actions {
        display: flex;
        justify-content: center;
        align-content: flex-end;
      }
    }
    .gps-id-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      button {
        min-width: auto !important;
      }
    }
  }
`;
export const EmptyState = styled.div`
  /* this is legacy code and not to be used. Use EmptyState component inside Modules dir */
  font-size: 22px;
  color: #ccc;
  line-height: 33px;
  text-align: center;
  padding-top: 148px;
  & span {
    font-size: 16px;
  }
`;

export const CustomRadio = styled.div`
  display: flex;
  flex-wrap: wrap;
  & div {
    position: relative;
    input {
      position: absolute;
      opacity: 0;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      &:checked {
        ~ label {
          display: inline-block;
          border-radius: 5px;
          background: #fff;
          transform: ${props => (props.checked ? 'translateX(88px)' : 'translateX(0px)')};
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          transition: all 150ms;
          vertical-align: top;
          position: relative;
          z-index: 1;
        }
      }
      ~ label {
        width: 87px;
        line-height: 24px;
        color: #333333;
        font-size: 8px;
        display: inline-block;
        text-align: center;
        background: #fcfcfc;
        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
      }
    }
    &:first-child {
      label {
        border-radius: 5px 0 0 5px;
      }
    }
    &:last-child {
      label {
        border-radius: 0px 5px 5px 0px;
      }
    }
  }
`;
export const EstimateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .jobPrice {
    button {
      min-width: auto;
    }
  }
  .taxable {
    .custom-design {
      height: 38px;
      > div {
        height: 100%;
      }
    }
  }
  .selectColor {
    button {
      min-width: auto;
    }
  }
  .estimates-add-1 {
    .geosuggest__suggests-wrapper {
      width: 100%;
    }
  }
  .geosuggest__suggests-wrapper {
    position: absolute;
  }
  h5 {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #666;
  }
  .leftsideEstimate {
    padding: 50px 45px;
    flex: 1;
    h2 {
      font-size: 18px;
      margin: 0 45px 0 0;
    }
    .geosuggest__input {
      height: 30px;
      border-radius: 5px;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
      background-color: #fcfcfc;
      border: none;
      font-size: 10px;
      width: 318px;
      margin-right: 45px;
      padding: 0 30px 0 10px;
      background: url(${search}) no-repeat scroll right 10px center;
    }
  }
  .estimate-map-container {
    height: calc(100vh - 251px);
    width: 100%;
    padding: 0 35px;
    @media (max-width: 1210px) {
      padding-left: 40px;
      padding-right: 40px;
      max-height: 500px;
    }
    @media (max-device-width: 1366px) and (orientation: landscape) {
      padding-left: 40px;
      padding-right: 40px;
      max-height: 500px;
    }
  }
  @media (max-width: 1210px) {
    flex-direction: column;
    .leftsideEstimate {
      &__tool {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
    .rightsidewrap {
      display: block;
      width: 100% !important;
      height: 100% !important;
      & > div {
        position: relative !important;
      }
      .time-inline {
        width: 50%;
      }
      .address-line-1 {
        .geosuggest__suggests-wrapper {
          position: relative;
        }
      }
    }
  }
  @media (max-device-width: 1365px) and (orientation: landscape) {
    flex-direction: column;
    .leftsideEstimate {
      &__tool {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
    .rightsidewrap {
      display: block;
      width: 100% !important;
      height: 100% !important;
      @media (max-width: 1366px) {
        padding-left: 40px;
        padding-right: 40px;
      }
      & > div {
        position: relative !important;
      }
      .time-inline {
        width: 50%;
      }
      .address-line-1 {
        .geosuggest__suggests-wrapper {
          position: relative;
        }
      }
    }
  }
  .rightsidewrap > div {
    @media (max-width: 1365px) {
      overflow: auto !important;
    }
  }
`;
