import { combineReducers } from 'redux';
import authReducer from './authReducer';
import notificationReducer from './notificationReducer';
import * as actionTypes from '../actions/actionTypes';

const appReducer = combineReducers({

  auth: authReducer,
  notification:notificationReducer

});
export default (state, action) => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
