import React, { Component } from 'react';
import {
  getEmployeeInfo,
  getUserInfo,
  isUserAuthenticated,
  removeItem
} from '../services/localStorage';
import { Redirect } from 'react-router-dom';
import { errorAlert } from 'utils/alert';
import { store } from '..';
import { authLogout } from 'actions';
import { isGuestEnv } from 'utils/envUtils';
const requiredAuth = ChildComponent => {
  return class ComposedComponent extends Component {
    constructor(props) {
      super(props);
      this.shouldNavigateAway();
    }
    shouldNavigateAway = () => {
      if (!isUserAuthenticated()) {
        if (store?.dispatch) {
          store.dispatch(authLogout());
        }
        isGuestEnv() ? this.props.history.push('/login/guest') : this.props.history.push('/login');
      } else {
        // only let operators(admins) and Superadmins access the dashboard
        const userInfo = getUserInfo();
        if (userInfo) {
          const { IsCompanyVerified, SubscriptionStatusId, isAdmin, loggedAsAdmin } = JSON.parse(
            getUserInfo()
          );
          if (isAdmin || loggedAsAdmin) {
            return;
          }
          //TODO(SUBSCRIPTION)
          if (!IsCompanyVerified || SubscriptionStatusId === 1) {
            this.props.history.push('/signup');
          }
        } else if (getEmployeeInfo()) {
          errorAlert('You have been logged out of the Timeclock website');
          removeItem();
        }
      }
    };

    render() {
      if (isUserAuthenticated()) {
        //TODO(SUBSCRIPTION)
        const userInfo = getUserInfo();
        if (userInfo) {
          const { IsCompanyVerified, SubscriptionStatusId, isAdmin, loggedAsAdmin } = JSON.parse(
            getUserInfo()
          );

          if (isAdmin || loggedAsAdmin) {
            return (
              <>
                <ChildComponent {...this.props} />
              </>
            );
          }
          //TODO(SUBSCRIPTION)
          if (!IsCompanyVerified || SubscriptionStatusId === 1) {
            return <Redirect to='/signup' />;
          }
          return (
            <>
              <ChildComponent {...this.props} />
            </>
          );
        }
      }

      if (isGuestEnv()) {
        return <Redirect to='/login/guest' />;
      } else {
        if (store?.dispatch) {
          store.dispatch(authLogout());
        }

        return <Redirect to='/login' />;
      }
    }
  };
};

export default requiredAuth;
