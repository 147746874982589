import React from 'react';
import { ErrorMessage, getIn } from 'formik';
const errorMessage = (touched, errors, field) =>
  (touched[field.name] && errors[field.name] && (
    <ErrorMessage
      component='div'
      name={field.name}
      className='input-feedback'
    />
  )) ||
  (getIn(errors, field.name) && getIn(touched, field.name) && (
    <ErrorMessage
      component='div'
      name={field.name}
      className='input-feedback'
    />
  ));
export default errorMessage;
