import React from 'react';
import EventSelection from './EventSelection';
import * as actions from 'actions';

import {
  CLOCK_IN,
  SELECT_BREAK_TYPE,
  SELECT_EVENT_TYPE,
  BREAK_START,
  BREAK_END,
  LUNCH_START,
  CLOCK_OUT
} from '../../../constants';
import EventStart from './EventStart';

import SelectBreakType from './SelectBreakType';
import EventEnd from './EventEnd';
import { TimeMangementAPI } from '../TimeManagementAPI';
import { useTimeMangementState } from '../TimeMangementStateProvider';
import { errorAlert } from 'utils/alert';
import { HIDE_SUCCESS_SCREEN, RESET } from '../actionTypes';
import { getSuccessMessage } from '../utils';
import { connect } from 'react-redux';

function EventWrapper(props) {
  const [
    {
      employeeLoginInfo: { CompanyId, EmployeeId, CurrentEmployeeStatus },
      eventType,
      employeeLocationInfo
    },
    dispatch
  ] = useTimeMangementState();
  const eventSubmit = async (type, eventDetails) => {
    try {
      await TimeMangementAPI.logEvent(CompanyId, EmployeeId, {
        eventType: type,
        eventTime: eventDetails.date,
        memo: eventDetails.memo,
        lat: employeeLocationInfo ? employeeLocationInfo.lat : undefined,
        long: employeeLocationInfo ? employeeLocationInfo.long : undefined,
        caller: 'web'
      });
      //if the employee clocks out, submit the timesheet as well.
      if (type === CLOCK_OUT && CurrentEmployeeStatus) {
        await TimeMangementAPI.submitTimesheet(CompanyId, EmployeeId, CurrentEmployeeStatus.Id, {
          TimesheetStatus: 2
        });
      }
      //after sucessful action reset the state, clear the local storage and after 3 seconds of success screen, show the login screen
      dispatch({ type: RESET, data: { successMessage: getSuccessMessage(type) } });
      props.onLogout({ employeeLogout: true });
      setTimeout(() => {
        dispatch({ type: HIDE_SUCCESS_SCREEN });
      }, 3000);
    } catch (error) {
      if (error.response) {
        errorAlert(error.response?.data?.error);
      } else {
        errorAlert('Something Went Wrong!');
      }
      throw error;
    }
  };

  switch (eventType) {
    case CLOCK_IN:
      return (
        <EventStart
          label='Clock In'
          getEventDetails={eventDetails => eventSubmit(CLOCK_IN, eventDetails)}
        />
      );
    case CLOCK_OUT:
      return (
        <EventEnd
          label='Clock Out'
          getEventDetails={eventDetails => eventSubmit(CLOCK_OUT, eventDetails)}
        />
      );
    case SELECT_EVENT_TYPE:
      return <EventSelection />;
    case SELECT_BREAK_TYPE:
      return <SelectBreakType />;
    case BREAK_START:
      return (
        <EventStart
          label='Break Start'
          getEventDetails={eventDetails => eventSubmit(BREAK_START, eventDetails)}
        />
      );
    case LUNCH_START:
      return (
        <EventStart
          label='Lunch Start'
          getEventDetails={eventDetails => eventSubmit(LUNCH_START, eventDetails)}
        />
      );
    case BREAK_END:
      return (
        <EventEnd
          label='Break End'
          getEventDetails={eventDetails => eventSubmit(BREAK_END, eventDetails)}
        />
      );

    default:
      return (
        <EventStart
          label='Clock In'
          getEventDetails={eventDetails => eventSubmit(CLOCK_IN, eventDetails)}
        />
      );
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: ({ employeeLogout }) => dispatch(actions.authLogout({ employeeLogout }))
});

export default connect(null, mapDispatchToProps)(EventWrapper);
