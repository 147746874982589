import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import InputField from '../../components/FormElements/InputField';
import * as Yup from 'yup';
import APIUtil from '../../api/apiUtil';
import {
  getUserInfo,
  isUserAuthenticated,
  saveCognitoTokens,
  saveUserInfo
} from '../../services/localStorage';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { successAlert, errorAlert } from '../../utils/alert';
import { successMessages } from '../../utils/messages';
import { PrimaryButton } from '../../css/components/Modules/Buttons';
import SVGIcon from 'containers/SVGicon';
import { parse } from 'query-string';
import { PLAN_NAME, ROLES, UNSUBSCRIBED } from 'constants.js';
import { signIn } from 'aws-amplify/auth';
import { formatSessionDataOnLogin } from './helpers/formatSessionDataOnLogin';
import { currentSession } from 'services/amplify/currentSession';
import { validateEmail } from 'helpers/YUPValidations/validateEmail';
import { validatePassword } from 'helpers/YUPValidations/validatePassword';
import LoginPageLayout from './LoginPageLayout';
import history from 'history.js';
import { COGNITO_ERRORS, LIMIT_EXCEEDED, USER_NOT_FOUND } from 'services/amplify/constants';

const api = new APIUtil();
//This schema helps in validating the form.
const LoginSchema = Yup.object().shape({
  email: validateEmail(),
  password: validatePassword()
});

class Auth extends Component {
  //Form Submit Handler
  state = {
    type: 'password'
  };
  handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await signIn({
        username: values.email,
        password: values.password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH'
        }
      });
      const { idToken, accessToken } = await currentSession();
      let userInfo = { isAuthenticated: true, cognitoTokens: { idToken, accessToken } };
      saveUserInfo(userInfo);

      saveCognitoTokens({ idToken, accessToken });
      const { data } = await api.get('user/session');

      if (data?.User.RoleId === ROLES.employee.value) {
        // if an employee tries to login to web /login -> log them out🦵
        this.props.onLogout();
        setSubmitting(false);
        errorAlert('Please use H360 mobile app to Login!');
        return;
      }

      /**
       * BE changed the response and is not sending massaged data anymore
       * use formatSessionDataOnLogin to old BE login response so that minimal changes is required in authSuccess function
       * formatSessionDataOnLogin - in addition to formatting session response to replicate old login response structure, it adds cognitoTokens to the object as well
       */
      const sessionData = formatSessionDataOnLogin(data, idToken, accessToken);
      this.props.onAuth(sessionData);
      successAlert(successMessages.LOGIN_SUCCESS);

      // on login
      // if source === app =>
      //   redirect to billing and payments
      // else if connect user but source !== app
      //   show landing page
      if (sessionData?.IsAdmin) {
        this.props.history.push('/dashboard/company');
      } else if (
        sessionData?.Subscription?.Plan.Name === PLAN_NAME.CONNECT &&
        sessionData?.SubscriptionStatusId !== UNSUBSCRIBED &&
        sessionData?.IsCompanyVerified
      ) {
        // if logging in from the web browser
        this.props.history.push('/connect-landing');
      } else if (
        parse(this.props.location?.search).source === 'app' &&
        sessionData?.SubscriptionStatusId !== UNSUBSCRIBED
      ) {
        // if logging in from the app <Manage Subscriptions button>
        this.props.history.push('/dashboard/settings/billing-payments/subscription');
      } else if (parse(this.props.location.search).fromAppToQb === 'true') {
        this.props.history.push('/dashboard/quickbooks');
      } else if (parse(this.props.location.search).fromAppToOnlinePayment === 'true') {
        this.props.history.push('/dashboard/settings/online-payment');
      } else {
        this.props.history.push('/dashboard/schedule');
      }
    } catch (error) {
      setSubmitting(false);
      if (isUserAuthenticated()) {
        this.props.history.push('/login'); // prevent flash of 403 page
        this.props.onLogout(); // if session api throws error post cognito login, log user out
        if (error?.response?.data?.error) {
          errorAlert(error?.response?.data?.error);
          return;
        }
      }
      if (error?.name === USER_NOT_FOUND || error?.name === LIMIT_EXCEEDED) {
        // override cognito's default error message
        errorAlert(COGNITO_ERRORS[error.name]);
        return;
      } else if (error?.message) {
        errorAlert(error?.message);
        return;
      }
      errorAlert('Something Went Wrong!');
    }
  };

  showPasswordToggle = () => {
    this.setState(prevState => ({
      type: prevState.type === 'text' ? 'password' : 'text'
    }));
  };

  handleForgotPasswordClick = () => {
    history.push({
      pathname: `/forgot-password`,
      state: {
        from: this.props.location?.pathname
      }
    });
  };

  render() {
    const user = {
      email: '',
      password: ''
    };
    const userInfo = getUserInfo();
    let subscriptionDetails = null;
    if (userInfo) {
      const props = JSON.parse(userInfo);
      subscriptionDetails = props.subscriptionDetails;
    }
    let renderForm = (
      <Formik
        initialValues={user}
        validationSchema={LoginSchema}
        onSubmit={this.handleFormSubmit}
        render={props => {
          const { handleSubmit, isSubmitting } = props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <FormGroup className='form-group'>
                  <Field
                    type='text'
                    name='email'
                    label='Email Address*'
                    autoComplete='off'
                    component={InputField}
                    autoFocus={true}
                  />
                </FormGroup>
                <FormGroup className='form-group'>
                  <Label>Password*</Label>
                  <div className='show-password'>
                    <Field
                      type={this.state.type}
                      name='password'
                      autoComplete='off'
                      component={InputField}
                    />
                    <div className='show-password__eye-wrapper'>
                      <Input
                        id='showPassword'
                        className='show-password__eye-checkbox'
                        type='checkbox'
                        checked={this.state.type === 'text'}
                        onChange={this.showPasswordToggle}
                      />
                      <Label
                        className='show-password__eye-label d-flex align-items-right'
                        for='showPassword'
                      >
                        <span className='show-password__eye-svg'>
                          <SVGIcon
                            className='eye'
                            name='openEye'
                            width='12.947'
                            height='7.78'
                            fill='#333'
                          />
                        </span>
                      </Label>
                    </div>
                  </div>
                </FormGroup>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='forget-password justify-content-end d-flex ms-auto'>
                    <Button
                      id='forgotPassword'
                      color='link'
                      type='button'
                      onClick={this.handleForgotPasswordClick}
                    >
                      Forgot Password?
                    </Button>
                  </div>
                </div>
                <div className='submit'>
                  <PrimaryButton
                    id='loginButton'
                    type='submit'
                    disabled={isSubmitting}
                    modifiers={'medium'}
                  >
                    Log in
                  </PrimaryButton>
                </div>
              </Form>
            </>
          );
        }}
      />
    );
    let authRirect = null;
    if (this.props.isAuthenticated && isUserAuthenticated()) {
      if (
        parse(this.props.location.search).source === 'app' &&
        subscriptionDetails.SubscriptionStatusId !== UNSUBSCRIBED
      ) {
        // if logging in from the app <Manage Subscriptions button>
        this.props.history.push('/dashboard/settings/billing-payments/subscription');
      } else if (parse(this.props.location.search).fromAppToQb === 'true') {
        authRirect = <Redirect to='/dashboard/quickbooks' />;
      } else if (parse(this.props.location.search).fromAppToOnlinePayment === 'true') {
        authRirect = <Redirect to='dashboard/settings/online-payment' />;
      } else authRirect = <Redirect to='/dashboard' />;
    }
    // if (this.state.forgotPassword) {
    //   renderForm = (
    //     <ForgotPassword className='text-center' forgotPasswordToggle={this.forgotPasswordToggle} />
    //   );
    // }

    return (
      <LoginPageLayout header={'Welcome!'}>
        {authRirect}
        {renderForm}
      </LoginPageLayout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onAuth: token => dispatch(actions.authSuccess(token)),
  onLogout: () => dispatch(actions.authLogout())
});

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
