import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bool, func, string, number, node, oneOfType, oneOf } from 'prop-types';
import { isAndroid, isIOS, isTablet, isChrome, isSafari } from 'react-device-detect';
import { FormModalContext } from 'context';
import {
  PrimaryButton,
  ButtonAsIcon,
  SecondaryButton,
  GhostButton
} from 'css/components/Modules/Buttons';
import { isGuestEnv } from 'utils/envUtils';

const getVariantClassName = variant => {
  switch (variant) {
    case 'full-screen':
      return 'common-modal-fullscreen scheduleModal full-size-modal text-start';

    default:
      return '';
  }
};

const CommonModal = props => {
  const context = useContext(FormModalContext);
  const loggedAsAdmin = context ? context.loggedAsAdmin : '';

  let closeBtn = (
    <ButtonAsIcon
      className='close'
      onClick={() => props.toggle(true)}
      data-testid='component-Button'
    >
      <img className='img-fluid' src={require('../../../images/cross.png')} alt='' />
    </ButtonAsIcon>
  );
  let modalWrapperClass;

  if (typeof loggedAsAdmin === 'undefined') {
    modalWrapperClass = '';
  } else {
    if (loggedAsAdmin === 1 || isGuestEnv()) {
      modalWrapperClass = ' admin-login ';
    } else {
      modalWrapperClass = ' company-login ';
    }
    if (isTablet && isAndroid) {
      modalWrapperClass += ' tab-android';
    } else if (isTablet && isIOS && isChrome) {
      modalWrapperClass += ' tab-ios tab-chrome ';
    } else if (isTablet && isIOS && isSafari) {
      modalWrapperClass += ' tab-ios tab-safari ';
    }
  }
  if (props.wrapClassName) {
    modalWrapperClass += props.wrapClassName;
  }

  const className = `${props.className} ${getVariantClassName(props.variant)}`;

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        wrapClassName={`${loggedAsAdmin ? 'modal-admin' : 'modal-operator'} ${modalWrapperClass}`}
        toggle={props.toggle}
        className={className}
        backdrop={props.backdrop}
        data-testid='component-CommonModal'
        keyboard={!props.disableKeyboard} //for disabling the keyboard actions
        zIndex={props.zIndex ? props.zIndex : '1050'}
      >
        {!props.noHeader ? (
          <ModalHeader toggle={props.toggle} close={closeBtn} className={props.styleClass}>
            {props.title}
          </ModalHeader>
        ) : null}

        <ModalBody className={props.bodyClassName}>{props.children}</ModalBody>

        {(props.onCancel || props.onSave || props.onApply || props.onClose) && (
          <ModalFooter>
            {props.onCancel && (
              <SecondaryButton onClick={props.onCancel} disabled={props.isDisable}>
                {props.cancelButtonText || 'Cancel'}
              </SecondaryButton>
            )}

            {props.onSave && (
              <GhostButton onClick={props.onSave} disabled={props.isDisable}>
                Save
              </GhostButton>
            )}
            {props.onApply && (
              <PrimaryButton modifiers='small' onClick={props.onApply}>
                Apply
              </PrimaryButton>
            )}
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
CommonModal.propTypes = {
  isOpen: bool.isRequired,
  toggle: func.isRequired,
  className: string,
  backdrop: oneOfType([bool, oneOf(['static'])]),
  onCancel: func,
  onSave: func,
  noCloseButton: bool,
  isDisable: bool,
  noHeader: bool,
  isAdmin: number,
  title: string,
  wrapClassName: string,
  styleClass: string,
  children: node,
  bodyClassName: string,
  disableKeyboard: bool,
  onApply: func,
  zIndex: number,
  cancelButtonText: string,
  variant: oneOf(['full-screen'])
};
export default React.memo(CommonModal);
