import React, { useEffect } from 'react';
import EmployeeLogin from './EmployeeLogin';
import { useTimeMangementState } from './TimeMangementStateProvider';
import { getEmployeeInfo, getUserInfo } from 'services/localStorage';
import { LOGIN_SUCCESS, SET_EMPLOYEE_LOCATION_INFO } from './actionTypes';
import { AuthHeader } from '../../css/components/Modules/Headings';
import EventWrapper from './Events';
import { Logo, Wrapper } from 'css/components/Modules/login';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { TimeClockWrapper } from 'css/components/Layout/TimeClockWrapper';
import moment from 'moment-timezone';
import SVGIcon from 'containers/SVGicon';
import { errorAlert } from 'utils/alert';
import { useHistory } from 'react-router';
import { CLOCK_IN } from '../../constants';

export default function TimeMangementWrapper() {
  let history = useHistory();
  const [state, dispatch] = useTimeMangementState();
  useEffect(() => {
    //hydrating state from LS.
    if (getEmployeeInfo()) {
      const { employeeInfo, eventType, eventGoingOn } = JSON.parse(getEmployeeInfo());
      dispatch({
        type: LOGIN_SUCCESS,
        data: { employeeLoginInfo: employeeInfo, eventType, eventGoingOn }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (state.employeeLoginInfo) {
      //fecthing employee location and saving to state
      navigator.geolocation.getCurrentPosition(loc => {
        if (loc.coords.latitude || loc.coords.longitude) {
          dispatch({
            type: SET_EMPLOYEE_LOCATION_INFO,
            data: {
              employeeLocationInfo: {
                lat: loc.coords.latitude,
                long: loc.coords.longitude
              }
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.employeeLoginInfo]);
  useEffect(() => {
    //if an operator is logged in and tries to access /timeclock, don't let.
    if (getUserInfo()) {
      errorAlert('Please logout from H360 platform to access Timeclock website');
      history.push('/dashboard');
    }
  });

  let successScreen = null;
  if (state.showSuccessScreen) {
    successScreen = (
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div className='mb-4'>
          <AuthHeader>{state.successMessage}</AuthHeader>
        </div>
        <SVGIcon name='completed' width='35' height='35' />
      </div>
    );
  }

  let timeManagementUI;

  if (!state.employeeLoginInfo) {
    timeManagementUI = <EmployeeLogin />;
  } else {
    timeManagementUI = <EventWrapper />;
  }

  return (
    <Wrapper className='employee-flow'>
      <Row className='login-row login-row--employee'>
        <Col xs={12} md={4} className='login-lhs'>
          <Logo padding={'40px 50px'} className='mb-0'>
            <span>
              <img className='img-fluid' src={require('../../images/logo_dark.svg').default} alt='' />
            </span>
            {state.employeeLoginInfo && state.eventType !== CLOCK_IN ? (
              <div className='employee-details'>
                <p>Company Name: {state.employeeLoginInfo.CompanyName}</p>
                <p>
                  Employee Name:{' '}
                  {`${state.employeeLoginInfo.FirstName} ${state.employeeLoginInfo.LastName}`}
                </p>
                {state.employeeLoginInfo.CurrentEmployeeStatus ? (
                  <p>
                    Clock In Date:{' '}
                    {moment(state.employeeLoginInfo.CurrentEmployeeStatus.ClockInTime).format(
                      'MM/DD/YY'
                    )}
                  </p>
                ) : null}

                {state.employeeLoginInfo.CurrentEmployeeStatus ? (
                  <p>
                    Clock In Time:{' '}
                    {moment(state.employeeLoginInfo.CurrentEmployeeStatus.ClockInTime).format('LT')}
                  </p>
                ) : null}

                {state.employeeLoginInfo.CurrentEmployeeStatus &&
                  state.employeeLoginInfo.CurrentEmployeeStatus.BreakTime && (
                    <p>
                      Break Start Date:{' '}
                      {moment(state.employeeLoginInfo.CurrentEmployeeStatus.BreakTime).format(
                        'MM/DD/YY'
                      )}
                    </p>
                  )}
                {state.employeeLoginInfo.CurrentEmployeeStatus &&
                  state.employeeLoginInfo.CurrentEmployeeStatus.BreakTime && (
                    <p>
                      Break Start Time:{' '}
                      {moment(state.employeeLoginInfo.CurrentEmployeeStatus.BreakTime).format('LT')}
                    </p>
                  )}
              </div>
            ) : null}
          </Logo>
        </Col>
        <Col xs={12} md={8} className='d-flex flex-column justify-content-center auth-col'>
          <TimeClockWrapper>{successScreen ? successScreen : timeManagementUI}</TimeClockWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
}
