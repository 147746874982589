import styled from 'styled-components';
import { theme, typeScale } from '../../utils';
import { applyStyleModifiers } from 'styled-components-modifiers';

const Link_As_Button_MODIFIERS = {
  ghostButton: () => `
  a{
    background: white;
    color: ${theme.textPrimary} !important;
    &:hover {
      color: ${theme.textInverted} !important;
      background: ${theme.neutralPrimary};
    }
    &:focus {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
    }
  }
    `,
  backButton: () => `
  a {
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15) !important;
    color: ${theme.textPrimary};
    ${typeScale.textSm};
    position: relative;
    z-index: 999;
    min-width: 80px;
    border: none;
    padding: 10px 25px !important;
    text-decoration: none;
    background: transparent;
    @media (max-width: 1024px) {
      font-size: 8px;
      z-index: 0;
    }
    svg {
      margin-right: 6px;
    }
    &:hover {
      background: ${theme.neutralPrimary};
      path {
        stroke: ${theme.textInverted};
        fill: ${theme.textInverted};
      }
    }
  }`,
  large: () => `
  ${typeScale.textLg};
  padding: 12px 50px !important;
  `,
  medium: () => `
    ${typeScale.textMd};
    padding: 10px 45px !important;
  `,
  small: () => `
    ${typeScale.textSm};
    padding: 8px 20px !important;
  `
};
export const LinkAsButtonWrapper = styled.div`
  // xxxxxxxxxxxxxxxxxxxx ⚠️ ☠️ ⚠️ -- DEPRECATED -- use LinkAsPrimaryButton/LinkAsSecondaryButton etc. instead ⚠️ ☠️ ⚠️ xxxxxxxxxxxxxxxxxxxxxx
  a {
    display: inline-block;
    border-radius: 5px;
    letter-spacing: 0.2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    ${typeScale.textSm};
    font-weight: bold;
    background: ${theme.neutralPrimary};
    padding: 8px 35px;
    color: ${theme.textInverted};
    transition: box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.57, 0.89);
    &:hover {
      color: ${theme.textInverted};
      box-shadow: 3px 8px 5px 0 rgba(0, 0, 0, 0.3);
    }
    &:focus {
      /* color: ${theme.neutralPrimary};
    background: ${theme.textInverted}; */
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
  }
  ${applyStyleModifiers(Link_As_Button_MODIFIERS)};
`;

export const LinkAsPrimaryButton = styled.a`
  display: inline-block;
  border-radius: 5px;
  letter-spacing: 0.2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  ${typeScale.textSm};
  font-weight: bold;
  padding: 8px 35px;
  color: ${theme.buttonText};
  transition: box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.57, 0.89);
  background: ${theme.primary};
  min-width: 110px;
  &:focus {
    background: #fff !important;
    color: #333 !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15) !important;
  }

  &:hover {
    color: ${theme.textInverted};
    box-shadow: 3px 8px 5px 0 rgba(0, 0, 0, 0.3);
  }
  ${applyStyleModifiers(Link_As_Button_MODIFIERS)};
`;

export const LinkAsSecondaryButton = styled.a`
  display: inline-block;
  border-radius: 5px;
  letter-spacing: 0.2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  ${typeScale.textSm};
  font-weight: bold;
  background: ${theme.neutralPrimary};
  padding: 8px 35px;
  color: ${theme.textInverted};
  transition: box-shadow 0.3s cubic-bezier(0.17, 0.67, 0.57, 0.89);
  &:hover {
    color: ${theme.textInverted};
    box-shadow: 3px 8px 5px 0 rgba(0, 0, 0, 0.3);
  }
  &:focus {
    /* color: ${theme.neutralPrimary};
    background: ${theme.textInverted}; */
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  ${applyStyleModifiers(Link_As_Button_MODIFIERS)};
`;

export const UnderlinedLinkWrapper = styled.div`
  a {
    color: ${theme.iconGrey};
    ${typeScale.textMd};
    text-decoration: underline;
  }
`;
