import moment from 'moment-timezone';

/* global google */
export const validateLocation = (address, sendCompleteAddress) => {
  return new Promise((resolve, reject) => {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(address, function (results, status) {
      if (
        status === google.maps.GeocoderStatus.OK &&
        results.length > 0 &&
        !results[0].partial_match
      ) {
        if (sendCompleteAddress) {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            address: results[0]
          });
        } else {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          });
        }
      } else {
        resolve({
          lat: undefined,
          lng: undefined
        });
      }
    });
  });
};

/**
 *
 * @param {list} ServiceLocations "take service locations"
 * @returns {bool}
 */
export const activeLocations = ServiceLocations => {
  let activeLocationCount = 0;
  ServiceLocations.forEach(location => {
    if (location.IsActive && location.LocationType !== 'billing') {
      //don't consider billing location as it will always be active
      activeLocationCount = activeLocationCount + 1;
    }
  });
  return activeLocationCount;
};
/**
 *
 * @param {object} customerData - contains customer data
 * @returns url for redirection to analytics in finance tab with customer selected
 */
export const analyticsFinanceCustomerUrlFormatter = (customerData = {}, pageNumber) => {
  let name = '';
  if (customerData.Billing) {
    if (customerData.Billing.AddressType === 'commercial') {
      name = customerData.BusinessName;
    } else if (customerData.FirstName || customerData.LastName) {
      name = `${customerData.FirstName} ${customerData.LastName}`;
    }
  }
  if (name === '') {
    name = customerData.BusinessName || `${customerData.FirstName} ${customerData.LastName}`;
  }
  const selectedDateRange = {
    start: moment().subtract(6, 'd').startOf('day').utc().format(),
    end: moment().endOf('day').utc().format()
  };
  const Locations =
    customerData && customerData.ServiceLocations
      ? customerData.ServiceLocations.map(loc => {
          return {
            label: loc.AddressLine1,
            value: loc.LocationId,
            customerId: customerData.CustomerId
          };
        })
      : [];
  const selectedCustomer = [
    {
      label: name,
      value: customerData.CustomerId,
      Locations
    }
  ];
  const customerLocations = [{ label: name, options: Locations }];
  return `/dashboard/analytics/tabs/2?customers=${encodeURIComponent(
    JSON.stringify(selectedCustomer)
  )}&customerLocations=${encodeURIComponent(
    JSON.stringify(customerLocations)
  )}&selectedDateRange=${encodeURIComponent(
    JSON.stringify(selectedDateRange)
  )}&isFromCustomerSection=true&pageNumber=${pageNumber}`;
};

export const getCustomerName = customerObj => {
  const { AddressType, FirstName, LastName, BusinessName, Type } = customerObj || {};
  let customerName = '';
  if (AddressType === 'commercial' || Type === 'commercial') {
    if (BusinessName) {
      customerName = BusinessName;
    } else {
      customerName = `${FirstName ? FirstName + ' ' : ''} ${
        LastName ? LastName : ''
      }`;
    }
  } else {
    if (FirstName || LastName) {
      customerName = `${FirstName ? FirstName + ' ' : ''} ${
        LastName ? LastName : ''
      }`;
    } else {
      customerName = BusinessName;
    }
  }

  return customerName;
};
/**
 *
 * @param {object} selectedCustomers
 * @param {list} customerList
 * @returns list
 */
export const getCustomerDetailsForAddButtons = (selectedCustomers = {}, customerList = []) => {
  const keys = Object.keys(selectedCustomers);
  const filteredCustomers = keys.filter(key => selectedCustomers[key]);
  if (filteredCustomers.length !== 1) {
    return [];
  }
  const filteredActiveCutomerDetails = customerList.filter(
    customer => customer.CustomerId === filteredCustomers[0] && customer.IsActive
  );
  return filteredActiveCutomerDetails;
};

export const isAddButtonDisabled = (selectedCustomers = {}, customerList = []) => {
  const keys = Object.keys(selectedCustomers);
  const filteredCustomers = keys.filter(key => selectedCustomers[key]);

  if (
    customerList.filter(
      customer => customer.CustomerId === filteredCustomers[0] && customer.IsActive
    )
  ) {
    return false;
  } else return true;
};
