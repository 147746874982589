export const red = {
  100: '#cc0000',
  200: '#b62a2a',
  400: '#cc2f2f',
  700: '#A20000'
};
export const neutral = {
  100: '#333333',
  200: '#3F3F3F',
  300: '#999999',
  400: '#F9F9F9',
  500: '#292929',
  600: '#CCCCCC',
  700: '#FCFCFC',
  800: '#FFFFFF',
  900: '#666666',
  1000: '#F2F2F2',
  1100: '#e9ecef',
  1200: '#e3e3e3'
};
export const green = {
  100: '#51b37f',
  200: '#36b363'
};
export const blue = {
  100: ' #004eff'
};

export const orange = {
  400: '#faac4d'
};

export const yellow = {
  400: '#F0C427'
};

export const lightGrey = {
  100: '#F8F8F8'
}