export const successMessages = {
  LOGIN_SUCCESS: 'You have been successfully logged in!',
  GUEST_LOGIN_SUCCESS: 'You have been successfully logged in as a guest user with limited access'
};

export const ErrorMessages = {
  INVALID_CREDENTIALS: 'Invalid Credential. Please try again.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  DRAG_STARTED_JOB_ERROR: 'Unable to drag a job In Progress. Edit the job to change its status.',
  DRAG_PAUSED_JOB_ERROR: 'Unable to drag a paused job. Edit the job to change its status.',
  DRAG_COMPLETED_JOB_IN_FUTURE: 'Completed jobs cannot be moved to a future date.',
  DRAG_JOB_IN_PAST: `Jobs cannot be schedule in the past`,
  DRAG_ROUTE_IN_PAST: `Routes cannot be schedule in the past`,
  DRAG_STARTED_ROUTE_ERROR: 'Unable to drag a route In Progress.',
  DRAG_COMPLETED_ROUTE_IN_FUTURE: 'Completed routes cannot be moved to previous/future dates',
  DRAG_ODJ_OUTSIDE_SERVICE_WINDOW:
    'OnDemand job cannot be scheduled on a date outside the service window of the OnDemand job'
};
