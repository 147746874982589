import styled from 'styled-components';
import ODJ_Icon from '../../images/ODJ_Icon.svg';
import { theme } from 'css/utils';
export const Wrapper = styled.section`
  .row {
    height: 100%;
    & > [class*='col'] {
      padding: 0 40px;

      @media (max-width: 1399px) {
        padding-right: 2vw;
        &:nth-child(2) {
          padding-right: 3vw;
          padding-left: 2vw;
        }
      }
    }
  }
  .employee {
    border-right: 1px solid #e3e3e3;
    @media (max-width: 1024px) {
      border: none;
    }
    &.customer-col {
      .sticky-btn {
        margin-bottom: 46px !important;
      }
    }
  }
  .tab-content {
    width: 100%;
    display: flex;
    padding: 0;
    .tab-pane {
      display: flex;
      flex: 1;
      padding-top: 50px;
      @media (max-width: 1024px) {
        padding-top: 0;
      }
      .col-md-12 {
        padding: 0 45px;
        @media (max-width: 1024px) {
          padding: 0 15px;
        }
      }
      @media (max-width: 1024px) {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .nav-tabs .nav-item {
      flex: 1;
      text-align: center;
    }
  }
`;
export const ToggleWrap = styled.div`
  /* border-top: 1px solid #e3e3e3; */
  /* border-bottom: 1px solid #e3e3e3; */
  /* margin: 0 -18px; */
  transition: 0.6s;
  cursor: pointer;
  svg {
    transition: 0.6s;
  }
  &.open {
    border-bottom: none;
    button svg {
      transform: rotate(180deg);
      transition: 0.6s;
    }
  }
  strong {
    font-size: 10px;
    color: #333;
    padding: 14px 0px;
    text-transform: uppercase;
    & ~ .ColorPicker {
      margin: 0 0 0 auto;
    }
  }
  button {
    border: none;
    background: none;
    padding-right: 0px;
    outline: none;
  }
  h3.accordion-heading {
    margin-left: 0 !important;
    border-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
  }
`;
export const JobsStatus = styled.div`
  text-align: center;
  background: #fff;
  width: 100%;
  padding: 0 20px;
  height: calc(100vh - 108px);
  /* border: solid; */
  @media (max-width: 1024px) {
    height: 100%;
  }
  & ${ToggleWrap} {
    border-width: 2px;
    border-top: none;
    &.open {
      border-top: 1px solid #e3e3e3;
    }
    &.collapsed-job {
      border-bottom: 1px solid #e3e3e3;
      &.open {
        border-top: 1px solid #e3e3e3;
        border-bottom: 0;
      }
    }
  }

  .collapse,
  .collapsing {
    p {
      padding: 10px 0;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
      font-size: 10px;
      color: #333;
    }
    .list-group-item {
      border-radius: 5px;
      margin: 0 0 10px;
      border: none;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
      p {
        padding: 0;
        box-shadow: none;
        margin: 0;
        text-align: left;
      }
      &.empty-list {
        background: none;
        font-weight: 700;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
        padding: 8px 0 10px;
        pointer-events: none;
        p {
          background: none;
          color: #333;
          text-align: center;
        }
      }
      .pendingJobs {
        .jobsName {
          flex: 1;
          font-size: 10px;
          padding: 5px 0;
          word-break: break-all;
          .tooltip-wrapper__tip {
            font-size: 10px;
            color: #333;
          }
          &--odj {
            position: relative;
            &:before {
              content: '';
              background-image: url(${ODJ_Icon});
              background-size: 23px 20px;
              display: inline-block;
              width: 23px;
              height: 20px;
              position: absolute;
              background-repeat: no-repeat;
              left: -18px;
              top: 2px;
            }
          }
        }
        p {
          color: #333333;
        }
        span {
          color: #666;
        }
        svg {
          /* margin: 0 9px 0 12px; */
          width: 1.3vw;
          @media (max-width: 1024px) {
            width: 20px;
          }
        }
      }
    }
  }
  .unscheduled-route-gradient {
    position: relative;
    border-left: 0 !important;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 7px;
      height: 100%;
      background: repeating-linear-gradient(45deg, #f6f6f6, #f6f6f6 6px, #ccc 3px, #ccc 12px);
    }
    svg {
      width: 16px;
    }
  }
  .calendarRight {
    svg {
      width: 16px !important;
    }
  }
  .edit {
    cursor: pointer;
    &:hover svg path {
      fill: #333;
    }
  }
  .btn-secondary {
    width: 120px;
    height: 30px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6.3px;
    font-weight: 700;
    font-size: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  button {
    box-shadow: none;
    min-width: auto;
  }
`;
export const CalendarPopover = styled.div`
  overflow: hidden;
  position: relative;
  .schedule-popover {
    &__history-content {
      font-size: 10px;
      border: 1px solid ${theme.iconDivider};
      border-radius: 5px;
      color: #333;
      background: ${theme.inputBackground};
      [class*='col'] {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    &__table-data {
      border-top: 1px solid ${theme.iconDivider};
    }
    &__history-row {
      margin-left: -15px;
      margin-right: -15px;
    }
    &__table-header {
      color: #999999;
    }
    &__history-col {
      padding-left: 6px;
      padding-right: 6px;
      &--op {
        width: 37%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--status {
        width: 25.5%;
      }
      &--date {
        width: 37.5%;
      }
    }
    &__close {
      border: none;
      position: absolute;
      display: flex;
      width: auto;
      align-items: flex-end;
      justify-content: flex-end;
      top: 10px;
      background: none;
      right: 8px;
      cursor: pointer;
    }
  }
  .popover-header {
    background: none;
    font-size: 10px;
    font-weight: bold;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    font-weight: normal !important;
    justify-content: space-between;
    width: 100%;
    padding-right: 35px;
    svg {
      cursor: pointer;
    }
  }
  .popover-body {
    padding-right: 15px;
    .left {
      padding: 18px 0px;
    }
    p {
      font-size: 10px;
      margin: 0 0 2px;
      color: #333;
    }
    .edit {
      cursor: pointer;
    }
    h2 {
      font-size: 11px;
      font-weight: 400;
      color: #333333;
      margin: 0 0 5px;
    }
    h3 {
      /* border-radius: 3.9px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1); */
      font-size: 10px;
      color: #333333;
      display: inline-block;
      /* padding: 10px; */
      /* margin: 0 0 10px; */
      width: 100%;
      white-space: pre-wrap !important;
    }
    .demarcation {
      border-top: solid 1px ${theme.iconDivider};
    }
  }
`;
export const WrapperCalendar = styled.div`
  padding: 20px 30px;
  .rbc-addons-dnd .rbc-addons-dnd-resizable {
    min-height: 52px;
    background: #fff;
  }
  @media (max-width: 1024px) {
    padding: 10px 15px;
  }
  /* .rbc-time-view {
        height: calc(100vh - 386px);
        overflow-y: scroll;
    } */
  /* .rbc-time-content{
        overflow-y: initial;
        width:100%;
    } */
  .blue {
    .rbc-time-slot {
      border: none;
    }
    .rbc-timeslot-group {
      border-bottom: none;
    }
  }
  .rbc-month-view {
    height: calc(100vh - 150px);
    overflow-y: auto;
    .rbc-month-row {
      overflow: visible;
    }
  }
  .rbc-overflowing {
    border-right: none !important;
  }
  .rbc-date-cell.rbc-now.rbc-current {
    background: #f9f9f9;
    border-right: 1px solid #ddd;
    a {
      background: #333333;
      color: #fff;
      border-radius: 50%;
      width: 30px;
      line-height: 26px;
      position: relative;
      padding: 0 0 5px;
      display: inline-block;
      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        background: #fff;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: auto;
      }
    }
  }
  .calendarheader {
    align-items: flex-end;
    margin: 0 0 10px;
    flex-flow: row-reverse;
    justify-content: flex-end;
    display: flex;
    @media (max-width: 1024px) {
      align-items: center;
    }
    .form-group {
      margin: 0 30px 0 0;
      flex: 0 0 24%;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .custom-design {
      width: 325px;
      z-index: 999;
    }
    .RangeExample {
      button {
        background: #fff;
        color: #333;
        box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .rbc-calendar {
    height: calc(100vh - 290px);
    @media (max-width: 1024px) {
      display: none;
    }
    .rbc-addons-dnd-resize-ns-anchor,
    .rbc-addons-dnd-resize-ew-anchor {
      pointer-events: none;
      display: none;
    }
    .rbc-timeslot-group {
      min-height: 60px;
    }
    .rbc-date-cell {
      font-size: 10px;
      color: #666;
      text-align: center;
      padding: 10px 0;
    }
    .rbc-current-time-indicator {
      background: #cc0000;
      &:after {
        content: '';
        position: absolute;
        left: -4px;
        width: 7px;
        height: 7px;
        margin: auto;
        background: #cc0000;
        top: 0;
        bottom: 0;
        border-radius: 50%;
      }
    }
    .rbc-current-time-indicator {
      background: #cc0000;
      &:after {
        content: '';
        position: absolute;
        left: -4px;
        width: 7px;
        height: 7px;
        margin: auto;
        background: #cc0000;
        top: 0;
        bottom: 0;
        border-radius: 50%;
      }
    }
    .rbc-btn-group {
      box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
      button {
        font-size: 8px;
        color: #999999;
        box-shadow: none;
        background: #f5f5f5;
        min-width: auto;
        border: none;
        padding: 5px 10px;
        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
        &:hover {
          background: #fff;
        }
        &.rbc-active {
          border-radius: 4px;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
        }
      }
    }
    .rbc-toolbar-label {
      font-size: 10px;
      color: #333;
      font-weight: 400;
    }
    .rbc-allday-cell {
      margin-top: 34px;
    }
    .rbc-time-header-cell {
      padding: 0;
      background: #666666;
      min-height: inherit;
      position: absolute;
      width: calc(100% - 132px);
      z-index: 5;
      @-moz-document url-prefix() {
        width: calc(100% - 137px);
      }
    }
    .rbc-header {
      background: #666666;
      overflow: visible;
      display: table-cell;
      justify-content: center;
      align-items: center;
      border: none;
      color: #fff;
      border-right: 1px solid #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 7px;
      &:last-child {
        border-right: none;
      }
      span {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        padding: 6.5px;
      }
    }
    .rbc-timeslot-group,
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      font-size: 10px;
      color: #333;
    }
    .rbc-event-content {
      border-radius: 5px;
      position: relative;
      height: 100%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
      & > div {
        min-height: 52px;
        overflow: hidden;
        & .d-flex {
          justify-content: center;
        }
      }
      & .notifDot {
        min-height: auto;
      }
      p {
        font-size: 10px;
        padding: 0 5px 0 24px;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        &.warning {
          padding-left: 0;
          width: auto;
        }
      }
      svg {
        margin-left: 5px;
        margin-top: 3px;
        margin-right: 5px;
      }
    }
    .rbc-show-more {
      font-size: 10px;
      color: #333;
      font-weight: 600;
      padding: 5px;
    }
    .rbc-event {
      background: #fff;
      color: #333;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
      border: none;
      font-size: 10px;
      padding: 0;
      overflow: visible;
      min-height: 52px;
      &.rbc-addons-dnd-dragged-event,
      &.rbc-addons-dnd-drag-preview {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
      }
      &.rbc-selected {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        outline: none;
        min-height: 52px;
      }
      .rbc-event-label {
        font-weight: 700;
        position: absolute;
        padding-left: 32px;
        padding-top: 2px;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const HistoryWrapper = styled.section`
  .history-block {
    &__heading {
      font-size: 10px;
      font-weight: bold;
    }
    &__t-header {
      font-size: 10px;
      color: #999;
    }
    &__t-body {
      font-size: 10px;
      color: #292929;
    }
  }
`;
