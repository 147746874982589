import React from 'react';
import moment from 'moment-timezone';

import { func, string } from 'prop-types';
import { AuthHeader } from 'css/components/Modules/Headings';
import { PrimaryButton } from 'css/components/Modules/Buttons';
import { LoginForm } from 'css/components/Modules/login';
import getTimeObject from 'helpers/getTimeObject';
import useInput from 'custom_hooks/useInput';
export default function EventEnd({ label, getEventDetails }) {
  const [inputValue, setInput] = useInput('');
  return (
    <LoginForm>
      <div className='event-end login-form-container'>
        <div className='text-center mb-4'>
          <AuthHeader>{label === 'Break End' ? 'Confirm Time' : label}</AuthHeader>
        </div>
        <div className='form-group'>
          <label>{label} Date*</label>
          <div className='event-end__datepicker'>
            <input readOnly value={moment().format('MM/DD/YY')} disabled={true} />
          </div>
        </div>
        <div className='form-group clock-wrapper'>
          <label>{label} Time*</label>
          <input readOnly value={moment().format('hh:mm A')} disabled={true} />
        </div>
        <div className='form-group clock-wrapper employee-block-mb'>
          <label>Memo</label>
          <input
            value={inputValue}
            type='text'
            onChange={setInput}
            placeholder='Add a memo (upto 100 characters)'
            maxLength={100}
          />
        </div>
        <div className='text-center mt-auto mt-md-0 event-end__button'>
          <PrimaryButton
            onClick={() => {
              const { hours, minutes } = getTimeObject(moment().format('hh:mm A'));
              const date = moment().startOf('day').add({ hours, minutes }).utc().format();
              getEventDetails({ date, memo: inputValue });
            }}
          >
            {label === 'Clock Out' ? 'Clock Out and Submit Timesheet' : 'Confirm'}
          </PrimaryButton>
        </div>
      </div>
    </LoginForm>
  );
}
EventEnd.propTypes = {
  label: string.isRequired,
  getEventDetails: func.isRequired
};
