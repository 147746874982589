import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form, FormGroup, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { resetPassword } from 'aws-amplify/auth';
import { useLocation } from 'react-router-dom';

import { errorAlert, successAlert } from 'utils/alert';
import { ForgetPassword } from 'css/components/Modules/login';
import InputField from 'components/FormElements/InputField';
import { PrimaryButton } from 'css/components/Modules/Buttons';
import history from 'history.js';
import { validateEmail } from 'helpers/YUPValidations/validateEmail';
import LoginPageLayout from '../LoginPageLayout';
import { COGNITO_ERRORS, LIMIT_EXCEEDED, USER_NOT_FOUND } from 'services/amplify/constants';

const FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
  email: validateEmail()
});

const ForgotPasswordInitialStep = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const loginRoute = location.state?.from || '/login';

  const handleSubmit = async values => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const payload = {
        username: values.email
      };

      const { nextStep } = await resetPassword(payload);
      setIsLoading(false);

      if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        successAlert('A link has been sent on the registered email to reset password.');

        history.push({
          pathname: `/user/reset-password`,
          state: {
            username: payload.username,
            from: loginRoute
          }
        });
      } else {
        throw Error('Something went wrong!');
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name === USER_NOT_FOUND || error?.name === LIMIT_EXCEEDED) {
        // override cognito's default error message
        errorAlert(COGNITO_ERRORS[error.name]);
        throw error;
      }
      errorAlert('Something went wrong!');
    }
  };

  const handleBackToLoginClick = () => {
    history.push(loginRoute);
  };

  const initialValues = {
    email: ''
  };

  const isFromEmployeeLogin = location.state?.from === '/timeclock';

  return (
    <LoginPageLayout header='Reset Password' isEmployeeLogin={isFromEmployeeLogin}>
      <ForgetPassword data-test='component-forgotPassword'>
        <h2>Enter an email and we’ll send you a verification code to reset your password.</h2>

        <Formik
          data-test='component-forgotPassword-formik'
          initialValues={initialValues}
          validationSchema={FORGOT_PASSWORD_SCHEMA}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => {
            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className='form-group'>
                    <Field
                      type='text'
                      name='email'
                      component={InputField}
                      placeholder='Email'
                      label='Email Address*'
                      autoFocus={true}
                    />
                  </FormGroup>

                  <div className='text-center mt-5'>
                    <PrimaryButton type='submit'>
                      {isLoading ? <Spinner size='sm' /> : 'Send Code'}
                    </PrimaryButton>
                  </div>
                </Form>
              </>
            );
          }}
        />

        {/* TODO: Update the button UI */}
        <Button
          data-test='component-forgotPassword-button'
          color='link'
          className='done'
          type='button'
          onClick={handleBackToLoginClick}
        >
          Login
        </Button>
      </ForgetPassword>
    </LoginPageLayout>
  );
};

ForgotPasswordInitialStep.propTypes = {
  forgotPasswordToggle: PropTypes.func
};

export default ForgotPasswordInitialStep;
