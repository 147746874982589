import { theme } from 'css/utils';
import { bool } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import MiniSpinner from './MiniSpinner';
import { Spinner } from 'reactstrap';

const FetchLoaderStyles = styled.div`
  background: ${props => (props.inlineLoader ? 'none' : `${theme.pageBackground}`)};
  border-radius: 50%;
  display: flex;
  width: ${props => (props.inlineLoader ? 'auto' : `33px`)};
  height: ${props => (props.inlineLoader ? 'auto' : `33px`)};
  min-height: 23px;
  box-shadow: ${props => (props.inlineLoader ? 'none' : `var(--modal-box-shadow)`)};
  align-items: center;
  justify-content: center;
  position: ${props => (props.inlineLoader ? 'relative' : `absolute`)};
  z-index: 99;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ButtonLoaderStyles = styled.div`
  ${FetchLoaderStyles};
  .spinner-border {
    width: 14px !important;
    height: 14px !important;
  }
  min-height: 14px;
`;

export const LoadingSpinner = ({ darkBg }) => {
  return (
    // <QueryLoader className={`loader loader--is-loading ${darkBg && 'loader--dark-bg'}`} /> // this is absolutely positioned. parent must have a position of relative;
    <MiniSpinner centerAligned={true} />
  );
};
LoadingSpinner.propTypes = {
  darkBg: bool
};
LoadingSpinner.defaultProps = {
  darkBg: false
};

export const FetchingSpinner = ({ darkBg, inlineLoader }) => {
  return (
    <FetchLoaderStyles inlineLoader={inlineLoader}>
      <Spinner size='sm' />
      {/* <QueryLoader className={`loader loader--is-fetching ${darkBg && 'loader--dark-bg'}`} /> */}
      {/* //
      this is statically positioned. */}
    </FetchLoaderStyles>
  );
};

export const ButtonSpinner = ({ inlineLoader }) => {
  return (
    <ButtonLoaderStyles inlineLoader={inlineLoader}>
      <Spinner size='sm' />
    </ButtonLoaderStyles>
  );
};

FetchingSpinner.propTypes = {
  darkBg: bool
};

FetchingSpinner.defaultProps = {
  darkBg: false,
  inlineLoader: false
};

ButtonSpinner.defaultProps = {
  inlineLoader: true
};
