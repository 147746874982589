import { createGlobalStyle } from 'styled-components';
import { WrapperNewCalendar } from '../Layout/NewCalendar';
import { breakpoints } from 'css/utils';
import { MiddleSection } from '../Dashboard-main';
import { TimesheetHistoryWrapper, TimesheetListWrapper } from '../Layout/Timesheet';
import { Wrapper } from '../employee';

export const ResponsivePhase1 = createGlobalStyle`
     /* schedule section */
     .calendar-main, ${TimesheetListWrapper}, ${TimesheetHistoryWrapper} {
        @media(${breakpoints.maxTablet}) {
            width: 1440px;
            min-width: 1440px;
            display: flex;

            flex-wrap: nowrap;
        }
     }
     ${TimesheetListWrapper}, ${TimesheetHistoryWrapper} {
         flex-direction: column;
     }
     ${MiddleSection} {
         &.schedule, &.employeeTimesheet {
            @media(${breakpoints.maxTablet}) {
                overflow-x: auto;
            }
         }
     }
     ${WrapperNewCalendar} {
        .fc-ltr {
            @media(${breakpoints.maxTablet}) {
                display: block;
            }
        }
     }
     .calendarRight {
        @media(${breakpoints.maxTablet}) {
                width: 620px !important;
            }
     }
     ${'' /* schedule page job edit table */}
     .job-edit-form {
         &__table-scroll {
            @media(${breakpoints.maxTablet}) {
                overflow-x: auto;
            }
         }
         &__table-wrapper {
            @media(${breakpoints.maxTablet}) {
                min-width: 1366px;
            }
         }
     }
     ${'' /* for horizontally scrollable tables */}
     .table-scroll-wrapper {
        @media(${breakpoints.maxTablet}) {
                overflow-x: auto;
            }
        &__scrollable {
            @media(${breakpoints.maxTablet}) {
                min-width: 1366px;
            }
        }
     }

     ${'' /* for billing and payments section */}
      .nav-links{
        @media(${breakpoints.maxMobile}){
           max-width:64px !important
        }
      }

     ${'' /* job reporting section */}
     .jobReporting {
         &.hide {
            @media(${breakpoints.maxTablet}) {
                    display: block;
                    min-width: 1100px;
                }
         }
         &--mainsection {
            @media(${breakpoints.maxTablet}) {
                overflow-x: auto;
            }
         }
     }
     .rightsidewrap {
         &--jobReporting {
            @media(${breakpoints.maxTablet}) {
                    display: block;
                    min-width: 520px;
                }
         }
     }
     ${Wrapper} {
        &.employee-crew-wrapper {
            @media(${breakpoints.maxMobile}) {
                height: calc(100% - 130px);
            }
        }
     }

     .timesheet-edit-modal, .timesheet-history-modal  {
            .modal-body {
                overflow-x: auto;
            }
        }
`;
