import styled from 'styled-components';
import { breakpoints, theme, typeScale } from '../../utils';
export const TimesheetListWrapper = styled.div`
  padding: 54px 45px;
  height: 100%;
  .RangeExample {
    button {
      padding: 0 15px;
    }
  }
  .status {
    ${typeScale.textLg};
    padding: 5px 10px;
    border: solid 1px ${theme.textPrimary};
    border-radius: 4px;
    text-transform: capitalize;
    width: 130px;
    text-align: center;
    display: inline-block;
  }
`;
export const TimesheetListEditWrapper = styled(TimesheetListWrapper)`
  padding: 10px 45px;
  height: 100%;
  .timesheet-edit-list {
    height: 80%;
    padding: 20px;
    .datepickerCombo {
      width: 100px;
    }
  }
  .timesheet-edit-container {
    box-shadow: var(--modal-box-shadow);
    .list-group {
      max-height: 350px;
      height: 100%;
      overflow: visible !important ;
    }
    .list-group-item {
      padding-left: 0px !important;
      padding-right: 0px !important;

      .action-buttons {
        flex: 0 0 14.666667% !important;
        max-width: 14.666667% !important;
      }
    }
    .description-col {
      flex: 0 0 10.333333% !important;
      max-width: 10.333333% !important;
    }
  }
  .location-button {
    ${typeScale.textMd}
  }
  .add-break-type {
    padding: 0 27px 20px 27px;
    height: 20%;
  }
  .summary {
    box-shadow: var(--modal-box-shadow);
    padding: 20px;
    .table-header {
      padding: 0.75rem 1rem !important;
      margin-left: 0px;
      margin-right: 0px;
    }
    .list-group {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .input-wrapper {
    width: 100px;
    input {
      width: 100px;
      height: 32px;
      box-shadow: var(--input-box-shadow);
      border: 0px;
      border-radius: 5px;
      padding: 3px 10px 3px 5px;
      ${typeScale.textMd};
      &.is-invalid {
        background-position: center right 22px !important;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgc3Ryb2tlPSdub25lJyBzdHJva2VXaWR0aD0nMScgZmlsbD0nbm9uZScgZmlsbFJ1bGU9J2V2ZW5vZGQnPgogICAgICAgICAgPGcgdHJhbnNmb3JtPSd0cmFuc2xhdGUoLTc3NS4wMDAwMDAsIC0zMTAuMDAwMDAwKScgZmlsbD0nI0NDMDAwMCc+CiAgICAgICAgICAgIDxwYXRoIGQ9J003ODIuNTc4MTA5LDMxOS4wNjI3NjUgQzc4Mi41NzEzMjksMzE5LjE0NTc1NCA3ODIuNTA1NzQ3LDMxOS4yMDk1MDIgNzgyLjQyNzI5NiwzMTkuMjA5NTAyIEw3ODEuNTcyNzg0LDMxOS4yMDk1MDIgQzc4MS40OTQzMzQsMzE5LjIwOTUwMiA3ODEuNDI4NzUxLDMxOS4xNDU3NTQgNzgxLjQyMTk3MiwzMTkuMDYyNzY1IEw3ODEuMDU5NjA2LDMxNC42NTQ3NjUgQzc4MS4wNTE4NTgsMzE0LjU2MDkwNiA3ODEuMTIxNzMsMzE0LjQ3OTk3MyA3ODEuMjEwNDE5LDMxNC40Nzk5NzMgTDc4Mi43ODk1MjMsMzE0LjQ3OTk3MyBDNzgyLjg3ODM1MSwzMTQuNDc5OTczIDc4Mi45NDgwODQsMzE0LjU2MDkwNiA3ODIuOTQwNDc0LDMxNC42NTQ3NjUgTDc4Mi41NzgxMDksMzE5LjA2Mjc2NSBaIE03ODIuNjk2Njg0LDMyMS4yNjkxMTUgQzc4Mi42OTY2ODQsMzIxLjM1NzgzMyA3ODIuNjI4ODg3LDMyMS40Mjk2NTkgNzgyLjU0NTMxOCwzMjEuNDI5NjU5IEw3ODEuNDU0NzYzLDMyMS40Mjk2NTkgQzc4MS4zNzEwNTUsMzIxLjQyOTY1OSA3ODEuMzAzMjU5LDMyMS4zNTc4MzMgNzgxLjMwMzI1OSwzMjEuMjY5MTE1IEw3ODEuMzAzMjU5LDMyMC4xMTEzNzIgQzc4MS4zMDMyNTksMzIwLjAyMjUwNyA3ODEuMzcxMDU1LDMxOS45NTA1MzQgNzgxLjQ1NDc2MywzMTkuOTUwNTM0IEw3ODIuNTQ1MzE4LDMxOS45NTA1MzQgQzc4Mi42Mjg4ODcsMzE5Ljk1MDUzNCA3ODIuNjk2Njg0LDMyMC4wMjI1MDcgNzgyLjY5NjY4NCwzMjAuMTExMzcyIEw3ODIuNjk2Njg0LDMyMS4yNjkxMTUgWiBNNzg4LjkzODExLDMyMi4yNzI3NzYgTDc4Mi4zOTU2MTIsMzEwLjI0MjM1OSBDNzgyLjIxOTc1NiwzMDkuOTE5MjE0IDc4MS43ODAxODYsMzA5LjkxOTIxNCA3ODEuNjA0NDY5LDMxMC4yNDIzNTkgTDc3NS4wNjE4MzIsMzIyLjI3Mjc3NiBDNzc0Ljg4NjExNSwzMjIuNTk1OTIxIDc3NS4xMDU4MzEsMzIzIDc3NS40NTc0MDQsMzIzIEw3ODguNTQyNTM5LDMyMyBDNzg4Ljg5NDExMiwzMjMgNzg5LjExMzk2NiwzMjIuNTk1OTIxIDc4OC45MzgxMSwzMjIuMjcyNzc2IEw3ODguOTM4MTEsMzIyLjI3Mjc3NiBaJz48L3BhdGg+CiAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgICAgIDwvc3ZnPg==');
      }
    }
    svg {
      opacity: 0.6;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .react-timekeeper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    @media (${breakpoints.maxTablet}) {
      position: fixed;
    }
  }
`;
export const TimesheetSettingsWrapper = styled.div`
  h1 {
    padding-left: 0 !important;
  }
  .duration-dd {
    width: 80px;
  }
  .table-header {
    color: ${theme.textFaded};
    ${typeScale.textSm};
    font-weight: lighter;
  }
  .table-row {
    box-shadow: var(--modal-box-shadow);
    padding: 15px;
    ${typeScale.textSm}
  }
  .timesheet-link {
    color: ${theme.textPrimary};
    text-decoration: underline;
  }
`;
export const TimesheetReportWrapper = styled.section`
  padding: 54px 45px;
  height: 100%;
  overflow-y: auto;
  .headwrap {
    .filters {
      &__employeename {
        width: 250px;
      }
    }
  }
  h2 {
    font-size: 12px;
    font-weight: 700;
    padding: 0 0 5px;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      padding: 0 15px 25px;
      font-size: 10px;
    }
    svg {
      margin: -12px 1px 0;
    }
  }
  .graphwrapper-container {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .graphwrapper {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 22px 30px;
    height: 100%;
    position: relative;
    &--report {
      height: 600px;
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    @media (max-width: 1024px) {
      padding: 15px;
      /* margin: 20px 0 20px; */
      height: 100%;
      &.analyticsTracking {
        display: none;
      }
    }
    &__label {
      ${typeScale.textSm}
    }
    &__metric {
      width: 150px;
    }
    .doughnut-container {
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .totalHoursTxt {
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      bottom: 0;
      left: 0;
      right: 0;
      height: max-content;
      text-align: center;
      font-size: 12px;
      color: #333333;
      @media (max-width: 1024px) {
        font-size: 10px;
      }
    }
    canvas {
      position: relative;
    }
    .timesheet-table {
      height: 490px;
      .table-header {
        color: ${theme.textFaded};
        ${typeScale.textSm};
        font-weight: lighter;
        padding-left: 1.2rem !important;
        padding-right: 1.2rem !important;
        & > div {
          width: 14.2%;
          &.date {
            & > div {
              width: 100%;
            }
          }
        }
        .collapsewrap__searchwrapper {
          padding-top: 0;
          padding-bottom: 0;
        }
        .form-group {
          margin-bottom: 0;
        }
        .sort-label {
          color: ${theme.textFaded};
          ${typeScale.textSm};
        }
      }
      .table-body {
        padding-left: 0;
        height: 450px;
        overflow-y: auto;
        .list-group-item {
          padding: 0.65rem 1.25rem;
          border: none !important;
          &:nth-child(odd) {
            background: ${theme.pageBackground};
          }
          &__col {
            ${typeScale.textLg};
            width: 14.2%;
          }
        }
      }
      .mini-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%, -50%);
      }
    }
  }
  /* .legenlist {
    .legend-wrapper:nth-child(3) {
      position: relative;
      margin-bottom: 30px;
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: -15px;
        background: ${theme.lightBorder};
      }
    }
  } */
`;
export const TimesheetHistoryWrapper = styled.div`
  padding: 54px 45px;
  height: 100%;
  .RangeExample {
    button {
      padding: 0 15px;
    }
  }
  .status {
    ${typeScale.textLg};
    padding: 5px 10px;
    border: solid 1px ${theme.textPrimary};
    border-radius: 4px;
    text-transform: capitalize;
    width: 130px;
    text-align: center;
    display: inline-block;
  }
  .history-list {
    h1 {
      margin-bottom: 0;
    }
  }
  .history-box {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 22px 30px;
    height: 95%;
    position: relative;
  }
  .timesheet-table {
    height: 95%;
    .table-header {
      color: ${theme.textFaded};
      ${typeScale.textSm};
      font-weight: lighter;
      padding-left: 1.2rem !important;
      padding-right: 1.2rem !important;
      margin-bottom: 10px;
      & > div {
        text-align: left;
      }
      .collapsewrap__searchwrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
      .sort-label {
        color: ${theme.textFaded};
        ${typeScale.textSm};
      }
    }
    .table-body {
      padding-left: 0;
      height: 450px;
      overflow-x: hidden;
      overflow-y: auto;
      .list-group-item {
        padding: 0.65rem 1.25rem;
        border: none !important;
        &:nth-child(odd) {
          background: ${theme.pageBackground};
        }
        &__col {
          ${typeScale.textLg};
          text-align: left;
        }
      }
    }
  }
  .memo-tooltip {
    max-width: 300px;
    overflow: hidden;
    white-space: wrap;
  }
`;
