import awsExports from 'aws-exports';
import {
  isDevEnv,
  isQAEnv,
  isQaGuestEnv,
  isDevGuestEnv,
  isGuestEnv,
  isStagingEnv,
  isStagingGuestEnv,
  isProductionGuestEnv,
  isProductionEnv
} from 'utils/envUtils';
import appConfig from 'config';

export const returnAmplifyConfig = () => {
  if (isDevEnv()) {
    return {
      userPoolClientId: awsExports.DEV.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.DEV.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.DEV.AWS_IDENTITY_POOL_ID
    };
  }
  if (isQAEnv()) {
    return {
      userPoolClientId: awsExports.QA.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.QA.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.QA.AWS_IDENTITY_POOL_ID
    };
  }
  if (isStagingEnv()) {
    return {
      userPoolClientId: awsExports.STAGE.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.STAGE.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.STAGE.AWS_IDENTITY_POOL_ID
    };
  }
  if (isProductionEnv()) {
    return {
      userPoolClientId: awsExports.PRODUCTION.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.PRODUCTION.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.PRODUCTION.AWS_IDENTITY_POOL_ID
    };
  }
  if (isDevGuestEnv()) {
    return {
      userPoolClientId: awsExports.DEV.GUEST.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.DEV.GUEST.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.DEV.GUEST.AWS_IDENTITY_POOL_ID
    };
  }
  if (isQaGuestEnv()) {
    return {
      userPoolClientId: awsExports.QA.GUEST.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.QA.GUEST.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.QA.GUEST.AWS_IDENTITY_POOL_ID
    };
  }
  if (isStagingGuestEnv()) {
    return {
      userPoolClientId: awsExports.STAGE.GUEST.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.STAGE.GUEST.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.STAGE.GUEST.AWS_IDENTITY_POOL_ID
    };
  }
  if (isProductionGuestEnv()) {
    return {
      userPoolClientId: awsExports.PRODUCTION.GUEST.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.PRODUCTION.GUEST.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.PRODUCTION.GUEST.AWS_IDENTITY_POOL_ID
    };
  }
  // fallback
  if (isGuestEnv()) {
    return {
      userPoolClientId: awsExports.DEV.GUEST.AWS_USER_POOLS_WEB_CLIENT_ID,
      userPoolId: awsExports.DEV.GUEST.AWS_USER_POOLS_ID,
      identityPoolId: awsExports.DEV.GUEST.AWS_IDENTITY_POOL_ID
    };
  }
};

export const returnBucketNames = () => {
  if (isDevEnv()) {
    return 'h360-assets-dev';
  }
  if (isStagingEnv()) {
    return 'h360-assets-stage';
  }
  if (isQAEnv()) {
    return 'h360-assets-qa';
  }
  if (isProductionEnv()) {
    return 'h360-assets-prod';
  }
  if (isDevGuestEnv()) {
    return 'h360-assets-guest-dev';
  }
  if (isQaGuestEnv()) {
    return 'h360-assets-guest-qa';
  }
  if (isStagingGuestEnv()) {
    return 'h360-assets-guest-stage';
  }
  if (isProductionGuestEnv()) {
    return 'h360-assets-guest-prod';
  }
  return `h360-assets-${appConfig.config().env}`;
};
