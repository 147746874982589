import React from 'react';

import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import SVGIcon from 'containers/SVGicon';

import styled from 'styled-components';
import { ButtonAsIcon } from 'css/components/Modules/Buttons';
import { FetchingSpinner } from 'components/Spinner/QueryLoaders';

export const InfoStyles = styled.div`
  path {
    stroke: #fff;
  }
  rect {
    stroke: #fff;
    fill: #fff;
  }
  .rightsidewrap-info {
    position: absolute;
    right: 45px;
    &::before {
      transform: rotateZ(270deg) translate(-100%, -510%);
    }
    &::after {
      transform: translateX(-105%) translateY(0%);
    }
  }
`;

// import PropTypes from 'prop-types';
const CardFrame = props => {
  return !props.hide ? (
    <Card data-testid='component-CardFrame' className={props.className}>
      <CardHeader className={props.headerClassName}>
        <>
          <div className='text-center d-flex justify-content-between'>
            {props.goBack ? (
              <ButtonAsIcon
                onClick={() => props.goBack()}
                className='backBtn'
                data-testid='component-backButton'
              >
                <SVGIcon name='backBtn' width='13' height='11' fill='#fff' stroke='#fff' />
              </ButtonAsIcon>
            ) : null}
            {props.isContract ? (
              <SVGIcon name='contractIcon' width='22' height='22' fill='#fff' stroke='#fff' />
            ) : null}
            {props.headerClassName === 'card-header--light' ? (
              <>
                <h3 className='d-flex justify-content-start align-items-center nopadding'>
                  {props.heading} {props.showLoader ? <FetchingSpinner darkBg={true} /> : null}
                </h3>
              </>
            ) : (
              <>
                <strong className='mx-auto d-flex justify-content-center align-items-center'>
                  {props.heading} {props.showLoader ? <FetchingSpinner darkBg={true} /> : null}
                </strong>
              </>
            )}
            {props.invoiceCardFrameCollapse ? (
              <div>
                <ButtonAsIcon type='button' onClick={props.toggle}>
                  <SVGIcon name='toggleArrow' width='14' height='9' fill='#fff' stroke='#fff' />
                </ButtonAsIcon>
              </div>
            ) : null}

            {/*This button will only show when we are in modal otherwise don't show the button. */}
            {props.infoText ? (
              <InfoStyles className='form-group mb-0'>
                <i
                  className='rightsidewrap-info'
                  tooltip={props.infoText}
                  tooltip-position='center'
                >
                  <SVGIcon name='infoIcon' width='16' height='16' />
                </i>
              </InfoStyles>
            ) : null}
            {props.onFormClose ? (
              <ButtonAsIcon
                className='close'
                onClick={() => props.onFormClose()}
                data-testid='component-whiteCrossButton'
              >
                <SVGIcon name='white-cross' width='15' height='14' fill='#fff' />
              </ButtonAsIcon>
            ) : null}
            {props.onEdit ? (
              <ButtonAsIcon onClick={props.onEdit}>
                <SVGIcon name='edit' width='15' height='14' />
              </ButtonAsIcon>
            ) : null}
          </div>
          {props.subHeading ? (
            <h5 className='text-center d-flex justify-content-between ms-4'>
              <strong>({props.subHeading})</strong>
            </h5>
          ) : null}
        </>
      </CardHeader>
      {props.invoiceCardFrameCollapse ? (
        <div>
          <Collapse isOpen={props.isOpen}>
            <CardBody>{props.children}</CardBody>
          </Collapse>
        </div>
      ) : (
        <CardBody className={`position-relative ${props.bodyClassName}`}>{props.children}</CardBody>
      )}
    </Card>
  ) : (
    props.children
  );
};
// CardFrame.propTypes = PropTypes.shape({
//   heading: PropTypes.string,
//   children: PropTypes.array
// });

export default CardFrame;
