import React from 'react';
//Context
import { TimeMangementStateProvider } from './TimeMangementStateProvider';
//action types
import * as actionTypes from './actionTypes';
//Component
import TimeMangementWrapper from './TimeMangementWrapper';

//utils
import { getInitialState } from './utils';
const TimeMangementReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        employeeLoginInfo: action.data.employeeLoginInfo,
        eventType: action.data.eventType,
        eventGoingOn: action.data.eventGoingOn
      };
    case actionTypes.SET_EVENT_TYPE:
      return {
        ...state,
        eventType: action.data.eventType
      };
    case actionTypes.RESET:
      return {
        ...state,
        employeeLoginInfo: null,
        eventType: null,
        eventGoingOn: null,
        showSuccessScreen: true,
        successMessage: action.data.successMessage || 'Successful!'
      };
    case actionTypes.HIDE_SUCCESS_SCREEN:
      return {
        ...state,
        showSuccessScreen: false,
        successMessage: undefined
      };
    case actionTypes.SET_EMPLOYEE_LOCATION_INFO:
      return {
        ...state,
        employeeLocationInfo: action.data.employeeLocationInfo
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
export default function TimeMangement() {
  return (
    <>
      <TimeMangementStateProvider initialState={getInitialState()} reducer={TimeMangementReducer}>
        <TimeMangementWrapper />
      </TimeMangementStateProvider>
    </>
  );
}
