import { useState } from 'react';
import PropTypes from 'prop-types';

function useInput(initialInput) {
  const [input, setInput] = useState(initialInput);
  /**
   *
   * @param {Object} e  input event
   * @param {Boolean} reset if passed true, it will reset the input value to empty string.
   */
  const changeInput = (e, reset) => {
    if (reset) {
      setInput('');
      return;
    }
    setInput(e.target.value);
  };

  return [input, changeInput];
}

useInput.propTypes = {
  initialInput: PropTypes.string
};

export default useInput;
