// Functional component to render a conditional value
export default function RenderIf({ children, isTrue }) {
  return isTrue ? children : null;
}

//How to Use
/* 
    <RenderIf isTrue={some true condition}
        Jsx here
    </RenderIf>

*/
